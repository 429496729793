<template>
  <div class="store-download">
    <button class="button-google-play" @click="downloadGPlay" />
    <button class="button-app-store" @click="downloadAppStore" />
  </div>
</template>

<script>
  export default {
    methods: {
      downloadAppStore: function () {
        this.trackSnowplow('trackButton', {
          button_name: 'store_download',
          additional_properties: 'ios',
        });
        window.open(
          'https://apps.apple.com/us/app/whitebook-prescri%C3%A7%C3%A3o-e-bul%C3%A1rio/id638521744?l=pt&ls=1'
        );
      },
      downloadGPlay: function () {
        this.trackSnowplow('trackButton', {
          button_name: 'store_download',
          additional_properties: 'android',
        });
        window.open(
          'https://play.google.com/store/apps/details?id=com.medprescricao'
        );
      },
    },
  };
</script>

<style scoped lang="scss" src="./StoreButtons.scss"></style>
