<template>
  <div class="error-bank-slip page">
    <div class="main">
      <div class="container">
        <div class="message">
          <span class="circle">
            <img
              src="../../assets/images/svg/bar-code-error.svg"
              :alt="languageString.BANK_SLIP_FAILURE_altImage"
            />
          </span>
          <h3>
            {{ languageString.CREDIT_CARD_FAILURE_ops }}
          </h3>
          <p>
            {{ this.bankSlipFailureMessage || languageString.BANK_SLIP_FAILURE_subTitle}}
          </p>
        </div>
        <div class="actions">
          <custom-button :primary="true" :action="actionBackToBankSlipPayment">
            {{ languageString.BANK_SLIP_FAILURE_tryAgain }}
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomButton from '@/components/CustomButton';

  import LanguageString from '@/config/language/pt-BR';

  export default {
    name: 'BankSlipFailure',
    components: {
      'custom-button': CustomButton,
    },
    props: {
      actionBackToBankSlipPayment: {
        type: Function,
        required: true,
      },
      bankSlipFailureMessage: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        languageString: LanguageString,
      };
    },
    mounted() {
      this.trackSnowplow('trackPopupView', {
        name: 'view_payment_bank_slip_failure',
      });

      this.trackSnowplow('trackSubscribe', {
        e: null,
        step_name: 'subscribe_error',
        step_num: 9,
        step_value: 'bank_slip',
        failure_reason: this.bankSlipFailureMessage,
      });

      this.trackBraze('trackEvent', {
        name: 'subscribe_error',
        attribute: 'step_name',
        event_name: 'subscribe',
      });
    },
  };
</script>

<style scoped lang="scss" src="./bank-slip-failure.scss" />
