<template>
  <div class="error-general page">
    <div class="main">
      <div class="container">
        <div class="message">
          <span class="circle">
            <img
              src="../../assets/images/svg/error-icon.svg"
              :alt="languageString.GENERAL_FAILURE_errorTitle"
            />
          </span>
          <h3>
            {{ languageString.CREDIT_CARD_FAILURE_ops }}
          </h3>
          <p>
            {{ languageString.GENERAL_FAILURE_errorTitle }}
          </p>
        </div>
        <div class="actions">
          <custom-button :primary="true" :action="actionHideMessage">
            {{ languageString.BANK_SLIP_FAILURE_tryAgain }}
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomButton from '@/components/CustomButton';

  import LanguageString from '@/config/language/pt-BR';

  export default {
    name: 'BankSlipFailure',
    components: {
      'custom-button': CustomButton,
    },
    props: {
      actionHideMessage: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        languageString: LanguageString,
      };
    },
    mounted() {
      this.trackSnowplow('trackPopupView', {
        name: 'view_payment_general_failure',
      });
    },
  };
</script>

<style scoped lang="scss" src="./general-failure.scss" />
