import appboy from '@braze/web-sdk';

class BrazeService {
  constructor() {
    this.eventBaseObj = {
      plataforma: 'web',
    };
  }

  setup() {
    appboy.initialize(process.env.VUE_APP_BRAZE_API_KEY, {
      baseUrl: process.env.VUE_APP_BRAZE_SDK,
      appVersion: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
      enableLogging: process.env.VUE_APP_ENVIRONMENT !== 'PRODUCTION',
    });
    appboy.display.automaticallyShowNewInAppMessages();
    appboy.openSession();
  }

  trackUser({ userId }) {
    appboy.changeUser(`${userId}`);
  }

  trackEvent({ name, options }) {
    appboy.logCustomEvent(name, { ...options, ...this.eventBaseObj });
  }

  trackSubscribe({ name, planId }) {
    appboy.logCustomEvent(name, {
      plano: planId,
      metodo: 'cartao',
      ...this.eventBaseObj,
    });
  }
}

export default new BrazeService();
