<template>
  <div class="credit-card-failure page">
    <div class="main">
      <div class="container">
        <div class="message">
          <span class="circle">
            <svg-image
              :file-name="'card-error.svg'"
              :alt="languageString.CREDIT_CARD_FAILURE_captionOfCardErrorIcon"
            />
          </span>
          <h3>
            {{ languageString.CREDIT_CARD_FAILURE_ops }}
          </h3>
          <p>
            {{ this.creditCardFailureMessage || languageString.CREDIT_CARD_FAILURE_subTitle }}
          </p>
          <br />
          <p>
            {{ this.creditCardFailureAcaoMessage }}
          </p>
        </div>
        <div class="actions">
          <div class="informative">
            <p
              v-html="
                languageString.CREDIT_CARD_FAILURE_informationOnPaymentOfBankSlip
              "
            />
          </div>
          <custom-button :secondary="true" :action="actionPayWithBankSlip">
            {{ languageString.CREDIT_CARD_FAILURE_payWithBankSlip }}
          </custom-button>
          <custom-button
            :primary="true"
            :action="actionBackToCreditCardPayment"
          >
            {{ languageString.CREDIT_CARD_FAILURE_useAnotherCard }}
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SVGImage from '@/components/SVGImage';
  import CustomButton from '@/components/CustomButton';

  import LanguageString from '@/config/language/pt-BR';

  export default {
    name: 'CreditCardFailure',
    components: {
      'custom-button': CustomButton,
      'svg-image': SVGImage,
    },
    props: {
      actionPayWithBankSlip: {
        type: Function,
        required: true,
      },
      actionBackToCreditCardPayment: {
        type: Function,
        required: true,
      },
      creditCardFailureMessage: {
        type: String,
        required: true,
      },
      creditCardFailureAcaoMessage: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        languageString: LanguageString,
      };
    },
    mounted() {
      this.trackSnowplow('trackPopupView', {
        name: 'view_payment_credit_card_failure',
      });

      this.trackSnowplow('trackSubscribe', {
        e: null,
        step_name: 'subscribe_error',
        step_num: 9,
        step_value: 'credit_card',
        failure_reason: this.creditCardFailureMessage,
      });

      this.trackBraze('trackEvent', {
        name: 'subscribe_error',
        attribute: 'step_name',
        event_name: 'subscribe',
      });
    },
  };
</script>

<style scoped lang="scss" src="./credit-card-failure.scss" />
