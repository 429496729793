<template>
  <div class="form-input" :class="[this.hasError ? 'form-input-error' : selectedInput.identifier]">
    <input
      v-model="inputValue"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      v-bind="selectedInput.attributes"
      v-on="selectedInput.events"
      @blur="onBlurEvent"
    />
    <label :for="selectedInput.identifier">
      {{ selectedInput.label }}
    </label>
    <span class="has-error" style="display:none" :id="selectedInput.identifier + '-error'">{{ error }}</span>
  </div>
</template>

<script>
  import Utils from '@/assets/js/Utils';
  import IuguService from '@/services/IuguService';

  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: true,
      },
      error: {
        type: String,
        default: '',
      },
      disabled: Boolean,
      readonly: Boolean,
      value: String,
    },
    data() {
      return {
        hasError: false,
        templates: {
          cpf: {
            label: this.setCpfLabel(),
            attributes: {
              type: 'text',
              pattern: /\d{3}\.\d{3}\.\d{3}-\d{2}/,
              minLength: 14,
              maxLength: 14,
            },
            events: {
              input: this.formatCPF,
            },
          },
          fullName: {
            label: 'Nome completo',
            attributes: {
              type: 'text',
              pattern: /[a-zA-Z]+\s[a-zA-Z\s]+/,
              minLength: 3,
              maxLength: 100,
            },
          },
          phoneNumber: {
            label: 'Número de celular',
            attributes: {
              type: 'text',
              pattern: /\(\d{2}\)\s[\D*\d]{9}/,
              minLength: 15,
              maxLength: 16,
            },
            events: {
              input: this.formatPhoneNumber,
            },
          },
          zipCode: {
            label: 'CEP',
            attributes: {
              type: 'text',
              pattern: /\d{5}-\d{3}/,
              minLength: 9,
              maxLength: 9,
            },
            events: {
              input: this.formatZipCode,
            },
          },
          street: {
            label: 'Endereço',
            attributes: {
              type: 'text',
              pattern: /[a-zA-Z0-9]+\s[a-zA-Z0-9\s]+/,
              maxLength: 100,
            },
          },
          number: {
            label: 'Número',
            attributes: {
              type: 'text',
              pattern: /^$|^[0-9a-zA-Z\s\/]+$/,
              minLength: 1,
              maxLength: 10,
            },
          },
          complement: {
            label: 'Complemento',
            attributes: {
              type: 'text',
              pattern: /[a-zA-Z]+\s[a-zA-Z\s]+/,
              maxLength: 100,
            },
          },
          email: {
            label: 'E-mail',
            attributes: {
              type: 'email',
              pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
            },
          },
          password: {
            label: 'Senha',
            attributes: {
              type: 'password',
              pattern: /.+/,
            },
          },
          cardNumber: {
            label: 'Número do cartão',
            attributes: {
              type: 'text',
              pattern: /[0-9\s]+/,
              minLength: 16,
              maxLength: 19,
            },
            events: {
              input: this.formatCardNumber,
            },
          },
          cardExpirationDate: {
            label: 'Validade',
            attributes: {
              type: 'text',
              pattern: /\d{2}\/\d{2}/,
              minLength: 5,
              maxLength: 5,
            },
            events: {
              input: this.formatCardExpirationDate,
            },
          },
          cardVerificationValue: {
            label: 'Cód. de segurança',
            attributes: {
              type: 'text',
              pattern: /\d{3}\d*/,
              minLength: 3,
              maxLength: 4,
            },
            events: {
              input: this.formatCardVerificationValue,
            },
          },
          cardName: {
            label: 'Nome impresso no cartão',
            attributes: {
              type: 'text',
              pattern: /^[a-zA-Z]+\s[a-zA-Z\s]+$/,
              minLength: 3,
              maxLength: 100,
            },
          },
          discountCoupon: {
            label: 'Cupom',
            attributes: {
              type: 'text',
              pattern: /[A-Za-z0-9]*/,
              minLength: 10,
              maxLength: 10,
            },
          },
        },
      };
    },
    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      selectedInput() {
        const selectedInput = { ...this.templates[this.name] };

        selectedInput.identifier = Utils.toKebabCase(this.name);
        selectedInput.attributes = {
          ...selectedInput.attributes,
          id: selectedInput.identifier,
          name: selectedInput.identifier,
          placeholder: selectedInput.label,
        };

        return selectedInput;
      },
    },
    methods: {
      formatCardNumber(e) {
        let value = e.target.value;

        if (
          !/delete/i.test(e.inputType) ||
          (/delete/i.test(e.inputType) && value.length > 17)
        ) {
          value = Utils.sanitizeDigitsString(value);

          if (value) {
            value = Utils.maskCardNumber(value);
          }
        }
        e.target.value = value;
        this.$parent.form.cardNumber.value = value;
      },

      formatCardExpirationDate(e) {
        let value = e.target.value;

        if (!/delete/i.test(e.inputType)) {
          value = Utils.sanitizeDigitsString(value);

          if (value)
            value = Utils.maskDate(value).slice(
              0,
              this.selectedInput.attributes.maxLength
            );
        }
        e.target.value = value;
        this.$parent.form.cardExpirationDate.value = value;
      },

      formatCardVerificationValue(e) {
        let value = e.target.value;

        if (!/delete/i.test(e.inputType))
          value = Utils.sanitizeDigitsString(value);

        e.target.value = value;
        this.$parent.form.cardVerificationValue.value = value;
      },

      formatCPF(e) {
        let value = e.target.value;

        if (!/delete/i.test(e.inputType)) {
          value = Utils.sanitizeDigitsString(value);

          if (value) value = Utils.maskCPF(value);
        }
        e.target.value = value;
        this.$parent.form.cpf.value = value;
      },

      setCpfLabel(e) {
        if (this.$route.name === 'checkout'){
          return 'CPF do portador do cartão'
        }
        return 'CPF do portador'
      },

      formatPhoneNumber(e) {
        let value = e.target.value;

        if (
          !/delete/i.test(e.inputType) ||
          (/delete/i.test(e.inputType) && value.length > 14)
        ) {
          value = Utils.sanitizeDigitsString(value);

          if (value) value = Utils.maskPhoneNumber(value);
        }
        e.target.value = value;
        this.$parent.form.phoneNumber.value = value;
      },

      formatZipCode(e) {
        let value = e.target.value;

        if (!/delete/i.test(e.inputType)) {
          value = Utils.sanitizeDigitsString(value);

          if (value) value = Utils.maskZipCode(value);
        }
        e.target.value = value;
        this.$parent.form.zipCode.value = value;
      },
      isLetter: function(char) {
        return char.toLowerCase() == char.toUpperCase()
      },
      onBlurEvent(e) {
        const formValue = this.$parent.form[this.name];

        formValue.isValid = this.selectedInput.attributes.pattern.test(
          formValue.value
        )

        if (e.target.id === 'card-number') {
          const cardNumber = IuguService.validateCardNumber(e.target.value);
          if (!cardNumber) {
            formValue.isValid = cardNumber
            document.getElementById(`${e.target.id}-error`).innerText = 'Número do cartão inválido'
          }
        }

        if (e.target.id === 'complement') {
          formValue.isValid = true;
          document.getElementById(`${e.target.id}-error`).style.display = 'none'
        }

        if (e.target.id === 'card-expiration-date') {
          const cardExpirationDate = IuguService.validateCardExpirationDate(e.target.value);

          if (!cardExpirationDate) {
            formValue.isValid = cardExpirationDate
            document.getElementById(`${e.target.id}-error`).innerText = 'Data inválida'
          }
        }

        if (e.target.id === 'card-verification-value') {
          const errorContainerId = `${e.target.id}-error`;
          const cardVerificationValue = e.target.value;
          const cardNumberValue = document.getElementById(`card-number`).value.replace(/\s/g, '');
          formValue.isValid = true;
          const mensagem = 'Código inválido';
          if (cardVerificationValue && cardVerificationValue.length <= 2) {
            formValue.isValid = false;
            document.getElementById(errorContainerId).innerText = mensagem;
          }

          const regexAmex = /^3[47]\d{13}$/;
          const isAmex = regexAmex.test(cardNumberValue);
          if (cardNumberValue && isAmex && cardVerificationValue.length <= 3) {
            formValue.isValid = false;
            document.getElementById(errorContainerId).innerText = mensagem;
          } else if (cardNumberValue && !isAmex && cardVerificationValue.length > 3) {
            formValue.isValid = false;
            document.getElementById(errorContainerId).innerText = mensagem;
          }

        }

        if (e.target.id === 'card-name') {
          const cardName = e.target.value;

          if (this.isLetter(cardName)) {
            document.getElementById(`${e.target.id}-error`).innerText = 'Utilize apenas letras'
           } else {
            document.getElementById(`${e.target.id}-error`).innerText = 'Preencher o nome conforme impresso no cartão'
          }
        }

        if (!e.target.value) {
          document.getElementById(`${e.target.id}-error`).innerText = 'Campo obrigatório'
        }

        if (!formValue.isValid) {
          document.getElementById(`${e.target.id}-error`).style.display = 'block'
          this.hasError = true;

        } else {
          document.getElementById(`${e.target.id}-error`).style.display = 'none'
          this.hasError = false;

        }
        this.$emit('blur', e);
      },
    },
  };
</script>

<style lang="scss" scoped src="./FormInput.scss"></style>
