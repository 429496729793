var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-billing",style:(_vm.plansHeight)},[(_vm.registeredCreditCard)?_c('div',{staticClass:"registered-card"},[_c('p',{staticClass:"group-title"},[_vm._v("Cartão Atual")]),_c('div',{staticClass:"card",style:({ backgroundImage: _vm.brandStylization().background })},[_c('div',{staticClass:"number"},[_c('div',{staticClass:"brand",style:({ backgroundImage: _vm.brandStylization().logo })}),_vm._l((_vm.registeredCreditCard.number),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.replace("XXXX", "••••")))])})],2),_c('div',{staticClass:"bottom-info"},[_c('div',{staticClass:"expiration"},[_c('span',[_vm._v("Validade")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.twoDigitsDate(_vm.registeredCreditCard.expirationDate))+" ")])]),_c('div',{staticClass:"holder-name"},[_vm._v(" "+_vm._s(_vm.registeredCreditCard.holderName)+" ")])])]),_c('div',{staticClass:"about",on:{"click":_vm.calculatePlansHeight}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.aboutInfo.text)}}),_c('p',{staticClass:"about-extra-info",domProps:{"innerHTML":_vm._s(_vm.aboutInfo.extraInfo)}})])]):_vm._e(),_c('form',{ref:"cardForm",staticClass:"form",attrs:{"novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.submitNewCreditCard.apply(null, arguments)}}},[_c('div',{staticClass:"payment-info"},[_c('p',{staticClass:"group-title"},[_vm._v("Novo Cartão")]),_c('payment-methods'),_c('form-input',{attrs:{"name":'cardNumber',"value":_vm.form.cardNumber.value,"error":_vm.form.cardNumber.error.message},on:{"input":function($event){_vm.form.cardNumber.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
            form_element: _vm.form.cardNumber,
            step_name: 'user_card_number',
            step_num: 1,
          })}}}),_c('div',{staticClass:"card-confirmation-info"},[_c('form-input',{attrs:{"name":'cardExpirationDate',"value":_vm.form.cardExpirationDate.value,"error":_vm.form.cardExpirationDate.error.message},on:{"input":function($event){_vm.form.cardExpirationDate.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
              form_element: _vm.form.cardExpirationDate,
              step_name: 'user_card_expiration',
              step_num: 2,
            })}}}),_c('form-input',{attrs:{"name":'cardVerificationValue',"value":_vm.form.cardVerificationValue.value,"error":_vm.form.cardVerificationValue.error.message},on:{"input":function($event){_vm.form.cardVerificationValue.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
              form_element: _vm.form.cardVerificationValue,
              step_name: 'user_card_cvv',
              step_num: 3,
            })}}})],1),_c('form-input',{attrs:{"name":'cardName',"value":_vm.form.cardName.value,"error":_vm.form.cardName.error.message},on:{"input":function($event){_vm.form.cardName.value = $event},"blur":function($event){return _vm.trackerSnowplowAndBrazeByUserCardName(_vm.form)}}}),_vm._m(0)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"submit-form"},[_c('button',{attrs:{"name":"submit-button","type":"submit"}},[_vm._v(" Cadastrar novo cartão ")])])])
}]

export { render, staticRenderFns }