import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueScrollTo from 'vue-scrollto';
import axios from 'axios';

import App from './App.vue';
import Router from './router';

import SentryService from '@/services/sentry';
import BrazeService from '@/services/braze';

import './assets/js/gtm';
import './assets/js/snowplow';

import navigation from './mixins/navigation';
import tracking from './mixins/tracking';
import globals from './mixins/globals';

import formatDate from './filters/formatDate';

Vue.config.productionTip = false;

Vue.filter('formatDate', formatDate);

Vue.mixin(navigation);
Vue.mixin(globals);
Vue.mixin(tracking);

Vue.use(VueCookies);
Vue.use(VueScrollTo, {
  duration: 500,
  easing: 'ease',
});

Vue.$cookies.config(60 * 60 * 2, '/', '.pebmed.com.br');

SentryService.setup(Router);
BrazeService.setup();

axios.interceptors.request.use(function (config) {
  config.headers['X-Release'] = process.env.VUE_APP_VERSION;
  config.headers['X-App-Name'] = 'assinatura-web';

  return config;
});

new Vue({
  router: Router,
  render: h => h(App),
}).$mount('#app');
