import axios from 'axios';

const api_pagarme = axios.create({
  baseURL: process.env.VUE_APP_PAGARME_URL,
})

class PagarmeService {
  constructor() {
    this.appId = process.env.VUE_APP_PAGARME_PUBLIC_KEY
  }

  createPaymentToken(CardObject) {
    return api_pagarme.post(`/core/v5/tokens?appId=${this.appId}`, CardObject);
  }
}

export default new PagarmeService();
