// Google Tag Manager Snowplow
import * as Sentry from '@sentry/browser';

(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(
  window,
  document,
  'script',
  'dataLayer',
  `${process.env.VUE_APP_SNOWPLOW_GTM_KEY}`
);
// End
dataLayer.push({ app_id: 'portalassinatura' });
const pathName = window.location.pathname;
dataLayer.push({
  page_title: pathName === '/forma-pagamento.php' ? 'checkout_web' : null,
});

try {
  SnowplowTracker.loadTracker();
} catch (error) {
  console.warn('Snowplow Analytics desativado', error);
  Sentry.captureException(error);
}
