<template>
  <div class="wb-bar" :style="wbBarSize">
    <span />
    <span />
    <span />
    <span />
    <span />
    <span />
  </div>
</template>

<script>
  export default {
    props: {
      wbBarSize: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style scoped lang="scss">
  .wb-bar {
    display: flex;
    width: var(--bar-width);
    height: var(--bar-height);

    span {
      width: 16.66%;
      height: 100%;

      &:nth-child(1) {
        background-color: #e04d33;
      }

      &:nth-child(2) {
        background-color: #d42d65;
      }

      &:nth-child(3) {
        background-color: #87448c;
      }

      &:nth-child(4) {
        background-color: #44b0ce;
      }

      &:nth-child(5) {
        background-color: #63a448;
      }

      &:nth-child(6) {
        background-color: #ffc400;
      }
    }
  }
</style>
