<template>
  <div class="top-bar">
    <wb-bar :wb-bar-size="{ '--bar-height': '3px' }" />
    <div class="info-container">
      <div class="container-logo">
        <svg-image file-name="logo-whitebook.svg" alt="Logo do Whitebook" class="logo-whitebook" />
      </div>
      <p class="step">
        {{ steps[stepId] }}
      </p>
      <div v-if="steps[stepId] === 'Gerenciar Assinatura'" class="actions" @click="redirectHome">
        <a class="button-link redirect-home"
          >Voltar para o Whitebook</a
        >
      </div>
    </div>
  </div>
</template>

<script>
  import WbBar from '@/components/WbBar';
  import SVGImage from '@/components/SVGImage';

  export default {
    components: {
      'wb-bar': WbBar,
      'svg-image': SVGImage,
    },
    props: {
      stepId: String,
    },
    data() {
      return {
        steps: {
          payment: 'Detalhes de Pagamento',
          management: 'Gerenciar Assinatura',
          paymentBankSlip: 'Detalhes do Boleto',
        },
      };
    },
  };
</script>

<style scoped lang="scss" src="./TopBar.scss" />
