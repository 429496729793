<template>
  <div class="pix" v-bind:class="{pixClosed: !showPix}">
    <div class="slideout-closed-container" v-if="!showPix">
      <span class="title-slideout-closed" v-if="!showPix">Pagamento por PIX</span>
      <img class="slideout-img-closed" v-if="!showPix" @click="managePixSlideout()"
          src="../../assets/images/svg/arrow.svg"
          alt="Ícone para retornar à tela anterior"
          title="Ícone para retornar à tela anterior"
        />
    </div>
    <div class="slideout">
      <div class="pix-payment">
        <div class="slideout-container">
          <span class="title">Pagamento por PIX</span>
          <img class="slideout-img" @click="managePixSlideout()"
              src="../../assets/images/svg/arrow.svg"
              alt="Ícone para retornar à tela anterior"
              title="Ícone para retornar à tela anterior"
            />
        </div>
        <span class="summary">
          O pagamento da sua compra pode ser feito por PIX até o dia {{formatDate(userSession.subscription.premium.endDate)}},
          basta seguir uma das opções abaixo.
        </span>
        <span class="subtitle">Por QR Code</span>
        <div class="qrcode">
          <span class="summary qrcode-summary">
            Para poder realizar o pagamento abra o app do seu banco, acesse a área PIX
            e selecione o leitor de QR Code para escanear a imagem abaixo.
          </span>
          <img class="pix-qrcode" :src="pixQRcode" alt="pix-qrcode" />
        </div>
      </div>
      <div class="pix-payment">
        <span class="subtitle">Por Código</span>
        <span class="summary">
          Você também pode realizar o pagamento copiando o código e acessando a
          opção PIX Copia e Cola dentro do app do seu banco.
        </span>
        <span id="pixCode" @click.prevent="copyPixCode" class="pix-payment-qrcode-button">Copiar código PIX</span>
      </div>
    </div>
  </div>
</template>

<script>
  import UserSessionService from '@/services/user-session';
  import Utils from '@/assets/js/Utils';
  import LanguageString from '@/config/language/pt-BR';

  export default {
    name: 'PixPayment',
    props: {
      showPix: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
        pixQRCodeText: null,
        languageString: LanguageString,
        userSession: null,
        CONGRATS_warning: LanguageString.CONGRATS_warning,
        showModal: false,
      };
    },
    created() {
      this.userSession = UserSessionService.getData();
      this.planName = window.sessionStorage.getItem('planName');
      this.totalValue = window.sessionStorage.getItem('totalValue');
      this.installmentValue = window.sessionStorage.getItem('installmentValue');
      this.installmentQuantity = window.sessionStorage.getItem(
        'installmentQuantity'
      );
      this.pixQRcode = window.sessionStorage.getItem('qrcode');
      this.pixQRCodeText = window.sessionStorage.getItem('qrcodeUrl');
      this.userEmail = window.sessionStorage.getItem('userEmail');
      this.userCpf = window.sessionStorage.getItem('userCpf');
    },
    methods: {
      formatDate: Utils.formatDate,
      managePixSlideout() {
       this.$emit('manage-pix-slideout')
      },
      copyPixCode() {
        try {
          this.trackSnowplow('trackButton', {
            button_name: 'copy_qrcode',
            additional_properties: 'Copy qrcode text from congrats'
          });

          if(this.pixQRCodeText) {
            let qrcodeElement = document.createElement("textarea");
            qrcodeElement.value = this.pixQRCodeText;
            qrcodeElement.style.position = "fixed";
            qrcodeElement.style.left = "-999999px";
            qrcodeElement.style.top = "-999999px";
            document.body.appendChild(qrcodeElement);
            qrcodeElement.focus();
            qrcodeElement.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                qrcodeElement.remove();
                this.createToast(this.languageString.CONGRATS_copiedCodeSuccess,
                  {},
                  'copyPixCodeSuccess'
                );
            });
          } else {
            this.createToast(this.languageString.CONGRATS_copiedQRCodeError,
              {},
              'copyPixCodeFail'
            );
          }
        } catch (err) {
          this.createToast(this.languageString.CONGRATS_copiedQRCodeError,
            {},
            'copyPixCodeFail'
          );
        }
        this.cleanSelection();
      },
      cleanSelection() {
        window.getSelection().removeAllRanges();
      },
    },
  };
</script>

<style scoped lang="scss" src="./PixPayment.scss"></style>
