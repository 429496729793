<template>
  <button v-if="isButton" :class="classButton" @click="action" :disabled="disabled">
    <slot />
  </button>
  <a v-else class="link" @click="action">
    <slot />
  </a>
</template>

<script>
  export default {
    props: {
      primary: Boolean,
      secondary: Boolean,
      link: Boolean,
      action: {
        type: Function,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isButton() {
        return !this.link;
      },
      classButton() {
        return this.primary ? 'primary' : 'secondary';
      },
    },
  };
</script>

<style lang="scss" scoped src="./CustomButton.scss"></style>
