import axios from 'axios';
import * as Sentry from '@sentry/browser';

class OfferService {
  constructor() {
    this.api_url = process.env.VUE_APP_URL_API_OFFER;
  }

  async validateCoupon(authToken, couponCode, userId) {

    couponCode = encodeURIComponent(couponCode);

    try {
      const endpoint = `${this.api_url}/coupons/validate?couponCode=${couponCode}&userId=${userId}`;
      const response = await axios.get(endpoint, {
        headers: {
          accept: 'application/json',
          Authorization: authToken,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      throw error;
    }
  }
}

export default new OfferService();
