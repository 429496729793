import moment from 'moment';
class Utils {
  calculateDiscount(price, discountPercentage) {
    let priceWithDiscount = price * (1 - discountPercentage / 100);

    return Math.round(priceWithDiscount * 100) / 100;
  }

  getMobilePlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) return 'ios';
    else if (/android/i.test(userAgent)) return 'android';
    else if (/windows phone/i.test(userAgent)) return 'windows phone';

    return;
  }

  getBrowserInfo() {
    const sUsrAg = navigator.userAgent;
    const response = {};

    if (sUsrAg.indexOf('Chrome') > -1) {
      response.browser = 'Chrome';
    } else if (sUsrAg.indexOf('Safari') > -1) {
      response.browser = 'Safari';
    } else if (sUsrAg.indexOf('Opera') > -1) {
      response.browser = 'Opera';
    } else if (sUsrAg.indexOf('Firefox') > -1) {
      response.browser = 'Firefox';
    } else if (sUsrAg.indexOf('MSIE') > -1) {
      response.browser = 'IE';
    } else if (sUsrAg.indexOf('AppleWebKit') > -1) {
      response.browser = 'AppleWebKit';
    } else {
      response.browser = 'Outros';
    }

    if (response.browser === 'Outros') {
      response.version = 'Outros';
    } else {
      let idBrowser = sUsrAg.indexOf(response.browser);

      response.version = sUsrAg.slice(idBrowser).split('/')[1].split(' ')[0];
    }

    return response;
  }

  numberToCurrency(value) {
    value = (+value).toLocaleString('pt-BR');

    if (value.indexOf(',') !== -1) {
      return value.indexOf(',') > value.length - 3 ? (value += '0') : value;
    } else return `${value},00`;
  }

  currencyToNumber(value) {
    return +value.replace(',', '.');
  }

  sanitizeDigitsString(stringToSanitize) {
    return stringToSanitize.replace(/\D/g, '');
  }

  validateCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (!cpf || cpf.length != 11 || cpf.match(/(.)\1*/g)[0].length === 11)
      return false;

    let add = 0;

    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);

    let rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) rev = 0;

    if (rev != parseInt(cpf.charAt(9))) return false;

    add = 0;

    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) rev = 0;

    if (rev != parseInt(cpf.charAt(10))) return false;

    return true;
  }

  maskCPF(cpfString) {
    const slicePointA = 3;
    const slicePointB = 6;
    const slicePointC = 9;

    if (cpfString.length >= slicePointC)
      return [
        cpfString.slice(0, slicePointA),
        '.',
        cpfString.slice(slicePointA, slicePointB),
        '.',
        cpfString.slice(slicePointB, slicePointC),
        '-',
        cpfString.slice(slicePointC),
      ].join('');
    else if (cpfString.length >= slicePointB)
      return [
        cpfString.slice(0, slicePointA),
        '.',
        cpfString.slice(slicePointA, slicePointB),
        '.',
        cpfString.slice(slicePointB),
      ].join('');
    else if (cpfString.length >= slicePointA)
      return [
        cpfString.slice(0, slicePointA),
        '.',
        cpfString.slice(slicePointA),
      ].join('');
    else return cpfString;
  }

  maskDate(dateString) {
    const slicePointA = 2;
    const slicePointB = 4;

    if (dateString.length >= slicePointB)
      return [
        dateString.slice(0, slicePointA),
        '/',
        dateString.slice(slicePointA, slicePointB),
        '/',
        dateString.slice(slicePointB),
      ].join('');
    else if (dateString.length >= slicePointA)
      return [
        dateString.slice(0, slicePointA),
        '/',
        dateString.slice(slicePointA),
      ].join('');
    else return dateString;
  }

  maskCardNumber(cardNumberString) {
    if (cardNumberString.length < 16) {
      const slicePointA = 4;
      const slicePointB = 10;

      if (cardNumberString.length >= slicePointB)
        return [
          cardNumberString.slice(0, slicePointA),
          ' ',
          cardNumberString.slice(slicePointA, slicePointB),
          ' ',
          cardNumberString.slice(slicePointB),
        ].join('');
      else if (cardNumberString.length >= slicePointA)
        return [
          cardNumberString.slice(0, slicePointA),
          ' ',
          cardNumberString.slice(slicePointA),
        ].join('');
      else return cardNumberString;
    } else {
      const slicePointA = 4;
      const slicePointB = 8;
      const slicePointC = 12;

      return [
        cardNumberString.slice(0, slicePointA),
        ' ',
        cardNumberString.slice(slicePointA, slicePointB),
        ' ',
        cardNumberString.slice(slicePointB, slicePointC),
        ' ',
        cardNumberString.slice(slicePointC),
      ].join('');
    }
  }

  maskPhoneNumber(phoneNumberString) {
    phoneNumberString = '(' + phoneNumberString;

    if (phoneNumberString.length < 12) {
      const slicePointA = 3;
      const slicePointB = 7;
      if (phoneNumberString.length >= slicePointB)
        return [
          phoneNumberString.slice(0, slicePointA),
          ') ',
          phoneNumberString.slice(slicePointA, slicePointB),
          ' ',
          phoneNumberString.slice(slicePointB),
        ].join('');
      else if (phoneNumberString.length >= slicePointA)
        return [
          phoneNumberString.slice(0, slicePointA),
          ') ',
          phoneNumberString.slice(slicePointA),
        ].join('');
      else return phoneNumberString;
    } else {
      const slicePointA = 3;
      const slicePointB = 4;
      const slicePointC = 8;

      return [
        phoneNumberString.slice(0, slicePointA),
        ') ',
        phoneNumberString.slice(slicePointA, slicePointB),
        ' ',
        phoneNumberString.slice(slicePointB, slicePointC),
        ' ',
        phoneNumberString.slice(slicePointC),
      ].join('');
    }
  }

  maskZipCode(zipCodeString) {
    const slicePointA = 5;

    if (zipCodeString.length >= slicePointA)
      return [
        zipCodeString.slice(0, slicePointA),
        '-',
        zipCodeString.slice(slicePointA),
      ].join('');
    else return zipCodeString;
  }

  toKebabCase(str) {
    return (
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join('-')
    );
  }
  isEmpty(stringValue) {
    return (!stringValue || 0 === stringValue.length);
  }
  formatDate(value) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY');
    }
    return value;
  }
}

export default new Utils();
