import { render, staticRenderFns } from "./subscription-info.vue?vue&type=template&id=35c4473b&scoped=true"
import script from "./subscription-info.vue?vue&type=script&lang=js"
export * from "./subscription-info.vue?vue&type=script&lang=js"
import style0 from "./subscription-info.scss?vue&type=style&index=0&id=35c4473b&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c4473b",
  null
  
)

export default component.exports