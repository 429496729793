import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

class SentryService {
  static setup(Router) {
    const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
    const DEBUG = process.env.VUE_APP_SENTRY_DEBUG === 'true';
    const RELEASE = `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`;
    const SAMPLE_RATE = +process.env.VUE_APP_SENTRY_SAMPLE_RATE;
    const TRACES_SAMPLE_RATE = +process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE;
    const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
    const config = {
      dsn: SENTRY_DSN,
      debug: DEBUG,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
          attachProps: true,
          tracing: true
        })],
      release: RELEASE,
      environment: ENVIRONMENT,
      sampleRate: SAMPLE_RATE,
      tracesSampleRate: TRACES_SAMPLE_RATE,
    };
    Sentry.init(config);
  }
  static sendCookieSentry(cookieInBase64) {
    if (!cookieInBase64) {
      return;
    }

    Sentry.configureScope(scope => {
      scope.setExtra('user_data_base64', cookieInBase64);
    });
  }
  static sendUserDataSentry(userData) {
    if (!userData) {
      return;
    }

    Sentry.configureScope(scope => {
      scope.setUser({
        id: userData.id,
        username: userData.nome,
        email: userData.email,
      });
    });
  }
}

export default SentryService;
