import axios from 'axios';
import Utils from '@/assets/js/Utils';

class AuthService {
  constructor() {
    this.api_url = process.env.VUE_APP_URL_API_AUTH;
  }

  setAuthorizationHeader(authToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  }

  setUserIdHeader(userId) {
    axios.defaults.headers.common['X-User-Id'] = userId;
  }

  login(email, password) {
    const { browser, version } = Utils.getBrowserInfo();
    const loginInfo = {
      cod_app: process.env.VUE_APP_NAME,
      dispositivo: browser,
      so_versao: version,
      app_versao: process.env.VUE_APP_VERSION,
      so: 'WEB',
      email,
      senha: password,
    };

    return axios.post(`${this.api_url}/login`, loginInfo);
  }

  resetPassword(email) {
    return axios.patch(`${this.api_url}/password/reset`, { email });
  }

  getUserDevices(userId) {
    return axios.get(`${this.api_url}/users/${userId}/devices`);
  }

  deleteUserDevice(userId, deviceId) {
    return axios.delete(`${this.api_url}/users/${userId}/devices/${deviceId}`);
  }
}

export default new AuthService();
