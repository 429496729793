import SnowplowService from '../services/SnowplowService';
import BrazeService from '../services/braze';
import GTMService from '../services/GTMService';

export default {
  methods: {
    clarity() {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'dbpoemxtdg');
    },

    trackSnowplow(event, params) {
      SnowplowService[event](params);
    },

    trackBraze(event, params) {
      BrazeService[event](params);
    },

    trackGTM(event, params) {
      GTMService[event](params);
    },
  },
};
