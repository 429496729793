export default {
  methods: {
    navigateToRoute: function (routeInfo, destroyToast = true) {
      if (destroyToast) {
        this.$root.$emit('destroyToast');
      }

      let routeObj = {};

      if (typeof routeInfo === 'string')
        routeObj = { name: routeInfo, query: this.$route.query };
      else if (typeof routeInfo === 'object')
        routeObj = { ...this.$route, ...routeInfo };
      else
        return console.error(
          'Incorrect parameter type. Parameter should be an Object or String, only.'
        );

      if (routeObj.name !== this.$route.name)
        this.$router
          .push(routeObj)
          .catch((err) => console.warn(err.message ? err.message : err));
    },
  },
};
