<template>
  <div :id="toastId" :class="classToast">
    <div class="toast-text">
      <slot />
    </div>
    <a @click="forceClose" class="toast-close">
      &times;
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'danger',
      },
      dismissTime: {
        type: Number,
        default: 30000,
      }
    },
    data() {
      return {
        toastId: '',
      }
    },
    computed: {
      classToast() {
        return {
          [this.color]: true,
          'toast-container': true,
        }
      }
    },
    async created() {
      this.timer().then(() => {
        this.destroyToast();
      }).catch(() => {});

      this.toastId = this.makeRandomId();
    },
    methods: {
      timer() {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            const element = document.getElementById(this.toastId);
            if(element) {
              element.classList.add('hide');
              resolve();
            } else {
              reject();
            }
          }, this.dismissTime);
        })
      },
      destroyToast() {
        setTimeout(() => {
          this.$root.$emit('destroyToast');
        }, 1000)
      },
      async forceClose() {
        return new Promise(resolve => {
          document.getElementById(this.toastId).classList.add('hide');
          resolve();
        }).then(() => {
          this.destroyToast();
        })
      },
      makeRandomId() {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < 5; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
    }
  };
</script>

<style lang="scss" scoped src="./Toast.scss"></style>
