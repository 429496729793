import axios from 'axios';

const apiLayerBase = axios.create({
  baseURL: process.env.VUE_APP_AFYA_LAYER_URL,
});

const ENDERECO_DEFAULT = {
  city: 'Rio de Janeiro',
  country: 'BR',
  district: 'Botafogo',
  line_1: 'Rua da Passagem, 123',
  line_2: '7o andar',
  postalCode: '22290030',
  state: 'RJ'
}; 

const getCardName = (cardName) => {
  const arrayName = cardName.split(' ').filter(name => name !== '');
  const [firstName, ...otherNames] = arrayName;
  const lastName = otherNames.join(' ');
  return {
    firstName,
    lastName,
  }
}
class AfyaLayerService {

  createPaymentToken(card, customerInfo) {
    const payload = {
      billing_address: ENDERECO_DEFAULT,
      customer: {
        address: ENDERECO_DEFAULT,
        birth_date: '01/01/1970',
        document: customerInfo.document,
        email: customerInfo.email,
        first_name: getCardName(card.holder_name).firstName,
        gender: 'male',
        last_name: getCardName(card.holder_name).lastName,
        phone: '5511999999999',
      },
      cvv: card.cvv,
      exp_month: card.exp_month,
      exp_year: card.exp_year,
      holder: card.holder_name,
      number: card.number,
    }
    return apiLayerBase.post(`/cards`, payload);
  }
}

export default new AfyaLayerService();
