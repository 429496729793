class IuguService {
  constructor() {
    this.accountToken = '0B864B780D28411F91D0172DAB585A2B';

    Iugu.setAccountID(this.accountToken);
    Iugu.setTestMode(process.env.VUE_APP_ENVIRONMENT !== 'PRODUCTION');
  }

  createCreditCardObj(
    cardNumber,
    cardExpirationDate,
    cardVerificationValue,
    cardName,
  ) {
    const [expirationMonth, expirationYear] = cardExpirationDate.split('/');
    const nameSlicePoint = cardName.indexOf(' ');
    const firstName = cardName.slice(0, nameSlicePoint);
    const lastName = cardName.slice(nameSlicePoint + 1);

    return Iugu.CreditCard(
      cardNumber,
      expirationMonth,
      expirationYear,
      firstName,
      lastName,
      cardVerificationValue,
    );
  }

  validateCardNumber(cardNumber) {
    return Iugu.utils.validateCreditCardNumber(cardNumber);
  }

  validateCardExpirationDate(cardExpirationDate) {
    return Iugu.utils.validateExpirationString(cardExpirationDate);
  }

  validateCardVerificationValue(cardNumber, cardVerificationValue) {
    const brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber);

    return Iugu.utils.validateCVV(cardVerificationValue, brand);
  }

  createPaymentToken(iuguCardObject) {
    return new Promise((resolve, reject) => {
      Iugu.createPaymentToken(iuguCardObject, response => {
        if (response.errors) {
          console.log('response.errors', response.errors);
          if (response.errors.adblock) {
            reject({ bug_iugu_criacao_token: true });
          }
          reject({ payment_token_creation: true });
        }

        resolve(response.id);
      });
    });
  }
}

export default new IuguService();
