<template>
  <div class="bankslip" v-bind:class="{bankSlipClosed: !showBankSlip}">
    <div class="slideout-closed-container" v-if="!showBankSlip">
      <span class="title-slideout-closed" v-if="!showBankSlip">Pagamento por boleto</span>
      <img class="slideout-img-closed" v-if="!showBankSlip" @click="manageBankSlipSlideout()"
          src="../../assets/images/svg/arrow.svg"
          alt="Ícone para retornar à tela anterior"
          title="Ícone para retornar à tela anterior"
        />
    </div>
    <div class="slideout">
      <div class="bankslip-payment">
        <div class="slideout-container">
          <span class="title">Pagamento por boleto</span>
          <img class="slideout-img" @click="manageBankSlipSlideout()"
              src="../../assets/images/svg/arrow.svg"
              alt="Ícone para retornar à tela anterior"
              title="Ícone para retornar à tela anterior"
            />
        </div>
        <span class="summary">O boleto já foi gerado e enviado para o seu e-mail.<p />
        Seu boleto vence em {{formatDate(userSession.subscription.premium.endDate)}}. Após o pagamento, pode levar até 3 dias úteis
        até a confirmação.</span>
        <div class="pix-payment-qrcode-summary" @click.prevent="copyBarcode">Copiar código de barras</div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserSessionService from '@/services/user-session';
  import Utils from '@/assets/js/Utils';
  import LanguageString from '@/config/language/pt-BR';

  export default {
    name: 'BankSlipPayment',
    props: {
      showBankSlip: {
        type: Boolean,
        required: true,
      }
    },
    created() {
      this.languageString = LanguageString;
      this.userSession = UserSessionService.getData();
      this.digitableLine = window.sessionStorage.getItem('digitableLine');
    },
    methods: {
      formatDate: Utils.formatDate,
      manageBankSlipSlideout() {
       this.$emit('manage-bankslip-slideout')
      },
      copyBarcode() {
        try {
          this.trackSnowplow('trackButton', {
            button_name: 'copy_barcode',
            additional_properties: 'Copy digitlable line from congrats'
          });
          if(this.digitableLine) {
            let barcodeElement = document.createElement("textarea");
            barcodeElement.value = this.digitableLine;
            barcodeElement.style.position = "fixed";
            barcodeElement.style.left = "-999999px";
            barcodeElement.style.top = "-999999px";
            document.body.appendChild(barcodeElement);
            barcodeElement.focus();
            barcodeElement.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                barcodeElement.remove();
                  this.createToast(this.languageString.CONGRATS_copiedCodeSuccess,
                  {},
                  'copyBankSlipCodeSuccess'
                );
            });
          } else {
            this.createToast(this.languageString.CONGRATS_copiedCodeError,
              {},
              'copyBankSlipCodeSuccess'
            );
          }
        } catch (err) {
          this.createToast(this.languageString.CONGRATS_copiedCodeError);
        }
        this.cleanSelection();
      },
      cleanSelection() {
        window.getSelection().removeAllRanges();
      },
    }
  };
</script>

<style scoped lang="scss" src="./BankSlipPayment.scss"></style>
