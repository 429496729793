import constants from '../../constants/index'

export default {
  CONGRATS_congratulations: 'Parabéns!',
  CONGRATS_warning: 'ATENÇÃO! <br/><br/> Será feito uma pré-autorização no seu cartão e você <strong>verá duas cobranças</strong> com o valor do plano escolhido por você.<br/><br/> Mas não se preocupe! Uma delas será cancelada e <strong>você não será cobrado duas vezes</strong>.',
  CONGRATS_success: 'Parabéns, seu conteúdo premium já está liberado!',
  CONGRATS_subTitlePayment: 'A PEBMED se comprometeu a doar R$150.000,00 ao Instituto da Criança para criação de leitos, fornecimento de EPIs e testes de COVID-19, sem acréscimo no valor da sua assinatura. Doe você também!',
  CONGRATS_captionOfCongratulationsIcon: 'Icone de sucesso ao realizar a assinatura, obrigado!',
  CONGRATS_informativeBankSlip: endDate => `Seu boleto vence em ${endDate}. Após o pagamento, pode levar até 3 dias úteis até a confirmação.`,
  CONGRATS_subTitleBankSlip: `Seu boleto já foi gerado e está disponível para pagamento.`,
  CONGRATS_subTitlePix: `O QR-Code do PIX foi gerado e está logo abaixo.`,
  CONGRATS_manageSubscription: 'Gerenciar Assinatura',
  CONGRATS_goToHome: 'ACESSAR CONTEÚDO PREMIUM',
  CONGRATS_copyCode: 'Copiar código de barras',
  CONGRATS_copiedCodeSuccess: 'Código copiado com sucesso!',
  CONGRATS_copiedCodeError: 'Oops, erro ao copiar o código de barras. Por favor, tente novamente!',
  CONGRATS_copiedQRCodeError: 'Oops, erro ao copiar o código do PIX. Por favor, tente novamente!',
  CONGRATS_wantToDonateMore: 'Quero doar',
  CREDIT_CARD_FAILURE_captionOfCardErrorIcon: 'Icone de error ao realizar a assinatura por cartão!',
  CREDIT_CARD_FAILURE_ops: 'Ops!',
  CREDIT_CARD_FAILURE_subTitle: 'Algo deu errado ao finalizar sua assinatura. Verifique as informações do cartão ou escolha uma nova forma de pagamento.',
  CREDIT_CARD_FAILURE_payWithBankSlip: 'Pagar com boleto',
  CREDIT_CARD_FAILURE_useAnotherCard: 'Utilizar outro cartão',
  CREDIT_CARD_FAILURE_informationOnPaymentOfBankSlip: '*Para pagamento com Boleto, só disponibilizamos opção de pagamento anual à vista.',
  BANK_SLIP_FAILURE_subTitle: `Algo deu errado. Entre em contato com o suporte pelo Whatsapp ${constants.WHATSAPP_PHONE}`,
  BANK_SLIP_FAILURE_tryAgain: 'tentar novamente',
  BANK_SLIP_FAILURE_altImage: 'Erro ao gerar o Boleto',
  GENERAL_FAILURE_errorTitle: 'Verifique sua conexão com a internet e tente novamente. ',
  GENERAL_FAILURE_subTitle: `Algo deu errado. Tente novamente ou entre em contato com o suporte pelo Whatsapp ${constants.WHATSAPP_PHONE}.`,
};
