<template>
  <div class="container-subscription-info" v-if="hasSubscriptionData">
    <div class="subscription-info">
      <h1>Assinatura</h1>
      <div>
        <ul>
          <li class="status">
            <strong>Status da assinatura</strong>
            <span
              v-if="hasSubscriptionData && getStatus() !== 'PENDENTE' && (getSubscription().trial || getStatus() === 'TRIAL')"
              class="text-status aproved"
              id="subscription-info-status"
            >
              Período de teste
              <em class="fa fa-check" />

              <div class="container-message-alert">
                <div class="container-top-message">
                  <div class="container-icon">
                    <img src="../../assets/images/shape.png" alt="info-icon" />
                  </div>
                  <p class="text-alert">
                    Seu período de testes encerra no dia
                    {{ formatDate(responseSubscription.data.subscription.endDate) }}.
                    <span v-if="responseSubscription.data.subscription.payment.paid.method === 'credit_card'">
                      Após essa data, enviaremos a cobrança na fatura do seu cartão de crédito.
                    </span>
                    <span v-else>
                      Após essa data, realize o pagamento do boleto para continuar utilizando o Whitebook.
                    </span>
                  </p>
                </div>
                <div
                  v-if="
                    responseSubscription.data.subscription.payment.paid.method === 'bank_slip' && invoiceStatus === 'pending'
                  "
                  class="container-buttons"
                >
                  <a class="button-bankslip" @click="copyBarcode()">
                    <p v-if="bankSlipDigitableLineCopied">
                      Código de barras copiado
                      <img height="10" width="10" src="../../assets/images/svg/check-success.svg" alt="info-icon" />
                    </p>
                    <p v-else>Copiar código de barras</p>
                  </a>
                  <a class="button-bankslip" @click="downloadBankSlip()">
                    <p>Baixar boleto</p>
                  </a>
                </div>
              </div>
            </span>
            <span
              v-else-if="
                hasSubscriptionData &&
                (getStatus() === 'APROVADA' ||
                  getStatus() === 'SUSPENSA' ||
                  getStatus() === 'REEMBOLSADA' ||
                  getStatus() === 'CONTESTADA' ||
                  getStatus() === 'PRE-AUTORIZADA' ||
                  getStatus() === 'PENDENTE') &&
                !assinaturaCancelada
              "
            >
              <span v-if="getStatus() === 'APROVADA'" class="text-status aproved">
                {{ status[responseSubscription.data.subscription.status] }}
                <em class="fa fa-check" />
              </span>
              <span v-else class="text-status pending">
                {{ status[responseSubscription.data.subscription.status] }}
                <em class="fa fa-times" />
              </span>

              <div
                v-if="
                  responseSubscription.data.subscription.payment.paid.method === 'bank_slip' && invoiceStatus === 'pending'
                "
                class="container-message-alert"
              >
                <div class="container-top-message">
                  <div class="container-icon">
                    <img src="../../assets/images/shape.png" alt="info-icon" />
                  </div>
                  <p class="text-alert">
                    Não identificamos o pagamento. Por favor, regularize agora e evite o cancelamento da assinatura.
                  </p>
                </div>
                <div
                  v-if="
                    responseSubscription.data.subscription.payment.paid.method === 'bank_slip' && invoiceStatus === 'pending'
                  "
                  class="container-buttons"
                >
                  <a class="button-bankslip" @click="copyBarcode()">
                    <p v-if="bankSlipDigitableLineCopied">
                      Código de barras copiado
                      <img height="10" width="10" src="../../assets/images/svg/check-success.svg" alt="info-icon" />
                    </p>
                    <p v-else>Copiar código de barras</p>
                  </a>
                  <a class="button-bankslip" @click="downloadBankSlip()">
                    <p>Baixar boleto</p>
                  </a>
                </div>
              </div>
              <div v-if="getStatus() === 'PRE-AUTORIZADA'" class="container-message-alert">
                <div class="container-top-message">
                  <div class="container-icon">
                    <img src="../../assets/images/shape.png" alt="info-icon" />
                  </div>
                  <p class="text-alert">
                    Sua assinatura não foi finalizada. Favor entrar em contato com nosso suporte através do Whatsapp.
                  </p>
                </div>
                <div v-if="getStatus() === 'PRE-AUTORIZADA'" class="container-button">
                  <a
                    class="button-whatsapp"
                    href="https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0"
                    target="_blank"
                  >
                    <p>Fale conosco pelo Whatsapp</p>
                  </a>
                </div>
              </div>
            </span>
            <span
              v-else-if="
                hasSubscriptionData &&
                (getStatus() === 'APROVADA' ||
                  getStatus() === 'SUSPENSA' ||
                  getStatus() === 'REEMBOLSADA' ||
                  getStatus() === 'CONTESTADA' ||
                  getStatus() === 'PENDENTE') &&
                assinaturaCancelada
              "
            >
              Ativa, mas sem renovação
            </span>
          </li>
          <li class="email">
            <strong>Email</strong>
            <span v-if="hasSubscriptionData" id="subscription-info-email">{{ userSession.currentUser.email }}</span>
          </li>
          <li class="plan">
            <strong>Plano</strong>
            <span v-if="hasSubscriptionData" id="subscription-info-plan">
              {{ responseSubscription.data.subscription.plan.description }}
            </span>
          </li>
          <li v-if="responseSubscription.data.subscription.plan.storeId !== 'pebmed'">
            <strong>Valor</strong>
            <span v-if="isPagoComCupomDeDesconto" id="subscription-info-value"
              >R$ {{ valorPagoDoPlano }} (com cupom de desconto promocional)</span
            >
            <span v-else>
              <span v-if="isPagamentoAVista && isGatewayWithDefaultDiscount" id="subscription-info-value"
                >R$ {{ valorPagoDoPlano }} (com desconto promocional à vista)</span
              >
              <span v-else-if="isGatewayWithDefaultDiscount" id="subscription-info-value"
                >R$ {{ valorPagoDoPlano }} (com desconto promocional parcelado)</span
              >
              <span v-else id="subscription-info-value"
                >R$ {{ valorPagoDoPlano }}</span
              >
            </span>
          </li>
          <li
            v-if="
              (responseSubscription.data.subscription.payment.paid.method === 'credit_card' ||
                responseSubscription.data.subscription.payment.paid.method === 'bank_slip' ||
                responseSubscription.data.subscription.payment.paid.method === 'pix') &&
              !((getSubscription().trial && getStatus() === 'APROVADA') || getStatus() === 'PRE-AUTORIZADA') &&
              responseSubscription.data.subscription.plan.storeId !== 'pebmed'
            "
          >
            <strong>Forma de pagamento</strong>
            <span
              id="subscription-info-payment"
              v-if="responseSubscription.data.subscription.payment.paid.method === 'credit_card'"
              >Cartão de crédito</span
            >
            <span
              id="subscription-info-payment"
              v-else-if="responseSubscription.data.subscription.payment.paid.method === 'bank_slip'"
              >Boleto</span
            >
            <span
              id="subscription-info-payment"
              v-else-if="responseSubscription.data.subscription.payment.paid.method === 'pix'"
              >Pix</span
            >
            <span id="subscription-info-payment" v-else>{{
              responseSubscription.data.subscription.payment.paid.method
            }}</span>
          </li>

          <li
            v-if="
              (responseSubscription.data.subscription.payment.paid.method === 'credit_card' ||
                responseSubscription.data.subscription.payment.paid.method === 'bank_slip' ||
                responseSubscription.data.subscription.payment.paid.method === 'pix') &&
              !((getSubscription().trial && getStatus() === 'APROVADA') || getStatus() === 'PRE-AUTORIZADA') &&
              responseSubscription.data.subscription.plan.storeId !== 'pebmed' &&
              responseSubscription.data.subscription.payment.paid.installments &&
              responseSubscription.data.subscription.payment.paid.installmentValue
            "
          >
            <strong>Quantidade de parcelas</strong>
            <span
              id="subscription-info-installments"
              v-if="responseSubscription.data.subscription.payment.paid.installments === 1"
            >
              À vista
            </span>
            <span id="subscription-info-installments" v-else>
              {{ responseSubscription.data.subscription.payment.paid.installments }}x de
              {{ responseSubscription.data.subscription.payment.paid.installmentValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
            </span>
          </li>

          <div
            v-if="
              hasSubscriptionData &&
              getStatus() !== 'PRE-AUTORIZADA' &&
              formatDate(getSubscription().beginDate) !== formatDate(getSubscription().endDate)
            "
          >
            <li class="endDate">
              <span v-if="getSubscription().trial && getStatus() === 'APROVADA'">
                <strong>Fim do período de teste </strong>
              </span>
              <span
                v-else-if="
                  getStatus() === 'SUSPENSA' ||
                  getStatus() === 'REEMBOLSADA' ||
                  getStatus() === 'CONTESTADA' ||
                  (getSubscription().payment.paid.value === 0 && getStatus() === 'APROVADA') ||
                  assinaturaCancelada
                "
              >
                <strong>Ativa até</strong>
              </span>
              <span v-else-if="getStatus() === 'APROVADA' && !getSubscription().trial">
                <strong>Data da assinatura</strong>
              </span>
              <span
                id="subscription-info-subscriptionDate"
                v-if="
                  getStatus() === 'APROVADA' &&
                  !getSubscription().trial &&
                  responseSubscription.data.subscription.plan.storeId !== 'pebmed'
                "
                class="text-date"
              >
                {{ formatDate(responseSubscription.data.subscription.beginDate) }}
              </span>
              <span
                id="subscription-info-subscriptionDate"
                v-else-if="hasSubscriptionData && getStatus() !== 'PENDENTE' && getStatus() !== 'TRIAL'"
                class="text-date"
              >
                {{ formatDate(responseSubscription.data.subscription.endDate) }}
              </span>
            </li>
          </div>
        </ul>
      </div>
      <p
        v-if="getStatus() !== 'PRE-AUTORIZADA'"
        class="title about"
        :class="{ open: aboutInfo.show }"
        @click="aboutInfo.show = !aboutInfo.show"
      >
        Alterar assinatura
      </p>
      <div class="container-body-alterar-assinatura modify-subscription" :class="{ open: aboutInfo.show }">
        <div class="container-text">
          <p class="body-title">Alterar assinatura</p>
          <p class="body">
            Para alterar sua assinatura, acesse a nossa
            <a href="https://help.pebmed.com.br/hc/pt-br" target="_blank">Central de Ajuda</a>
            ou fale conosco
            <a
              href="https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0"
              target="_blank"
            >
              aqui</a
            >
          </p>
        </div>
        <div class="confirm-button">
          <p @click="aboutInfo.show = !aboutInfo.show">Ok, entendi</p>
        </div>
        <span class="container-arrow-down"></span>
      </div>
    </div>      

    <div
      class="renewal-info"
      v-if="isShowRenewalInformation"
    >
      <div class="about-renewal">
        <h1 class="title renewal" :class="{ open: renewalInfo.show }" @click="renewalInfo.show = !renewalInfo.show">
          Renovação
          <img src="../../assets/images/icon-info.png" class="icon-info" alt="info-icon" />
        </h1>
        <div class="container-body renewal-subscription" :class="{ open: renewalInfo.show }">
          <div class="container-text">
            <p class="body-title">Renovação</p>
            <p class="body">
              A renovação de qualquer Plano Whitebook é feita com base no valor total, não contemplando nenhum desconto
              oferecido anteriormente.
            </p>
          </div>
          <div class="confirm-button">
            <p @click="renewalInfo.show = !renewalInfo.show">Ok, entendi</p>
          </div>
          <span class="container-arrow-down"></span>
        </div>
      </div>
      <div>
        <ul>
          <li class="endDate" v-if="getStatus() !== 'PENDENTE'">
            <span v-if="!assinaturaCancelada">
              <strong>Data da renovação</strong>
            </span>
            <span
              id="subscription-info-renewDate"
              v-if="
                hasSubscriptionData &&
                getStatus() !== 'PENDENTE' &&
                getStatus() !== 'SUSPENSA' &&
                getStatus() !== 'REEMBOLSADA' &&
                getStatus() !== 'CONTESTADA' &&
                !getSubscription().trial
              "
              class="text-date"
            >
              {{ formatDate(responseSubscription.data.subscription.endDate) }}
            </span>
            <span id="subscription-info-renewDate" v-else class="text-date"> Sem renovação automática </span>
          </li>
          <li
            v-if="
              hasSubscriptionData &&
              getStatus() !== 'PENDENTE' &&
              getStatus() !== 'SUSPENSA' &&
              getStatus() !== 'REEMBOLSADA' &&
              getStatus() !== 'CONTESTADA' &&
              !getSubscription().trial
            "
          >
            <strong>Valor da renovação</strong>
            <span v-if="isRenovacaoComCupomDeDesconto" id="subscription-info-renewValue"
              >R$ {{ valorDeRenovacaoDoPlano }} (com cupom de desconto promocional)</span
            >
            <span v-else>
              <span v-if="isPagamentoAVista && isGatewayWithDefaultDiscount" id="subscription-info-renewValue"
                >R$ {{ valorDeRenovacaoDoPlano }} (com desconto promocional à vista)</span
              >
              <span v-else-if="isGatewayWithDefaultDiscount" id="subscription-info-renewValue"
                >R$ {{ valorDeRenovacaoDoPlano }} (com desconto promocional parcelado)</span
              >
              <span v-else id="subscription-info-renewValue"
                >R$ {{ valorDeRenovacaoDoPlano }}</span
              >

            </span>
          </li>
          <li>
            <strong>Valor Original do Plano</strong>
              <span id="subscription-info-renewValue"
                >R$ {{ valorOriginalDoPlano }}</span
              >
          </li>
          <li
            v-if="
              hasSubscriptionData &&
              getStatus() !== 'PENDENTE' &&
              getStatus() !== 'SUSPENSA' &&
              getStatus() !== 'REEMBOLSADA' &&
              getStatus() !== 'CONTESTADA' &&
              !getSubscription().trial &&
              (responseSubscription.data.subscription.payment.renewalPrice.method === 'credit_card' ||
                responseSubscription.data.subscription.payment.renewalPrice.method === 'bank_slip' ||
                responseSubscription.data.subscription.payment.renewalPrice.method === 'pix')
            "
          >
            <strong>Forma de pagamento</strong>
            <span
              id="subscription-info-renewPayment"
              v-if="responseSubscription.data.subscription.payment.renewalPrice.method === 'credit_card'"
              >Cartão de crédito</span
            >
            <span
              id="subscription-info-renewPayment"
              v-else-if="responseSubscription.data.subscription.payment.renewalPrice.method === 'bank_slip'"
              >Boleto</span
            >
            <span
              id="subscription-info-renewPayment"
              v-else-if="responseSubscription.data.subscription.payment.renewalPrice.method === 'pix'"
              >Pix</span
            >
            <span id="subscription-info-renewPayment" v-else>{{
              responseSubscription.data.subscription.payment.renewalPrice.method
            }}</span>
          </li>
          <li
            v-if="
              hasSubscriptionData &&
              getStatus() !== 'PENDENTE' &&
              getStatus() !== 'SUSPENSA' &&
              getStatus() !== 'REEMBOLSADA' &&
              getStatus() !== 'CONTESTADA' &&
              !getSubscription().trial &&
              (responseSubscription.data.subscription.payment.renewalPrice.method === 'credit_card' ||
                responseSubscription.data.subscription.payment.renewalPrice.method === 'bank_slip' ||
                responseSubscription.data.subscription.payment.renewalPrice.method === 'pix')
                &&
                !!responseSubscription.data.subscription.payment.renewalPrice.installments &&
                !!responseSubscription.data.subscription.payment.renewalPrice.installmentValue
            "
          >
            <strong>Quantidade de parcelas</strong>
            <span
              id="subscription-info-renewInstallments"
              v-if="responseSubscription.data.subscription.payment.renewalPrice.installments === 1"
              >À vista</span
            >
            <span id="subscription-info-renewInstallments" v-else>
              {{ responseSubscription.data.subscription.payment.renewalPrice.installments }}x de
              {{ responseSubscription.data.subscription.payment.renewalPrice.installmentValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
            </span>
          </li>
          <li v-if="hasSubscriptionData && getStatus() === 'PENDENTE'" class="description-subscription-pending">
            <span
              id="subscription-info-pendingSubscription"
              v-if="responseSubscription.data.subscription.payment.paid.method === 'credit_card'"
            >
              Para continuar a usar o Whitebook, por favor, regularize seu Cartão de Crédito.
            </span>
            <span
              id="subscription-info-pendingSubscription"
              v-else-if="responseSubscription.data.subscription.payment.paid.method === 'bank_slip'"
            >
              Para continuar a usar o Whitebook, por favor, verifique as informações de pagamento por Boleto no seu
              e-mail, ou adicione um novo Cartão de Crédito para pagamento.
            </span>
          </li>
        </ul>
      </div>

      <div class="container-btn-renewal">
        <!-- botão alterar meio pagamento -->
        <div
          v-if="isPaymentMethodChangeAllowed"
          class="btn-payment-method-change"
          >
          <p
            class="title"
            @click="redirectToChangePaymentMethod()"
          >
            Alterar método de pagamento
          </p>

        </div>

        <!-- botão cancelamento -->
      <div
        v-if="
          hasSubscriptionData &&
          getStatus() !== 'PENDENTE' &&
          getStatus() !== 'SUSPENSA' &&
          getStatus() !== 'REEMBOLSADA' &&
          getStatus() !== 'CONTESTADA' &&
          !getSubscription().trial &&
          iugu
        "
        class="about"
      >
        <p
          class="title"
          v-if="cancelamentoDisponivel"
          :class="{ open: aboutInfo.show }"
          @click="showModal = !showModal"
        >
          Cancelar renovação
        </p>
        <slideout-cancelamento
          v-on:fechar-modal-e-bloquear-cancelamento="fecharModalEBloquearCancelamento()"
          v-on:fechar-modal="fecharModal()"
          v-if="showModal"
        />
      </div>
      <div
        v-else-if="
          hasSubscriptionData &&
          getStatus() !== 'PENDENTE' &&
          getStatus() !== 'SUSPENSA' &&
          getStatus() !== 'REEMBOLSADA' &&
          getStatus() !== 'CONTESTADA' &&
          !getSubscription().trial
        "
        class="about"
      >
        <p class="title" :class="{ open: cancelInfo.show }" @click="cancelInfo.show = !cancelInfo.show">
          Cancelar renovação
        </p>
        <div class="container-body cancel-subscription" :class="{ open: cancelInfo.show }">
          <div class="container-text">
            <p class="body-title">Cancelar assinatura</p>
            <p class="body">
              Para cancelar sua assinatura, acesse a nossa
              <a href="https://help.pebmed.com.br/hc/pt-br" target="_blank">Central de Ajuda</a>
              ou fale conosco
              <a
                href="https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0"
                target="_blank"
              >
                aqui</a
              >
            </p>
          </div>
          <div class="confirm-button">
            <p @click="cancelInfo.show = !cancelInfo.show">Ok, entendi</p>
          </div>
          <span class="container-arrow-down"></span>
        </div>
      </div>
      </div>

    </div>
  </div>
</template>

<script>
  import Utils from '@/assets/js/Utils';
  import userSessionService from '@/services/user-session';
  import SubscriptionService from '@/services/SubscriptionService';
  import { subscriptionStatusMessageMapper } from '@/filters/subscriptionStatusMapper';
  import SlideoutCancelamento from '@/components/SlideoutCancelamento';
  import LanguageString from '@/config/language/pt-BR';

  export default {
    name: 'SubscriptionInfo',
    components: {
      'slideout-cancelamento': SlideoutCancelamento,
    },
    data() {
      return {
        aboutInfo: {
          show: false,
        },
        renewalInfo: {
          show: false,
        },
        cancelInfo: {
          show: false,
        },
        userSession: null,
        responseSubscription: null,
        status: subscriptionStatusMessageMapper,
        showModal: false,
        cancelamentoDisponivel: true,
        isPaymentMethodChangeAllowed: true,
        isShowRenewalInformation: true,
        assinaturaCancelada: false,
        iugu: false,
        renewalInstallmentValue: null,
        bankSlipSecureUrl: null,
        bankSlipDigitableLine: null,
        bankSlipDigitableLineCopied: false,
        invoiceStatus: null,
        languageString: LanguageString,
        gracePeriodDays: process.env.VUE_APP_GRACE_PERIOD_DAYS,
      };
    },
    mounted() {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'dbpoemxtdg');
    },
    computed: {
      hasSubscriptionData() {
        return this.responseSubscription;
      },
      isPagoComCupomDeDesconto() {
        return this.responseSubscription.data.subscription.payment.paid.withCoupon;
      },
      isRenovacaoComCupomDeDesconto() {
        return this.responseSubscription.data.subscription.payment.renewalPrice.withCoupon;
      },
      isPagamentoAVista() {
        return this.responseSubscription.data.subscription.payment.paid.installments === 1;
      },
      isGatewayWithDefaultDiscount() {
        return this.responseSubscription.data.subscription.payment.gateway.name !== 'ios' && this.responseSubscription.data.subscription.payment.gateway.name !== 'android' && this.responseSubscription.data.subscription.payment.gateway.name !== 'pebmed';
      },
      valorOriginalDoPlano() {
        return this.responseSubscription.data.subscription.plan.price.formatted.base;
      },
      valorPagoDoPlano() {
        return this.responseSubscription.data.subscription.payment.paid.formattedValue;
      },
      valorDeRenovacaoDoPlano() {
        return this.responseSubscription.data.subscription.payment.renewalPrice.formattedValue;
      }
    },

    async created() {
      this.initLoading();

      this.userSession = userSessionService.getData();
      try {
        const subscription = await SubscriptionService.getSubscriptionStatus(this.userSession.currentUser.id);
        const status = subscription.data.data.subscription.status;
        this.userSession.gateway = subscription.data.data.subscription.payment.gateway;

        const gateway = this.userSession.gateway.name;
        const isTrial = subscription.data.data.subscription.trial;
        const isSplittable = subscription.data.data.subscription.plan.splittable;
        const isAnnualPlan = subscription.data.data.subscription.plan.period;

        let isSubscriptionStatusAllowedToChangePaymentMethod = true;
        let isAllowedGateway = true;
        let isAnnual = true;

        if (subscription.data.data.subscription.payment.paid.method === 'bank_slip') {
          await this.getInvoices(subscription.data.data.subscription.id);
        }

        this.finishLoading();

        this.responseSubscription = subscription.data;
        if (status === 'SUSPENSA' || status === 'REEMBOLSADA' || status === 'CONTESTADA') {
          this.cancelamentoDisponivel = false;
        }

        if (status === 'PRE-AUTORIZADA' || status === 'SUSPENSA' || status === 'REEMBOLSADA' || status === 'CONTESTADA' || isTrial) {
          isSubscriptionStatusAllowedToChangePaymentMethod = false;
        }

        if (gateway !== 'iugu' && gateway !== 'pagarme' && gateway !== 'afya_layer') {
          isAllowedGateway = false;
        }

        if (isAnnualPlan !== 'annually') {
          isAnnual = false;
        }

        if (!isSubscriptionStatusAllowedToChangePaymentMethod || !isAllowedGateway || !isAnnual){
          this.isPaymentMethodChangeAllowed = false;
        }

        const isAccessCouponSubscription = this.responseSubscription.data.subscription.plan.storeId == 'pebmed';
        const isSubscriptionBankSlipAndTrial = (this.getSubscription().trial && this.getStatus() === 'APROVADA');
        const isSubscriptionCreditCardAndTrial = this.getStatus() === 'TRIAL';
        const isSubscriptionSecondCycle = this.getIntervalBetweenDates() > this.gracePeriodDays + 1;
        const isPreAutorized = this.getStatus() === 'PRE-AUTORIZADA';

        if (isAccessCouponSubscription) {
          this.isShowRenewalInformation = false;
        } 
        if (isSubscriptionBankSlipAndTrial || isSubscriptionCreditCardAndTrial) {
          this.isShowRenewalInformation = false;
        }
        if (!isSubscriptionSecondCycle) {
          this.isShowRenewalInformation = false;
        }
        if (isPreAutorized) {
          this.isShowRenewalInformation = false;
        }

      } catch (err) {
        this.finishLoading();
        if (err.response && err.response.status === 404) {
          this.userSession.subscription = {};
          userSessionService.setUserData(this.userSession);
          this.createToast(err.response.data.mensagem || err.response.data.message, {
            redirectTo: 'checkout',
          });
        } else {
          this.createToast(
            'Ocorreu um erro ao buscar a sua assinatura. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379',
            {
              redirectTo: 'login',
            },
          );
        }
      } finally {
        this.finishLoading();
      }

      const gateWayIUGU = 1;
      if (this.userSession.gateway.id === gateWayIUGU) {
        this.iugu = true;
      }
    },

    methods: {
      numberToCurrency: Utils.numberToCurrency,
      formatDate: Utils.formatDate,
      fecharModalEBloquearCancelamento() {
        this.showModal = false;
        this.cancelamentoDisponivel = false;
        this.assinaturaCancelada = true;
        location.reload(true);
      },
      fecharModal() {
        this.showModal = false;
      },
      getSubscription: function () {
        if (this.responseSubscription) {
          return this.responseSubscription.data.subscription;
        }
      },

      getStatus: function () {
        const status = this.responseSubscription.data.subscription.status;
        return status;
      },
      getIntervalBetweenDates() {
        const endDate = new Date(this.getSubscription().endDate);
        const beginDate = new Date(this.getSubscription().beginDate);

        const diff = Math.abs(endDate.getTime() - beginDate.getTime());
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

        return days;
      },
      async getInvoices(subscriptionId) {
        SubscriptionService.retrieveInvoices(subscriptionId)
          .then(response => {
            this.invoiceStatus = response.data.invoices[0].status;
            this.bankSlipDigitableLine = response.data.invoices[0].bank_slip.digitable_line;
            this.bankSlipSecureUrl = response.data.invoices[0].secure_url;
          })
          .catch(error => {
            console.log(error);
          });
      },
      async copyBarcode() {
        try {
          this.trackSnowplow('trackButton', {
            button_name: 'copy_barcode',
            additional_properties: 'Copy digitlable line from manage subscription',
          });
          if (this.bankSlipDigitableLine) {
            let barcodeElement = document.createElement('textarea');
            barcodeElement.value = this.bankSlipDigitableLine;
            barcodeElement.style.position = 'fixed';
            barcodeElement.style.left = '-999999px';
            barcodeElement.style.top = '-999999px';
            document.body.appendChild(barcodeElement);
            barcodeElement.focus();
            barcodeElement.select();
            return new Promise((res, rej) => {
              document.execCommand('copy') ? res() : rej();
              barcodeElement.remove();
              this.bankSlipDigitableLineCopied = true;
              alert(this.languageString.CONGRATS_copiedCodeSuccess);
            });
          } else {
            throw '';
          }
        } catch (error) {
          alert('Ocorreu um erro ao tentar copiar o código de barras!');
        }
      },
      downloadBankSlip: function () {
        try {
          this.trackSnowplow('trackButton', {
            button_name: 'download',
            additional_properties: 'Bankslip donwload from manage subscription',
          });
          window.open(this.bankSlipSecureUrl, '_blank');
        } catch (error) {
          alert('Ocorreu um erro ao tentar fazer o download do boleto!');
        }
      },
      redirectToChangePaymentMethod: function () {
        return window.location.assign(process.env.VUE_APP_URL_WB_PAYMENT_CHANGE);;
      }
    },
  };
</script>

<style scoped lang="scss" src="./subscription-info.scss" />
