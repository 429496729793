import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './views/login';
import CheckoutCard from './views/checkout-card';
import Congrats from './views/congrats';
import ManageSubscription from './views/ManageSubscription.vue';
import SubscriptionInfo from './views/subscription-info';
import ManageDevices from './views/ManageDevices';
import ManageBilling from './views/ManageBilling';
import CheckoutBankSlip from './views/checkout-bank-slip';
import UserSessionService from '@/services/user-session';
import NewCheckoutCard from './views/new-checkout-card';

Vue.use(VueRouter);

const isAuthorizedToAccess = (to, from, next) => {
  if (UserSessionService.isAuthenticated()) {
    next();
  } else {
    next({
      name: 'login',
      query: { redirectFullPath: to.fullPath },
    });
  }
};

const isAuthorizedToAccessManageBilling = (to, from, next) => {
  if (UserSessionService.isNotMobileGateway()) {
    next();
  } else {
    next({
      name: 'subscription-info',
    });
  }
};

const isAuthorizedToAcessPremium = (to, from, next) => {
  if (!UserSessionService.isAuthenticated()) {
    next({
      name: 'login',
      query: { redirectFullPath: to.fullPath },
    });
  } else if (!UserSessionService.isSubscriber()) {
    next({
      name: 'checkout',
    });
  } else {
    next();
  }
}

const successPageAccess = (to, from, next) => {
  if (!UserSessionService.isAuthenticated()) {
    next({
      name: 'login',
    });
  } else if (!UserSessionService.isSubscriber()){
    next({
      name: 'checkout',
    });
  } else {
    next();
  }
}

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login.php',
      name: 'login',
      component: Login,
      meta: {
        openRoute: true,
        free: true,
        step: 'login',
        requiresAuth: false,
      },
    },
    {
      path: '/forma-pagamento.php',
      name: 'checkout',
      component: CheckoutCard,
      beforeEnter: isAuthorizedToAccess,
      meta: {
        openRoute: false,
        free: true,
        step: 'payment',
        requiresAuth: true,
      },
    },
    {
      path: '/forma-pagamento/cartao',
      name: 'new-checkout',
      component: NewCheckoutCard,
      beforeEnter: isAuthorizedToAccess,
      meta: {
        openRoute: false,
        free: true,
        step: 'payment',
        requiresAuth: true,
      },
    },
    {
      path: '/forma-pagamento/boleto',
      name: 'PAYMENT_TYPE_BANK_SLIP',
      component: CheckoutBankSlip,
      beforeEnter: isAuthorizedToAccess,
      meta: {
        openRoute: false,
        free: true,
        step: 'payment',
        requiresAuth: true,
      },
    },
    {
      path: '/parabens.php',
      name: 'congrats',
      component: Congrats,
      beforeEnter: successPageAccess,
      meta: {
        openRoute: false,
        free: false,
        step: 'start',
        requiresAuth: true,
      },
    },
    {
      path: '/gerenciar-assinatura.php',
      name: 'manageSubscription',
      component: ManageSubscription,
      beforeEnter: isAuthorizedToAcessPremium,
      meta: {
        openRoute: false,
        free: false,
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          redirect: 'minha-assinatura',
        },
        {
          path: 'minha-assinatura',
          name: 'subscription-info',
          beforeEnter: isAuthorizedToAcessPremium,
          component: SubscriptionInfo,
        },
        {
          path: 'gerenciar-dispositivos',
          name: 'manage-devices',
          beforeEnter: isAuthorizedToAccess,
          component: ManageDevices,
        },
        {
          path: 'gerenciar-cobranca',
          name: 'manage-billing',
          beforeEnter: isAuthorizedToAccessManageBilling,
          component: ManageBilling,
        },
      ],
    },
    {
      path: '*',
      redirect: {
        name: 'login',
      },
    },
  ],
});
