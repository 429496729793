class SnowplowService {
  delayTillTrackSubscribeBeSynchronized(eventName) {
    try {
      setTimeout(() => {
        SnowplowTracker.trackCustomEvent({
          name: eventName,
        });
      }, 100);
    } catch (error) {
      console.warn('Analytics desativado', error);
    }
  }

  trackUser({ user }) {
    try {
      SnowplowTracker.trackUser(user);
    } catch (error) {
      console.warn('Analytics desativado', error);
    }
  }

  trackSubscribe({ step_name, step_num, step_value, failure_reason }) {
    try {
      if (step_name && step_num) {
        SnowplowTracker.trackSubscribe({
          type: 'web',
          step_name,
          step_num,
          step_value,
          failure_reason,
        });

        this.delayTillTrackSubscribeBeSynchronized('subscribe');
      }
    } catch (error) {
      console.warn('Analytics desativado', error);
    }
  }

  trackButton({ button_name, additional_properties }) {
    try {
      SnowplowTracker.trackButton({
        button_name,
        additional_properties,
      });

      this.delayTillTrackSubscribeBeSynchronized('customButton');
    } catch (error) {
      console.warn('Analytics desativado', error);
    }
  }

  trackListAction({ action_name, context, item_id, item_value, item_position }) {
    try {
      SnowplowTracker.trackListAction({
        action_name: action_name,
        context: context,
        item_id: item_id,
        item_value: item_value,
        item_position: item_position
      });

      this.delayTillTrackSubscribeBeSynchronized('listActionEvent');
    } catch (error) {
      console.warn('Analytics desativado', error);
    }
  }

  trackPopupView({ name, type = 'popup', popup_interaction = 'accept', failure_reason }) {
    try {
      SnowplowTracker.trackPopUpView({
        type: type,
        popup_interaction: popup_interaction,
        popup_value: name,
        failure_reason: failure_reason || null,
        schema: 'iglu:br.com.pebmed/popup_view/jsonschema/2-0-20'
      });

      this.delayTillTrackSubscribeBeSynchronized('popupEvent');
    } catch (error) {
      console.warn('Analytics desativado', error);
    }
  }

  sendSnowplowSubscribeEvent({
    form_element,
    step_name,
    step_num,
    step_value,
    failure_reason,
  }) {
    try {
      if (form_element && form_element.value) {
        let elementValue = form_element.value;
        if (typeof elementValue === 'string') {
          elementValue = elementValue.trim();
        }
        const formValueIsEmpty = !elementValue || 0 === elementValue.length;
        if (!formValueIsEmpty) {
          this.trackSubscribe({
            step_name,
            step_num,
            step_value,
            failure_reason,
          });
        }
      }
    } catch (error) {
      console.warn('Snowplow Analytics desativado', error);
    }
  }
}

export default new SnowplowService();
