<template>
  <div class="manage-billing" :style="plansHeight">
    <div v-if="registeredCreditCard" class="registered-card">
      <p class="group-title">Cartão Atual</p>
      <div class="card" :style="{ backgroundImage: brandStylization().background }">
        <div class="number">
          <div class="brand" :style="{ backgroundImage: brandStylization().logo }"></div>
          <span
            v-for="(item, index) in registeredCreditCard.number"
            :key="index"
            > {{ item.replace("XXXX", "••••") }}</span
          >
        </div>
        <div class="bottom-info">
          <div class="expiration">
          <span>Validade</span>
          <div class="date">
            {{ twoDigitsDate(registeredCreditCard.expirationDate) }}
          </div>
        </div>
        <div class="holder-name">
          {{ registeredCreditCard.holderName }}
        </div>
      </div>
      </div>
      <div class="about" @click="calculatePlansHeight">
        <p
          v-html="aboutInfo.text"
        />
        <p class="about-extra-info" v-html="aboutInfo.extraInfo"></p>
      </div>
    </div>
    <form class="form" ref="cardForm" @submit.prevent="submitNewCreditCard" novalidate="true">
      <div class="payment-info">
        <p class="group-title">Novo Cartão</p>
        <payment-methods />
        <form-input
          :name="'cardNumber'"
          :value="form.cardNumber.value"
          :error="form.cardNumber.error.message"
          @input="form.cardNumber.value = $event"
          @blur="
            trackSnowplow('sendSnowplowSubscribeEvent', {
              form_element: form.cardNumber,
              step_name: 'user_card_number',
              step_num: 1,
            })
          "
        />
        <div class="card-confirmation-info">
          <form-input
            :name="'cardExpirationDate'"
            :value="form.cardExpirationDate.value"
            :error="form.cardExpirationDate.error.message"
            @input="form.cardExpirationDate.value = $event"
            @blur="
              trackSnowplow('sendSnowplowSubscribeEvent', {
                form_element: form.cardExpirationDate,
                step_name: 'user_card_expiration',
                step_num: 2,
              })
            "
          />
          <form-input
            :name="'cardVerificationValue'"
            :value="form.cardVerificationValue.value"
            :error="form.cardVerificationValue.error.message"
            @input="form.cardVerificationValue.value = $event"
            @blur="
              trackSnowplow('sendSnowplowSubscribeEvent', {
                form_element: form.cardVerificationValue,
                step_name: 'user_card_cvv',
                step_num: 3,
              })
            "
          />
        </div>
        <form-input
          :name="'cardName'"
          :value="form.cardName.value"
          :error="form.cardName.error.message"
          @input="form.cardName.value = $event"
          @blur="trackerSnowplowAndBrazeByUserCardName(form)"
        />
        <div class="container">
          <div class="submit-form">
            <button name="submit-button" type="submit">
              Cadastrar novo cartão
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import FormInput from '@/components/FormInput';
  import PaymentMethods from '@/components/payment-methods';
  import IuguService from '@/services/IuguService';
  import PagarmeService from '@/services/PagarmeService';
  import SubscriptionService from '@/services/SubscriptionService';
  import Utils from '@/assets/js/Utils';
  import UserSessionService from '@/services/user-session';
  import twoDigitsDate from '@/filters/twoDigitsDate';

  import * as Sentry from '@sentry/browser';

  export default {
    name: 'ManageBilling',
    components: {
      'payment-methods': PaymentMethods,
      'form-input': FormInput,
    },
    props: {
      registeredCreditCard: Object,
    },
    data() {
      return {
        form: {
          isValid: false,
          cardNumber: {
            label: 'Número do cartão',
            value: '',
            pattern: /[0-9\s]+/,
            minLength: 16,
            maxLength: 19,
            disabled: false,
            required: true,
            error: {
              message: 'Número do cartão inválido',
            }
          },
          cardExpirationDate: {
            label: 'Validade',
            value: '',
            pattern: /[0-9]{2}\/[0-9]{2}/,
            minLength: 5,
            maxLength: 5,
            disabled: false,
            required: true,
            error: {
              message: 'Data inválida',
            }
          },
          cardVerificationValue: {
            label: 'Código de segurança',
            value: '',
            pattern: /[0-9]+/,
            minLength: 3,
            maxLength: 4,
            disabled: false,
            required: true,
            error: {
              message: 'Código inválido',
            }
          },
          cardName: {
            label: 'Nome impresso no cartão',
            value: '',
            pattern: /^[a-zA-Z]+\s[a-zA-Z\s]+$/,
            minLength: 3,
            maxLength: 100,
            disabled: false,
            required: true,
            error: {
              message: 'Preencher o nome conforme impresso no cartão',
            }
          },
        },
        brand: {
          visa: {
            logo: 'url('+require('@/assets/images/svg/logo-visa.svg')+')',
            background: 'url('+require('@/assets/images/bg-visa.png')+')',
          },
          master: {
            logo: 'url('+require('@/assets/images/svg/logo-mastercard.svg')+')',
            background: 'url('+require('@/assets/images/bg-mastercard.png')+')',
          },
          elo: {
            logo: 'url('+require('@/assets/images/svg/logo-elo.svg')+')',
            background: 'url('+require('@/assets/images/bg-elo.png')+')',
          },
          other: {
            logo: 'url('+require('@/assets/images/svg/logo-other.svg')+')',
            background: 'url('+require('@/assets/images/bg-other.png')+')',
          }
        },
        plansHeight: { '--plans-height': '0px' },
        aboutInfo: {
          show: false,
          text:
            'Ao cadastrar um novo cartão, o cartão atual será excluido e suas próximas cobranças serão feitas no novo cartão. <p> Para fazer a ativação, cobraremos R$ 1,00 no seu cartão de crédito. Mas não se preocupe, você será reembolsado em seguida.',
          extraInfo: '*Para planos parcelados a troca de cartão é feita na renovação do plano e não é aplicada ao pagamento das parcelas restantes.',
        },
        userSession: null,
      };
    },
    async created() {
      this.userSession = UserSessionService.getData();
    },
    mounted() {
      this.calculatePlansHeight();
    },
    methods: {
      twoDigitsDate,
      calculatePlansHeight: function () {
        this.plansHeight['--plans-height'] = `${
          document.querySelector('.form .container').offsetHeight
        }px`;
      },
      brandStylization: function() {
        const card = this.registeredCreditCard.brand;

        const currentCard = {
          'visa' : { background: this.brand.visa.background, logo: this.brand.visa.logo },
          'master' : { background: this.brand.master.background, logo: this.brand.master.logo },
          'elo' : { background: this.brand.elo.background, logo: this.brand.elo.logo },
          'other' : { background: this.brand.other.background, logo: this.brand.other.logo },
        }
        return currentCard[card] || currentCard['other'];
      },
      validateForm: function () {
        const {
          cardNumber,
          cardExpirationDate,
          cardVerificationValue,
          cardName,
        } = this.form;

        const result = {
          isValid: false,
        };

        const cardNumberValidation =  (
          !cardNumber.value ||
          !cardNumber.pattern.test(cardNumber.value) ||
          !IuguService.validateCardNumber(cardNumber.value)
        );

        const cardExpirationDateValidation =  (
          !cardExpirationDate.value ||
          !cardExpirationDate.pattern.test(cardExpirationDate.value) ||
          !IuguService.validateCardExpirationDate(cardExpirationDate.value)
        );

        const cardVerificationValidation =  (
          !cardVerificationValue.value ||
          !cardVerificationValue.pattern.test(cardVerificationValue.value) ||
          !IuguService.validateCardVerificationValue(
            cardNumber.value,cardVerificationValue.value,
            !cardName.value ||
            !cardName.pattern.test(cardName.value)
          )
        );

        if ((
          !cardNumber.value ||
          !cardExpirationDate.value ||
          !cardVerificationValue.value ||
          !cardName.value
        ) === true) {
          this.trackSnowplow('trackPopupView', {
            name: 'change_card_error',
            type: 'modal',
            failure_reason: 'empty_fields',
          });
          return 'Preencha todos os dados do cartão para prosseguir com o cadastro do novo cartão';
        }

        if (
          cardNumberValidation ||
          cardExpirationDateValidation ||
          cardVerificationValidation
        ) {
          result.isValid = false;
        }

        result.isValid = true;

        return result;
      },
      submitNewCreditCard: async function (e) {
        this.initLoading();

        const userId = this.userSession.currentUser.id;
        const {
          cardNumber,
          cardExpirationDate,
          cardVerificationValue,
          cardName,
        } = this.form;
        const formValidation = this.validateForm();

        if (!formValidation.isValid) {
          this.finishLoading();
          this.createToast(formValidation);
          return;
        }
        let paymentTokenIugu;
        try {
          paymentTokenIugu = await IuguService.createPaymentToken(
            IuguService.createCreditCardObj(
            Utils.sanitizeDigitsString(cardNumber.value),
            cardExpirationDate.value,
            cardVerificationValue.value,
            cardName.value,
            )
          );
        } catch (e) {
          console.error('Não foi possível realizar a tokenização no ambiente Iugu');
        }

        let paymentTokenPagarme;
        try {
          paymentTokenPagarme = await PagarmeService.createPaymentToken({
            type: 'card',
            card: {
              number: cardNumber.value.replace(/\s/g, ''),
              holder_name: cardName.value,
              exp_month: cardExpirationDate.value.substr(0, 2),
              exp_year: cardExpirationDate.value.substr(3, 2),
              cvv: cardVerificationValue.value
            }
          });
        } catch (e) {
          console.error('Não foi possível realizar a tokenização no ambiente PagarMe');
        }

        if (!paymentTokenPagarme && !paymentTokenIugu) {
          alert('Não foi possível validar o cartão de crédito. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379');
          return;
        }

        const newPaymentMethod = {
          description: 'Meu cartão',
          token: paymentTokenIugu || '',
          tokenCartaoPagarme: paymentTokenPagarme?.data?.id || ''
        };

        SubscriptionService.changePaymentMethodByUser(
          userId,
          newPaymentMethod,
        )
          .then(resp => {
            this.createToast(resp.data.message, {}, 'success');
            this.$emit('updateRegisteredCard');

            this.form.cardNumber.value = "";
            this.form.cardExpirationDate.value = "";
            this.form.cardVerificationValue.value = "";
            this.form.cardName.value = "";

            this.trackSnowplow('trackPopupView', {
              name: 'change_card_success',
              type: 'modal',
            });
            this.trackSnowplow('trackButton', {
              button_name: 'payment_method',
              additional_properties: 'update_card_success',
            });
          })
          .catch(err => {
            if (err.response) {
              err = err.response.data;
            }

            let errorMessage = err.message || err.mensagem;

            if (errorMessage) {
              if (err.code === 'AUTH_CODE_EXPIRED'
                || err.code === 'AUTH_INVALID_AUTH_CODE'
                || err.code === 'AUTH_WRONG_TOKEN_TYPE'
              ) {
                UserSessionService.removeData();
                this.createToast(errorMessage, {
                  redirectTo: 'login',
                });
                this.trackSnowplow('trackPopupView', {
                  name: 'change_card_error',
                  type: 'modal',
                  failure_reason: 'token_error',
                });
              } else {
                this.createToast(errorMessage);
                this.trackSnowplow('trackPopupView', {
                  name: 'change_card_error',
                  type: 'modal',
                  failure_reason: 'generic_error',
                });
              }
            } else {
              errorMessage = 'Ocorreu um erro na troca do seu cartão. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379';
              this.createToast(errorMessage);
              this.trackSnowplow('trackPopupView', {
                  name: 'change_card_error',
                  type: 'modal',
                  failure_reason: 'generic_error',
                });
              Sentry.captureException(err);
            }
          })
          .finally(() => {
            this.finishLoading();
          });
      },
      trackerSnowplowAndBrazeByUserCardName(form) {
        this.trackSnowplow('sendSnowplowSubscribeEvent', {
          form_element: form.cardName,
          step_name: 'user_card_name',
          step_num: 4,
        });

        this.trackBraze('trackEvent', {
          name: 'subscribe_user_card_name',
          attribute: 'step_name',
          event_name: 'subscribe',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .manage-billing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin-left: 1em;
    padding-bottom: 4em;

    overflow-y: auto;

    .group-title {
      font-size: 20px;
      font-weight: 500;
      margin: 40px 0;
    }

    .registered-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 290px;
        min-height: 185px;
        padding: 24px 16px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;

        > * {
          padding: 5px 0;
        }

        .brand {
          position: absolute;
          top: 0.5em;
          right: 0.4em;
          width: 40px;
          height: 40px;
          background-size: 40px 30px;
          background-repeat: no-repeat;
        }

        .number {
          display: flex;
          justify-content: space-between;
          font-size: 36px;
          margin-top: 48px;

          :nth-child(-n+4) {
            font-size: 38px;
          }
        }

        .bottom-info {
          display: flex;
          align-items: flex-end;
          padding: 0px;
        }

        .expiration {
          width: 40px;
          font-size: 1em;
          margin-right: 24px;

          span {
            font-size: 0.7em;
          }
        }
      }

      .about {
        width: 290px;
        text-align: center;
        font-size: 14px;
        color: $text-black;
      }

      .about-extra-info {
        margin-top: 24px;
        font-size: 12px;
        color: $dark-gray;
      }
    }

    .form {
      border-top: 1px solid $gray;
      margin-right: 2em;

      .payment-info {
        display: flex;
        justify-content: space-around;
        flex-flow: column;
        margin-bottom: 0;

        .group-title {
          margin-bottom: 16px;
        }


        .payment-methods {
          width: 100%;
          margin: 0 0 10px 0;

          :first-child {
            display: flex;
            justify-content: space-between;
          }

          :last-child {
            margin-left: 0;
          }
        }

        .form-input {
          margin-top: 28px;
          padding-bottom: 24px;
          font-size: 14px;
        }

        input {
          &[type='text']:not(:placeholder-shown) {
            text-transform: uppercase;
          }
        }

        .card-confirmation-info {
          display: flex;
          justify-content: space-between;
        }
      }

      .container {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 3em;
        background-color: $white;

        .submit-form {
          width: 100%;
          display: flex;
          justify-content: center;
          z-index: 9;

          button {
            width: 310px;
            height: 50px;
            margin-bottom: 1.2em;
            background: $primary;
            border-radius: 25px;
            color: $white;
            font-size: 14px;

            &:hover {
              background-color: transparent;
              border: 2px solid $primary;
              box-sizing: border-box;
              color: $primary;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .manage-billing {
      display: flex;
      flex-direction: row;
      grid-gap: 3em;
      margin: 2em 0 5em 3em;

      .registered-card {
        .about {
          text-align: left;
        }
      }
      .group-title {
        margin-bottom: 32px;
      }

      .form {
        display: flex;
        height: 100%;
        width: 24em;
        padding-left: 3em;
        flex-direction: column;
        align-items: start;
        border-top: 0;
        border-left: 1px solid $gray;

        .payment-info {
          justify-content: start;

          .group-title {
            margin-bottom: 37px;
          }

          .form-input {
            margin-bottom: 0;
          }

          .payment-methods {
            margin-bottom: 1.2em;
            margin-left: 0;
          }
        }

        .container {
          position: initial;
          box-shadow: none;

          .submit-form {
            justify-content: left;
            margin-top: -5px;
          }

          button {
            width: 196px !important;
            height: 48px;
            background: $primary;
            border-radius: 25px;
            color: $white;
            font-size: 14px;
            margin: 1.2em 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: 960px) {
    .group-title {
      margin-top: 10px;
    }

    .manage-billing {
      display: flex;
      flex-direction: row;
      grid-gap: 4em;
      margin: 0 0 4em 2em;

      .registered-card {
        .about {
          text-align: left;
        }
      }

      .form {
        padding-left: 4em;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .manage-billing {
      margin-left: 4em;
      grid-gap: 6em;
    }

    .manage-billing .form .payment-info .registered-card {
      position: absolute;
      right: 10em;
      border: none;
    }
  }
</style>
