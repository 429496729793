class GTMService {
  trackSubscribe({ event, category, label }) {
    if (dataLayer) {
      dataLayer.push({
        event,
        category,
        label,
      });
    }
  }
}

export default new GTMService();
