<template>
  <div class="congrats page">
    <custom-header />
    <div class="main">
      <div class="about">
        <div class="message">
          <span class="check" />
          <h1>{{ languageString.CONGRATS_congratulations }}</h1>
          <h2 v-if="isStepPayment">
            {{ languageString.CONGRATS_success }}
          </h2>
          <h2 v-if="isBankSlip" class="large">
            {{ languageString.CONGRATS_success }}
            <br><br>
            {{ languageString.CONGRATS_subTitleBankSlip }}
          </h2>
        </div>
        <subscription-info />
        <div v-if="isBankSlip" class="payment-method">
          <pix-payment :showPix="this.showPix" v-on:manage-pix-slideout="managePixSlideout()"/>
          <bank-slip-payment :showBankSlip="this.showBankSlip" v-on:manage-bankslip-slideout="manageBankSlipSlideout()"/>
        </div>
        <div v-if="!isBankSlip" class="feedback-warning-payment" v-html="languageString.CONGRATS_warning"/>
      </div>
      <store-buttons />
      <div v-if="isBankSlip" class="popup-info-help">
        <div class="container-icon">
          <img src="../../assets/images/shape.png" alt="info-icon" />
        </div>
        <p class="text-popup">Caso tenha algum problema, entre em contato com o suporte.</p>
        <a
          class="button-help"
          href="https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0"
          target="_blank"
        >
          <p>Ajuda</p>
        </a>
      </div>
      <ul class="actions">
        <li>
          <custom-button link :action="redirectToPageManageSubscription">
            {{ languageString.CONGRATS_manageSubscription }}
          </custom-button>
        </li>
        <li>
          <custom-button class="btn-goToHome" primary :action="redirectHome">
            {{ languageString.CONGRATS_goToHome }}
          </custom-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import SubscriptionInfo from '@/components/SubscriptionInfo';
  import PixPayment from '@/components/PixPayment';
  import BankSlipPayment from '@/components/BankSlipPayment';
  import StoreButtons from '@/components/StoreButtons';
  import CustomButton from '@/components/CustomButton';
  import CustomHeader from '@/components/CustomHeader';
  import UserSessionService from '@/services/user-session';

  import Utils from '@/assets/js/Utils';

  import LanguageString from '@/config/language/pt-BR';
  import Urls from '@/config/Urls';

  export default {
    name: 'Congrats',
    components: {
      'subscription-info': SubscriptionInfo,
      'pix-payment': PixPayment,
      'bank-slip-payment': BankSlipPayment,
      'store-buttons': StoreButtons,
      'custom-button': CustomButton,
      'custom-header': CustomHeader,
    },
    data() {
      return {
        platform: null,
        stepName: undefined,
        languageString: LanguageString,
        urls: Urls,
        userSession: null,
        CONGRATS_warning: LanguageString.CONGRATS_warning,
        showModal: false,
        showPix: true,
        showBankSlip: false,
      };
    },
    computed: {
      isStepPayment() {
        return this.stepName === 'payment';
      },
      isBankSlip() {
        return this.stepName === 'paymentBankSlip';
      },
    },
    created() {
      this.userSession = UserSessionService.getData();
      this.stepName = this.$route.query.stepName;
      this.cleanSelection();
    },
    mounted() {
      if (this.isBankSlip) {
        this.trackSnowplow('trackSubscribe', {
          e: null,
          step_name: 'subscribe_success',
          step_num: null,
          step_value: 'bank_slip',
          failure_reason: null,
        });

        this.trackBraze('trackEvent', {
          name: 'subscribe_success',
          attribute: 'step_name',
          event_name: 'subscribe',
        });
      }
    },
    methods: {
      formatDate: Utils.formatDate,
      fecharModal() {
        this.showModal = false;
      },
      managePixSlideout() {
        this.showPix = !this.showPix;
      },
      manageBankSlipSlideout() {
        this.showBankSlip = !this.showBankSlip;
      },
      cleanSelection() {
        window.getSelection().removeAllRanges();
      },
      redirectToPageManageSubscription() {
        window.location.href = process.env.VUE_APP_URL_WB_STATUS_ASSINATURA;


      }
    },
  };
</script>

<style scoped lang="scss" src="./congrats.scss" />
