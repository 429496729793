import Utils from '@/assets/js/Utils';

export default {
  created() {
    document.body.scrollTop = 0;
  },
  methods: {
    createToast(message, options, color) {
      this.$root.$emit('showToast', {
        message,
        color
      });

      if (options && options.redirectTo) {
        this.navigateToRoute(options.redirectTo, false);
      }
    },

    // TODO: DEPRECADO - Deve ser usado o componente "StoreButtons"
    downloadAppStore() {
      this.trackSnowplow('trackButton', {
        button_name: 'store_download',
        additional_properties: 'ios',
      });
      window.open(
        'https://apps.apple.com/us/app/whitebook-prescri%C3%A7%C3%A3o-e-bul%C3%A1rio/id638521744?l=pt&ls=1'
      );
    },

    // TODO: DEPRECADO - Deve ser usado o componente "StoreButtons"
    downloadGPlay() {
      this.trackSnowplow('trackButton', {
        button_name: 'store_download',
        additional_properties: 'android',
      });
      window.open(
        'https://play.google.com/store/apps/details?id=com.medprescricao'
      );
    },

    chooseSubscriptionPlan(planId, periodTerm) {
      this.trackSnowplow('trackButton', {
        button_name: 'subscribe_button',
        additional_properties: periodTerm,
      });
      this.navigateToRoute({ name: 'checkout', query: { planid: planId } });
    },

    trackSignUp() {
      this.trackSnowplow('trackButton', { button_name: 'signup' });
    },

    redirectHome() {
      window.open(process.env.VUE_APP_URL_WB_HOME);
    },

    initLoading() {
      this.$emit('loading', true);
    },

    finishLoading() {
      this.$emit('loading', false);
    },
    // TODO: DEPRECADO - Deve ser usado o componente "FormInput"
    formatCardNumber(formElement, event) {
      let value = formElement.value;

      if (
        !/delete/i.test(event.inputType) ||
        (/delete/i.test(event.inputType) && value.length > 17)
      ) {
        value = Utils.sanitizeDigitsString(value);

        if (value) {
          value = Utils.maskCardNumber(value);
        }
      }
      formElement.value = value;
    },
    // TODO: DEPRECADO - Deve ser usado o componente "FormInput"
    formatCardExpirationDate(formElement, event) {
      let value = formElement.value;

      if (!/delete/i.test(event.inputType)) {
        value = Utils.sanitizeDigitsString(value);

        if (value) value = Utils.maskDate(value).slice(0, 5);
      }
      formElement.value = value;
    },
    // TODO: DEPRECADO - Deve ser usado o componente "FormInput"
    formatCardVerificationValue(formElement, event) {
      let value = formElement.value;

      if (!/delete/i.test(event.inputType)) {
        value = Utils.sanitizeDigitsString(value);
      }

      formElement.value = value;
    },
  },
};
