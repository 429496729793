<template>
  <div id="app">
    <loading :class="{ hidden: !showLoading }" />
    <toast v-if="isToastVisible" :key="toastKey" :color="toastColor">
      {{ toastMessage }}
    </toast>
    <router-view @loading="handleLoading"/>
    <whatsapp />
  </div>
</template>

<script>
  import Loading from '@/components/Loading';
  import Toast from '@/components/Toast';
  import Whatsapp from '@/components/Whatsapp';
  import UserSessionService from '@/services/user-session';
  import SubscriptionService from '@/services/SubscriptionService';

  export default {
    components: {
      toast: Toast,
      loading: Loading,
      whatsapp: Whatsapp,
    },
    data() {
      return {
        showLoading: false,
        isToastVisible: false,
        toastKey: 0,
        toastMessage: '',
        toastColor: '',
      };
    },
    computed: {
      vh() {
        return (
          (window.orientation === 90 ? window.innerWidth : window.innerHeight) *
          0.01
        );
      },
    },
    mounted() {
      this.$root.$on('showToast', toastEvent => {
        this.isToastVisible = toastEvent.showToast || true;
        this.toastColor = toastEvent.color;
        this.toastMessage = toastEvent.message;
        this.toastKey += 1;
      })
      this.$root.$on('destroyToast', () => {
        this.isToastVisible = false
      })
    },
    async created() {
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
      if (UserSessionService.isAuthenticated()) {
        UserSessionService.runAuthenticationByCookie();
        await SubscriptionService.changeUserStateToSubscriber();
      } else {
        UserSessionService.removeData();
      }
    },
    methods: {
      handleLoading: function (isLoading) {
        if (isLoading) {
          this.isToastVisible = false;
        }
        this.showLoading = isLoading;
      },
    },
  };
</script>

<style lang="scss">
  /* Remove input autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .title-form {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #151516;
    text-align: left;
    margin: 0;
  }
  .subtitle-form {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #151516;
    text-align: left;
    margin: 10px 0 0 0;
  }

  @font-face {
    font-family: 'Google Sans', sans-serif;
    src: url('../public/fonts/GoogleSans-Bold.woff2') format('woff2'),
      url('../public/fonts/GoogleSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Google Sans', sans-serif;
    src: url('../public/fonts/GoogleSans-BoldItalic.woff2') format('woff2'),
      url('../public/fonts/GoogleSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Google Sans', sans-serif;
    src: url('../public/fonts/GoogleSans-Italic.woff2') format('woff2'),
      url('../public/fonts/GoogleSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Google Sans', sans-serif;
    src: url('../public/fonts/GoogleSans-Medium.woff2') format('woff2'),
      url('../public/fonts/GoogleSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Google Sans', sans-serif;
    src: url('../public/fonts/GoogleSans-MediumItalic.woff2') format('woff2'),
      url('../public/fonts/GoogleSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Google Sans', sans-serif;
    src: url('../public/fonts/GoogleSans-Regular.woff2') format('woff2'),
      url('../public/fonts/GoogleSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  body {
    margin: 0 auto;
    padding: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    font-size: 16px;
    font-family: 'Google Sans', sans-serif;
    font-weight: normal;
    color: #191847;
  }

  #app,
  #app .page {
    width: 100%;
    height: 100%;
    position: relative;
  }

  #app .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  input,
  select,
  button {
    border: unset;
    outline: none;
    background-color: transparent;
  }

  // TODO DEPRECATED: substituido pelo componente formInput
  input,
  select {
    border-width: 0 0 1px 0;
    border-color: #e2e2e2;
    border-style: solid;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    padding-bottom: 9px;
    font-size: 1em;
    position: relative;
    color: #191847;
  }

  // TODO DEPRECATED: substituido pelo componente formInput
  input:not([type='button']):not([type='checkbox']):not([type='radio']),
  select {
    &::-webkit-input-placeholder {
      /* Edge */
      color: #191847;
      text-transform: none;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #191847;
      text-transform: none;
    }
    &::placeholder {
      color: #191847;
      text-transform: none;
    }

    &:not(:placeholder-shown) + label {
      display: initial;
    }

    &:focus {
      &::-webkit-input-placeholder {
        /* Edge */
        color: transparent;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: transparent;
      }
      &::placeholder {
        color: transparent;
      }

      + label {
        display: initial;
      }
    }
  }

  // TODO DEPRECATED: substituido pelo componente formInput
  input:not([type='button']):not([type='checkbox']):not([type='radio']) + label,
  select + label {
    display: none;
    position: absolute;
    font-size: 14px;
    color: #e2e2e2;
    left: 0;
    top: -20px;
  }

  // TODO DEPRECATED: substituido pelo componente formInput
  .form-input {
    position: relative;
    font-family: 'Google Sans', sans-serif;

    &:not(:first-of-type) {
      margin-top: 33px;
    }
  }

  button {
    cursor: pointer;
  }

  a {
    cursor: pointer;
    color: inherit;
    font-weight: bold;
  }

  .button-link {
    color: initial;
    font-size: 12px;
    text-decoration: none;
  }

  .button-link:hover {
    text-decoration: underline;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  *:disabled {
    cursor: default;
  }

  *.hidden {
    display: none !important;
  }

  * {
    box-sizing: border-box;
  }
</style>
