<template>
  <div class="black-friday--countdown" :class="{ hidden: hidden }">
    <div class="headline">
      <div class="title">Black Friday,</div>
      <div class="support">
        O maior desconto da história.<br />
        Assine com até 45% OFF!
      </div>
      <div class="divider"></div>
    </div>

    <div class="countdown">
      <div class="days">
        <div class="day">
          {{ this.twoDigits(days) }}
        </div>
        <div>Dias</div>
      </div>

      <div class="hours">
        <div class="timer">{{ this.twoDigits(hours) }}</div>
        <div>Horas</div>
      </div>
      <div class="minutes">
        <div class="timer">{{ this.twoDigits(minutes) }}</div>
        <div>Minutos</div>
      </div>
      <div class="seconds">
        <div class="timer">{{ this.twoDigits(seconds) }}</div>
        <div>Segundos</div>
      </div>
    </div>

    <div class="terms">
      Para mais informações acesse os
      <a
        rel="noopener noreferrer"
        href="https://help.pebmed.com.br/hc/pt-br/articles/360055986134"
        target="_blank"
        >Termos de Uso</a
      >
      da promoção.
    </div>
  </div>
</template>

<script>
  import twoDigits from '../../filters/twoDigits';

  export default {
    mounted() {
      window.setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000);
      }, 1000);
    },
    data() {
      const blackFriday = {
        start: new Date(process.env.VUE_APP_BLACK_FRIDAY_START),
        end: new Date(process.env.VUE_APP_BLACK_FRIDAY_END),
        extend: new Date(process.env.VUE_APP_BLACK_FRIDAY_EXTEND),
      };

      let hidden = true;
      let date = blackFriday.end;

      let today = new Date();
      let now = Math.trunc(today.getTime() / 1000);

      const isBeforeBlackFriday = today <= blackFriday.start;
      const isAfterBlackFriday = today >= blackFriday.extend;

      if (isAfterBlackFriday || isBeforeBlackFriday) {
        return { hidden };
      }

      hidden = false;

      if (today > blackFriday.end) {
        date = blackFriday.extend;
      }

      return {
        hidden: hidden,
        now: now,
        date: date,
      };
    },
    computed: {
      dateInMilliseconds() {
        return Math.trunc(Date.parse(this.date) / 1000);
      },
      seconds() {
        return (this.dateInMilliseconds - this.now) % 60;
      },
      minutes() {
        return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
      },
      hours() {
        return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
      },
      days() {
        return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
      },
    },
    methods: {
      twoDigits,
    },
  };
</script>

<style lang="scss" scoped src="./BlackFridayCountdown.scss"></style>
