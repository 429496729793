<template>
</template>
<script>
  import UserSessionService from '@/services/user-session';
  import AuthService from '@/services/AuthService';
  import SubscriptionService from '@/services/SubscriptionService';
  import { subscriptionAdapter } from '@/filters/subscriptionAdapter';
  import * as Sentry from '@sentry/browser';

  export default {
    created() {
      UserSessionService.removeData();
      if (process.env.VUE_APP_URL_WB_LOGIN.includes('localhost') && process.env.VUE_APP_USUARIO_TESTE_EMAIL) {
        this.loginAmbienteDesenvolvimento();
      } else {
        this.redirectLoginWb();
      }
    },
    methods: {
      goToRestrictedNavigationStack(userIsSubscriber) {
        try {
          const { redirectFullPath } = this.$route.query;
          if (redirectFullPath) {
            this.$router.push({ path: redirectFullPath });
          } else if (userIsSubscriber) {
            this.$router.push({ name: 'subscription-info' });
          } else {
            this.$router.push({ name: 'checkout' });
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      },
      loginAmbienteDesenvolvimento: async function () {
        const TIPO_USUARIO_PREMIUM = 1;
        const TIPO_USUARIO_FREE = 2;
        const responseLogin = await AuthService.login(
          process.env.VUE_APP_USUARIO_TESTE_EMAIL,
          process.env.VUE_APP_USUARIO_TESTE_SENHA,
        );
        let loggedData = responseLogin.data;
        AuthService.setAuthorizationHeader(loggedData.codigo);
        AuthService.setUserIdHeader(loggedData.usuario.id);

        try {
          const responseSubscription = await SubscriptionService.getSubscriptionStatus(
            loggedData.usuario.id,
          );

          loggedData.subscription = subscriptionAdapter(responseSubscription.data);
          loggedData.usuario.id_tipo_usuario = loggedData.subscription.premium
            .active
            ? TIPO_USUARIO_PREMIUM
            : TIPO_USUARIO_FREE;
          loggedData.usuario.subscription_id = loggedData.subscription.premium
            .active
            ? loggedData.subscription.premium.id
            : null;

          UserSessionService.setData(loggedData);
          this.trackSnowplow('trackUser', { user: loggedData.usuario });
          this.trackBraze('trackUser', { userId: loggedData.usuario.id });

          const userIsSubscriber = loggedData.subscription.premium.active;
          this.goToRestrictedNavigationStack(userIsSubscriber);

        } catch (error) {
          console.log('error', error);
          if(error.response.status === 404) {
            loggedData.usuario.id_tipo_usuario = TIPO_USUARIO_FREE;
            loggedData.subscription = error;

            UserSessionService.setData(loggedData);
            this.goToRestrictedNavigationStack(false);
          } else {
            this.createToast(
              'Ocorreu um erro ao buscar a sua assinatura. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379'
            );
            Sentry.captureException(error);
          }
        }
      },
      redirectLoginWb: async function () {
        const params = location.search;

        if (params.includes('boleto')) {
          document.cookie = "origin_login=" + "AW-boleto" + ";domain=.pebmed.com.br;path=/login";
        } else {
          document.cookie = "origin_login=" + "AW" + ";domain=.pebmed.com.br;path=/login";
        }

        window.location.href = process.env.VUE_APP_URL_WB_LOGIN;
      }
    },
  };
</script>
