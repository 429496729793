<template>
  <div class="slideout-cancelamento-info" >
    <div class="slideout-cancelamento-info-background">
      <div class="slideout-cancelamento-info-container">
        <div class="slideout-cancelamento-info-header">
          <div class="slideout-cancelamento-info-return">
            <span @click="retornar()">
              <img
                v-if="!slideoutCancelamentoConcluido"
                src="../../assets/images/svg/arrow.svg"
                width="18"
                height="18"
                alt="Ícone para retornar à tela anterior"
                title="Ícone para retornar à tela anterior"
              />
            </span>
          </div>
          <span class="center" v-if="!slideoutCancelamentoConcluido">Cancelamento</span>
          <div class="slideout-cancelamento-info-close">
            <span @click="fechar()">
              <img
                v-if="slideoutCancelamentoConcluido"
                src="../../assets/images/svg/close-icon-blue.svg"
                width="18"
                height="18"
                alt="Icone de fechar mais Informações"
              />
            </span>
          </div>
        </div>
        <div class="slideout-cancelamento-info-body" v-if="slideoutTemCerteza">
          <div class="center">
            <img src="../../assets/images/svg/cancelamento-tem-certeza.png" alt="Ícone de coração" />
          </div>
          <h3>Tem certeza que deseja cancelar a assinatura?</h3>
          <p>Olha só o que você perderá ao cancelar a assinatura:</p>
          <div class="slideout-cancelamento-body-item">
            <img src="../../assets/images/svg/cancelamento-livro.svg" alt="ícone de informações" />
            <p class="slideout-cancelamento-body-item-texto">+ de 9 mil conteúdos na palma da sua mão</p>
          </div>
          <div class="slideout-cancelamento-body-item">
            <img src="../../assets/images/svg/cancelamento-wifi-off.svg" alt="ícone de wifi off" />
            <p class="slideout-cancelamento-body-item-texto">Calculadoras e escores não estarão mais disponíveis offline</p>
          </div>
          <div class="slideout-cancelamento-body-item">
            <img src="../../assets/images/svg/cancelamento-pig-bank.svg" alt="ícone de cofre de dinheiro" />
            <p class="slideout-cancelamento-body-item-texto">Não poderá participar de novas promoções, por até 6 meses</p>
          </div>
          <div class="slideout-cancelamento-body-item">
            <img src="../../assets/images/svg/cancelamento-money.svg" alt="ícone de dinheiro" />
            <p class="slideout-cancelamento-body-item-texto">
              Caso deseje retornar para o premium, possivelmente o valor da assinatura terá sido reajustado
            </p>
          </div>
          <div class="escolha-nao" @click="hideTemCerteza()">Não, quero continuar premium</div>
          <div class="escolha-sim" @click="hideTemCertezaAndslideoutMotivo()">Sim, quero cancelar minha assinatura</div>
        </div>
        <div class="slideout-cancelamento-info-body" v-if="slideoutMotivo">
          <div class="center">
            <img src="../../assets/images/svg/cancelamento-medicamento.png" alt="Ícone de medicamento" />
          </div>
          <h3>Poxa, sentiremos a sua falta :(</h3>
          <p>Selecione abaixo o motivo do cancelamento, para que possamos melhorar cada vez mais o Whitebook:</p>
          <div
            class="slideout-cancelamento-body-item-motivo-cancelamento"
            v-for="(item, index) in motivosCancelamento"
            :key="index"
            @click="trackMotivosCancelamento(item, index)"
          >
            <input type="radio" name="plan" />
            <label class="slideout-cancelamento-body-item-texto">{{ item }}</label>
          </div>
          <div class="escolha-nao" @click="hideTemCerteza()">Quero manter minha assinatura</div>
          <div class="escolha-sim" @click="hideQuePenaAndShowObrigado()">Quero cancelar minha assinatura</div>
        </div>
        <div class="slideout-cancelamento-info-body-cancelamento-concluido" v-if="slideoutCancelamentoConcluido">
          <div class="center center-v">
            <img src="../../assets/images/svg/cancelamento-success.svg" alt="Ícone de sucesso" />
            <h3 class="padding-top">Assinatura cancelada</h3>
            <p>Sua assinatura foi cancelada com sucesso!</p>
          </div>
          <div class="bottom-v">
            <div class="ir-whitebook">
              <div class="escolha-nao" @click="cancellationSuccessfulAndGoToHome">Ir para Whitebook</div>
            </div>
            <div class="slideout-cancelamento-body-item-motivo-cancelamento">
              <img src="../../assets/images/svg/cancelamento-info.svg" alt="Ícone de informações" />
              <p class="fonte-pequena">
                Você continuará com acesso premium até
                <span> {{ formatDate(data_fim) }} </span>, depois seu acesso ao Whitebook será limitado.
              </p>
            </div>
          </div>
        </div>
        <div class="slideout-cancelamento-info-body-cancelamento-concluido" v-if="slideoutCancelamentoFalha">
          <div class="center center-v">
            <img class="padding-top-erro" src="../../assets/images/svg/cancelamento-error.svg" alt="Ícone de erro" />
            <h3 class="padding-top">Desculpe!</h3>
            <p>Houve um erro inesperado na Suspensão/Cancelamento da Assinatura. Entre em contato pelo whatsapp +55 (21) 97376-4379.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionService from '@/services/SubscriptionService';
import UserSessionService from '@/services/user-session';
import Utils from '@/assets/js/Utils';
import constants from '../../constants/index'


export default {
  data() {
    return {
      slideoutCancelamentoConcluido: false,
      slideoutTemCerteza: true,
      slideoutMotivo: false,
      data_fim: null,
      motivosCancelamento: [
        'Ganhei acesso gratuito / desconto pela faculdade',
        'Falta conteúdo para minha área de atuação',
        'Aplicativo não funciona bem',
        'Assino outro aplicativo de tomada de decisão clínica',
        'Outros',
      ],
      userSession: null,
      slideoutCancelamentoFalha: false,
    };
  },
  mounted() {
    this.trackSnowplow('trackButton', {
      button_name: 'manage_subscription',
      additional_properties: 'cancel_membership',
    });

    this.trackSnowplow('trackPopupView', {
      name: 'membership_retention',
      type: 'modal',
    });
  },
  methods: {
    formatDate: Utils.formatDate,
    fechar(){
      this.$emit('fechar-modal-e-bloquear-cancelamento');
    },
    retornar(){
      this.$emit('fechar-modal');
    },
    trackMotivosCancelamento:  function (item, index) {
      const itemIndex = index + 1;
      this.trackSnowplow('trackListAction', {
        action_name: 'item_click',
        context: 'cancellation_reason',
        item_id: itemIndex,
        item_value: item,
        item_position: itemIndex,
      });
    },
    cancellationSuccessfulAndGoToHome() {
      this.trackSnowplow('trackButton', {
        button_name: 'return_home',
        button_location: 'cancellation_successful',
        additional_properties: 'cancellation_successful',
      });
      return this.redirectHome();
    },
    hideTemCerteza() {
      this.retornar();
      this.trackSnowplow('trackButton', {
        button_name: 'manage_subscription',
        additional_properties: 'cancel_cancellation',
      });
    },
    hideTemCertezaAndslideoutMotivo() {
      this.slideoutTemCerteza = false;
      this.slideoutMotivo = true;
      this.trackSnowplow('trackButton', {
        button_name: 'manage_subscription',
        additional_properties: 'proceed_with_cancellation',
      });
      this.trackSnowplow('trackPopupView', {
        name: 'membership_retention',
        type: 'modal',
      });
    },
    hideQuePenaAndShowObrigado() {
      this.cancelSubscription();
    },
    cancelSubscription() {
      this.initLoading();
      this.userSession = UserSessionService.getData();

      const userId = this.userSession.currentUser.id;

      this.trackSnowplow('trackButton', {
        button_name: 'manage_subscription',
        additional_properties: 'cancellation_confirmation',
      });

      SubscriptionService.getSuspendSubscription(userId)
        .then(resp => {
          this.finishLoading();
          if (resp) {
            this.data_fim = resp.data.data_fim;
          }
          this.slideoutMotivo = false;
          this.slideoutCancelamentoConcluido = true;

          this.trackSnowplow('trackPopupView', {
            name: 'cancellation_successful',
            type: 'modal',
          });
        })
        .catch(err => {
          if (err.response) {
            err = err.response.data;
          }
          this.slideoutMotivo = false;
          this.slideoutCancelamentoFalha = true;

          throw err;
        })
        .finally(() => {
          this.finishLoading();
        });
    },
  },
};
</script>

<style lang="scss" scoped src="./SlideoutCancelamento.scss"></style>
