<template>
  <div>
    <custom-header :status="failure" />
    <general-failure v-if="isGeneralFailure" :action-hide-message="hideMessageGeneralFailure" />
    <credit-card-failure
      v-if="isCreditCardFailure"
      :action-pay-with-bank-slip="goToBankSlipCheckout"
      :action-back-to-credit-card-payment="displayCreditCardPaymentCheckout"
      :credit-card-failure-message="creditCardFailureMessage"
    />
    <div v-if="!failure" class="checkout page" :style="plansHeight">
      <black-friday-countdown class="checkout-page black-friday-countdown--desktop" />
      <form name="checkout-form" class="checkout-form" novalidate="true" @submit.prevent>
        <div class="checkout-form-body">
          <div class="container">
            <div class="payment-info">
              <h2 class="title-form">Falta pouco!</h2>
              <h3 class="subtitle-form">Insira seus dados de pagamento abaixo:</h3>
              <payment-methods />
              <div class="info-container">
                <form-input
                  :name="'cardNumber'"
                  :value="form.cardNumber.value"
                  @input="form.cardNumber.value = $event"
                  @blur="
                    trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: form.cardNumber,
                      step_name: 'user_card_number',
                      step_num: 1,
                    })
                  "
                />
                <div class="row">
                  <form-input
                    :name="'cardExpirationDate'"
                    :value="form.cardExpirationDate.value"
                    @input="form.cardExpirationDate.value = $event"
                    @blur="
                      trackSnowplow('sendSnowplowSubscribeEvent', {
                        form_element: form.cardExpirationDate,
                        step_name: 'user_card_expiration',
                        step_num: 2,
                      })
                    "
                  />
                  <form-input
                    :name="'cardVerificationValue'"
                    :value="form.cardVerificationValue.value"
                    @input="form.cardVerificationValue.value = $event"
                    @blur="
                      trackSnowplow('sendSnowplowSubscribeEvent', {
                        form_element: form.cardVerificationValue,
                        step_name: 'user_card_cvv',
                        step_num: 3,
                      })
                    "
                  />
                </div>
                <form-input
                  :name="'cardName'"
                  :value="form.cardName.value"
                  @input="form.cardName.value = $event"
                  @blur="trackerSnowplowAndBrazeByUserCardName(form)"
                />
                <form-input
                  :name="'cpf'"
                  :value="form.cpf.value"
                  :disabled="form.cpf.disabled"
                  @input="form.cpf.value = $event"
                  @blur="
                    trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: form.cpf,
                      step_name: 'user_cpf',
                      step_num: 5,
                    })
                  "
                />
                <div v-if="planOffer && planOffer.acceptCoupon" class="discount-coupon">
                  <form-input
                    v-if="planOffer && planOffer.acceptCoupon"
                    :name="'discountCoupon'"
                    :value="form.discountCoupon.value"
                    :disabled="form.discountCoupon.disabled"
                    :readonly="form.discountCoupon.readonly"
                    @input="form.discountCoupon.value = $event"
                  />
                  <button
                    v-if="!discountApplied && !form.discountCoupon.readonly"
                    type="button"
                    @click="applyDiscountCoupon(form.discountCoupon.value)"
                  >
                    Aplicar
                  </button>
                  <button
                    v-if="discountApplied && !form.discountCoupon.readonly"
                    type="button"
                    @click="removeDiscountApplied(form.discountCoupon)"
                  >
                    X
                  </button>
                </div>
                <div class="discount-coupon-info">Desconto não acumulativo. Ao aplicar valerá como o principal.</div>
                <div v-if="splittingEnabled" class="form-input installments">
                  <select
                    id="installments"
                    v-model="form.installments.value"
                    name="installments"
                    @blur="
                      trackSnowplow('sendSnowplowSubscribeEvent', {
                        form_element: form.installments,
                        step_name: 'user_installments',
                        step_num: 7,
                        step_value: `${form.installments.value} installments`,
                      })
                    "
                  >
                    <option v-for="item in form.installments.options" :key="item.value" :value="item.value">
                      {{ item.value }}x de R$ {{ numberToCurrency(item.price) }} sem juros
                    </option>
                  </select>
                  <label for="installments">Número de parcelas</label>
                </div>
              </div>
            </div>
          </div>
          <div id="planInfo" class="plan-info expanded">
            <div class="section-title">
              <p class="primary">Confira o seu plano:</p>
              <div class="secondary" @click="togglePlans">
                <p v-if="userInfo" class="user-email"
                  id="card-userEmail">
                  {{ userInfo.email }}
                </p>
              </div>
            </div>
            <div v-if="planOffer" class="plans-container open">
              <div
                v-for="plan in availablePlans"
                :key="plan.id"
                class="plan"
                :class="{ discount: discountApplied }"
                @click.prevent="selectPlan(plan.id)"
              >
                <input :id="plan.id" v-model="form.plan.value" type="radio" name="plan" :value="plan.id" />
                <label
                  :for="plan.id"
                  class="plan-card"
                  :class="plan.class"
                  :data-discount-percentage="`-${
                    discountApplied ? plan.price.discountPercentage : plan.price.originalDiscountPercentage
                  }%`"
                >
                  <p class="plan-title">
                    <span class="period">{{ plan.title.period }}</span>
                    <span class="type">{{ plan.title.type }}</span>
                  </p>
                  <p class="price" :class="{ discount: discountApplied }">
                    <span class="full" v-if="plan.price.original !== plan.price.full">De R$ {{ numberToCurrency(plan.price.original) }}</span>
                    <span class="withDiscount"
                      >Por R$ {{ numberToCurrency(discountApplied ? plan.price.discount : plan.price.full) }}</span
                    >
                    <span v-if="plan.isSplittable && splittingEnabled"> em {{ form.installments.value }}x</span>
                  </p>
                </label>
              </div>
            </div>
            <div class="about">
              <p class="title" :class="{ open: aboutInfo.show }" @click="aboutInfo.show = !aboutInfo.show">
                Sobre a cobrança
              </p>
              <p class="body" :class="{ open: aboutInfo.show }" v-html="aboutInfo.text" />
            </div>

            <div class="submit-form mobile">
              <custom-button :primary="true" :action="submitSubscription" :disabled="isSubmitButtonDisabled">
                Finalizar pagamento
              </custom-button>
            </div>
          </div>
        </div>
        <div class="checkout-form-button">
          <custom-button :primary="true" :action="submitSubscription" :disabled="isSubmitButtonDisabled"
          id="checkout-card-submitButton">
            Finalizar pagamento
          </custom-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PaymentMethods from '@/components/payment-methods';
import CustomButton from '@/components/CustomButton';
import FormInput from '@/components/FormInput';
import IuguService from '@/services/IuguService';
import PagarmeService from '@/services/PagarmeService';
import AfyaLayerService from '@/services/AfyaLayerService';
import SubscriptionService from '@/services/SubscriptionService';
import OfferService from '@/services/OfferService';
import Utils from '@/assets/js/Utils';
import CreditCardFailure from '@/views/credit-card-failure';
import GeneralFailure from '@/views/general-failure';
import CustomHeader from '@/components/CustomHeader';
import BlackFridayCountdown from '@/components/BlackFridayCountdown';
import UserSessionService from '@/services/user-session';

import LanguageString from '@/config/language/pt-BR';

import * as Sentry from '@sentry/browser';

export default {
  name: 'Checkout',
  components: {
    'payment-methods': PaymentMethods,
    'custom-button': CustomButton,
    'form-input': FormInput,
    'credit-card-failure': CreditCardFailure,
    'custom-header': CustomHeader,
    'general-failure': GeneralFailure,
    'black-friday-countdown': BlackFridayCountdown,
  },
  data() {
    return {
      isSubmitButtonDisabled: false,
      splittingEnabled: false,
      windowWidth: 0,
      languageString: LanguageString,
      isCreditCard: true,
      isCreditCardFailure: false,
      isCardSubscriptionError: false,
      isGeneralFailure: false,
      creditCardFailureMessage: null,
      plansHeight: { '--plans-height': '0px' },
      aboutInfo: {
        show: false,
        text: 'Será feito uma pré-autorização no seu cartão e você verá duas cobranças com o valor do plano escolhido por você. Mas não se preocupe! Uma delas será cancelada e você não será cobrado duas vezes. ',
      },
      discountApplied: false,
      discountPercentage: 0,
      discountCouponValue: null,
      basePrice: process.env.VUE_APP_BASE_PRICE,
      gatewayHabilitado: process.env.VUE_APP_GATEWAY_HABILITADO,
      planOffer: null,
      planOffers: {
        show: false,
      },
      userSession: null,
      failure: false,
      form: {
        cardNumber: {
          value: '',
          disabled: false,
        },
        cardExpirationDate: {
          value: '',
          disabled: false,
        },
        cardVerificationValue: {
          value: '',
          disabled: false,
        },
        cardName: {
          value: '',
          disabled: false,
        },
        cpf: {
          value: '',
          disabled: false,
        },
        discountCoupon: {
          value: '',
          disabled: false,
        },
        installments: {
          value: 12,
          options: [],
        },
        plan: {
          value: process.env.VUE_APP_DEFAULT_PLAN_ID,
        },
      },
    };
  },
  mounted() {
    this.clarity();
  },
  computed: {
    partPlan() {
      return this.planOffer.options.find(item => item.isSplittable);
    },
    userInfo() {
      return this.userSession ? this.userSession.currentUser : null;
    },
    availablePlans() {
      return this.planOffer.options.filter(plan => this.isCreditCard || plan.id !== this.partPlan.id);
    },
  },
  async created() {
    if (UserSessionService.isSubscriber()) {
      this.$router.push({
        name: 'subscription-info',
        query: this.$route.query,
      });
      return;
    }

    try {
      this.userSession = UserSessionService.getData() || UserSessionService.getAuthenticationCookieData();
      const gatewayHabilitado = this.gatewayHabilitado || 'afya_layer';
      const offersData = (await SubscriptionService.listOffersByGateway(gatewayHabilitado)).data;
      this.planOffers = {
        show: false,
        offers: {
          default: {
            isBlackFriday: offersData.backgroundImage === 'black_friday',
            acceptCoupon: offersData.plans.list[0].acceptsCoupon,
            discountCouponCode: offersData.plans.list[0].discountCouponCode,
            options: [],
          },
        },
      };

      const planClasses = ['first-card', 'second-card'];

      for (let key in offersData.plans.list) {
        const currentItem = offersData.plans.list[key];
        const fullPrice = this.currencyToNumber(offersData.plans.basePrice[currentItem.period]);
        const priceWithBaseDiscount = fullPrice - (fullPrice / 100) * currentItem.baseDiscountPercentage;
        const formaPagamento = currentItem.subtitle.text1 || 'À vista';
        const cicloPagamento = currentItem.period === 'monthly' ? 'Mensal' : 'Anual';
        const nome = `${cicloPagamento} ${formaPagamento.toLowerCase()}`;
        const item = {
          id: currentItem.storeId,
          name: nome,
          class: planClasses[key],
          title: {
            period: currentItem.title,
            type: formaPagamento,
          },
          isSplittable: currentItem.splittable,
          price: {
            original: this.currencyToNumber(offersData.plans.basePrice[currentItem.period]),
            full: priceWithBaseDiscount,
            discountPercentage: currentItem.discountPercentage,
            originalDiscountPercentage: Math.round(currentItem.baseDiscountPercentage),
          },
        };

        this.planOffers.offers.default.options.push(item);
      }

      this.form.plan.value = this.$route.query.planid || offersData.plans.list[0].storeId;
      this.planOffer = this.getPlanOfferByPlan(this.form.plan.value);

      this.setCPF();
      this.applyDiscountCoupon(this.$route.query.couponCode || this.planOffers.offers.default.discountCouponCode);

      this.generateInstallmentOptions(this.partPlan.price.full);

      this.$nextTick(() => {
        if (window && window.hasOwnProperty('addEventListener')) {
          window.addEventListener('resize', this.getWindowWidth);
        }

        this.getWindowWidth();
      });

      this.setSplittingEnabled(this.form.installments);

      this.trackBrazeWhenLoadPage();
    } catch (error) {
      if (error.response) {
        error = error.response.data;
      }

      let errorMessage = error.message || error.mensagem;
      Sentry.captureException(error);

      if (errorMessage) {
        if (
          error.code === 'AUTH_CODE_EXPIRED' ||
          error.code === 'AUTH_INVALID_AUTH_CODE' ||
          error.code === 'AUTH_WRONG_TOKEN_TYPE'
        ) {
          UserSessionService.removeData();
          this.createToast(errorMessage, {
            redirectTo: 'login'
          });
        } else if (errorMessage.includes('getAuthenticationCookieData')) {
          UserSessionService.removeData();
          errorMessage = 'Desculpe, não encontramos a sua sessão. Efetue o login novamente.';
          this.createToast(errorMessage,  {
            redirectTo: 'login',
          });
        } else {
          this.createToast(errorMessage);
        }
      } else {
        errorMessage = 'Ocorreu um erro ao buscar as ofertas. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379';
        this.createToast(errorMessage);
      }
    } finally {
      this.finishLoading();
    }
  },
  methods: {
    numberToCurrency: Utils.numberToCurrency,
    currencyToNumber: Utils.currencyToNumber,
    validateCreditCardForm() {
      const { cardNumber, cardExpirationDate, cardVerificationValue, cardName, cpf } = this.form;
      const inputRadioPlans = document.getElementsByName('plan');
      const inputRadioPlansSelected = Array.from(inputRadioPlans).filter(i => i.checked && i);
      const result = {
        isValid: false,
      };

      if (!cardNumber.value || !cardNumber.isValid || !IuguService.validateCardNumber(cardNumber.value)) {
        result.error = {
          field: 'cardNumber',
          message: 'Número de cartão inválido',
        };

        return result;
      }

      if (
        !cardExpirationDate.value ||
        !cardExpirationDate.isValid ||
        !IuguService.validateCardExpirationDate(cardExpirationDate.value)
      ) {
        result.error = {
          field: 'cardExpirationDate',
          message: 'Data de expiração do cartão inválida',
        };

        return result;
      }

      if (
        !cardVerificationValue.value ||
        !cardVerificationValue.isValid ||
        !IuguService.validateCardVerificationValue(cardNumber.value, cardVerificationValue.value)
      ) {
        result.error = {
          field: 'cardVerificationValue',
          message: 'Código de verificação do cartão inválido',
        };

        return result;
      }

      if (!cardName.value || !cardName.isValid) {
        result.error = {
          field: 'cardName',
          message: 'Nome do titular inválido',
        };

        return result;
      }

      if (!cpf.value || !cpf.isValid || !Utils.validateCPF(cpf.value)) {
        result.error = {
          field: 'cpf',
          message: 'CPF inválido',
        };

        return result;
      }

      if (inputRadioPlansSelected.length === 0) {
        result.error = {
          field: 'Planos',
          message: 'Plano não selecionado',
        };

        return result;
      }

      result.isValid = true;

      return result;
    },
    getPlanOfferByPlan(planId) {
      let planOffer = null;

      for (let key in this.planOffers.offers) {
        let offer = this.planOffers.offers[key];

        if (offer.options.find(planOption => planOption.id === planId)) planOffer = offer;
      }

      return planOffer || this.planOffers.offers['default'];
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < 600) this.scrollToPlan('auto');
    },
    scrollToPlan(behavior) {
      const plansElement = document.querySelector(`#${this.form.plan.value}`);

      if (plansElement) {
        const planCardClasses = plansElement.labels[0].classList.value;
        let amount = this.windowWidth;

        if (/first/.test(planCardClasses)) amount *= -1;

        document.querySelector('.plans-container').scrollTo({ left: amount, behavior });
      }
    },
    selectPlan(planId) {
      if (planId !== this.form.plan.value) {
        this.chooseSubscriptionPlan(planId, this.partPlan.id === planId ? 'annually_splittable' : 'annually_onetime');
      }

      this.form.plan.value = planId;
      this.setSplittingEnabled(this.form.installments);

      if (this.windowWidth < 600) {
        this.scrollToPlan('smooth');
      }
    },
    togglePlans() {
      return (this.planOffers.show = !this.planOffers.show);
    },
    applyDiscountCoupon(discountCoupon) {
      if (!discountCoupon) return;

      this.form.discountCoupon.value = discountCoupon;

      this.initLoading();

      OfferService.validateCoupon(
        `Bearer ${UserSessionService.getAuthenticationToken()}`,
        discountCoupon,
        this.userInfo.id,
      )
        .then(response => {
          for (let key in this.planOffer.options) {
            this.planOffer.options[key].price.discountPercentage = response.data.discountPercentage;
            // Calculo do desconto
            let discountedPlan =
              this.planOffer.options[key].price.original * ((100 - response.data.discountPercentage) / 100);
            discountedPlan = +discountedPlan.toFixed(2);
            this.planOffer.options[key].price.discount = discountedPlan;
          }

          this.form.discountCoupon.isValid = true;
          this.form.discountCoupon.disabled = true;
          this.discountPercentage = `-${response.data.discountPercentage}%`;
          this.discountApplied = true;
          this.discountCouponValue = discountCoupon;

          this.generateInstallmentOptions(this.partPlan.price.discount);

          this.trackSnowplow('sendSnowplowSubscribeEvent', {
            form_element: this.form.discountCoupon,
            step_name: 'user_coupon',
            step_num: 6,
            step_value: discountCoupon,
          });
        })
        .catch(error => {
          const statusCode = error.response.status;

          if (error.response) {
            error = error.response.data;
          }
          const errorMessage =
            error.mensagem ||
            error.message ||
            'Ocorreu um erro ao validar o cupom. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379';

          Sentry.captureException(error);

          if (statusCode === 401 || statusCode === 403) {
            UserSessionService.removeData();
            this.finishLoading();
            this.createToast(errorMessage, {
              redirectTo: 'login',
            });
          } else if (statusCode === 422 && error.code.includes('COUPON_')) {
            document.querySelector('#discount-coupon').value = '';
            this.form.discountCoupon.value = '';
            this.createToast(errorMessage);
          } else {
            this.createToast(errorMessage);
          }
        })
        .finally(() => {
          this.finishLoading();
        });
    },
    removeDiscountApplied(formElement) {
      this.form.discountCoupon.value = '';
      this.form.discountCoupon.disabled = false;
      this.discountApplied = false;
      this.discountPercentage = false;
      this.generateInstallmentOptions(this.partPlan.price.full);

      this.trackSnowplow('sendSnowplowSubscribeEvent', {
        form_element: formElement,
        step_name: 'user_coupon',
        step_num: 6,
      });
    },
    generateInstallmentOptions(price) {
      if (this.form.installments) {
        this.form.installments.options = [];

        for (let i = 2; i <= 12; i++) {
          this.form.installments.options.push({
            value: i,
            price: (price / i).toFixed(2),
          });
        }
      }
    },
    async submitSubscription(event) {
      try {
        event.preventDefault();
        this.isSubmitButtonDisabled = true;

        this.initLoading();
        const formValidation = this.validateCreditCardForm();
        if (!formValidation.isValid) {
          this.finishLoading();
          this.createToast(formValidation.error.message);
          return;
        }

        const preAuthorizationInfo = await this.getPreAuthorizationSubscriptionBodyRequest();
        const preAuthorizationResult = await SubscriptionService.submitPreAuthorizationSubscriptionNew(
          preAuthorizationInfo,
        );
        const activateSubscriptionInfo = this.getActivateSubscriptionBodyRequest(
          preAuthorizationInfo,
          preAuthorizationResult,
        );

        await SubscriptionService.submitActivateSubscription(activateSubscriptionInfo);

        this.storeSubscriptionData();

        this.sendSuccessfulSubscriptionEvent();

        await SubscriptionService.changeUserStateToSubscriber();

        this.trackBrazeWhenSuccessPay();

        this.goToCreditCardCongratulationsScreen();
      } catch (error) {
        this.sendSubscriptionFailureEvent();

        if (error.payment_token_creation) {
          this.createToast('Erro no cadastramento do cartão.');
          return;
        }

        if (error.response) {
          error = error.response.data;
        }

        const errorMessage = error.mensagem || error.message;

        if (errorMessage) {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            UserSessionService.removeData();
            this.createToast(errorMessage, {
              redirectTo: 'login',
            });
          } else if (
            error.error &&
            error.error.code &&
            error.error.code === 'SUBSCRIPTION_CREDIT_CARD_TRANSACTION_UNAUTHORIZED'
          ) {
            this.trackBrazeWhenError();
            this.isCardSubscriptionError = true;
            this.isCreditCardFailure = true;
            this.failure = true;
            this.creditCardFailureMessage = errorMessage;
          } else if (error.code && error.code === 'SUBSCRIPTION_PAYMENT_AUTHORIZATION_FAIL') {
            this.trackBrazeWhenError();
            this.isCreditCardFailure = true;
            this.failure = true;
            let failureMessage = errorMessage;
            if (error.details && error.details.descricao) {
              failureMessage = `${failureMessage}\n${error.details.descricao}`;
            }
            this.creditCardFailureMessage = failureMessage;
          } else if (errorMessage === 'Network Error') {
              this.createToast(this.languageString.GENERAL_FAILURE_errorTitle);
            } else {
            this.trackBrazeWhenError();
            this.createToast(this.languageString.GENERAL_FAILURE_subTitle + errorMessage);
          }
        } else {
          this.isGeneralFailure = true;
          this.failure = true;
        }
      } finally {
        this.isSubmitButtonDisabled = false;
        this.finishLoading();
      }
    },
    storeSubscriptionData() {
      const currentPlan = this.planOffer.options.find(planOffer => planOffer.id === this.form.plan.value);
      let price;
      if(this.discountApplied && currentPlan.price.discount) {
        price = currentPlan.price.discount;
      } else {
        price = currentPlan.price.full
      }

      window.sessionStorage.setItem('planName', currentPlan.name);
      window.sessionStorage.setItem('totalValue', this.numberToCurrency(price));

      if (this.form.installments) {
        const installmentValue =
          this.form.installments.value > 1
            ? this.numberToCurrency((price / this.form.installments.value).toFixed(2))
            : null;
        window.sessionStorage.setItem('installmentValue', installmentValue);

        window.sessionStorage.setItem('installmentQuantity', this.form.installments.value);
      }
      window.sessionStorage.setItem('userCpf', this.form.cpf.value);
      window.sessionStorage.setItem('userEmail', this.userInfo.email);
    },
    goToBankSlipCheckout() {
      let routerBankSlip = { name: 'PAYMENT_TYPE_BANK_SLIP' };
      this.isCreditCardFailure = !this.isCreditCardFailure;
      this.failure = false;
      this.discountApplied = false;
      this.setCPF();

      if (this.discountCouponValue) {
        this.form.discountCoupon.value = this.discountCouponValue;
        this.applyDiscountCoupon(this.discountCouponValue);
        routerBankSlip = {
          ...routerBankSlip,
          query: { couponCode: this.discountCouponValue },
        };
      }

      this.trackSnowplow('trackButton', {
        button_name: 'payment_method',
        additional_properties: 'bank_slip',
      });

      this.$router.push(routerBankSlip);
    },
    displayCreditCardPaymentCheckout() {
      const clearCreditCardData = () => {
        const { cardNumber, cardExpirationDate, cardVerificationValue, cardName } = this.form;

        cardNumber.value = '';
        cardExpirationDate.value = '';
        cardVerificationValue.value = '';
        cardName.value = '';
      };

      clearCreditCardData();
      this.isCreditCardFailure = !this.isCreditCardFailure;
      this.failure = false;
      this.isCreditCard = true;

      this.trackSnowplow('trackButton', {
        button_name: 'payment_method',
        additional_properties: 'credit_card',
      });
    },
    setSplittingEnabled(formInstallments) {
      this.splittingEnabled = this.form.plan.value === this.partPlan.id;

      if (this.splittingEnabled) {
        this.trackSnowplow('sendSnowplowSubscribeEvent', {
          form_element: formInstallments,
          step_name: 'user_installments',
          step_num: 7,
          step_value: `${formInstallments.value} installments`,
        });
      }
    },
    setCPF() {
      if (this.userInfo && this.userInfo.cpf) {
        this.form.cpf.value = Utils.maskCPF(this.userInfo.cpf);
        this.form.cpf.isValid = true;
      }
    },
    async getPreAuthorizationSubscriptionBodyRequest() {
      const idUsuario = this.userInfo.id;
      const identificadorPlanoLoja = this.form.plan.value;
      let pagamento = {
        numeroParcelas: 1,
        metodoPagamento: 'credit_card',
        cupomDesconto: undefined,
        descricaoCartao: 'Meu cartão',
        tokenIugu: '',
        tokenPagarme: '',
        tokenLayer: '',
      };
      const cliente = {
        email: this.userInfo.email,
        nome: this.form.cardName.value,
        cpfCnpj: Utils.sanitizeDigitsString(this.form.cpf.value),
      };

      if (this.discountApplied && this.form.discountCoupon) {
        pagamento.cupomDesconto = this.form.discountCoupon.value;
      }
      this.form.installments.value = this.splittingEnabled ? this.form.installments.value : 1;
      pagamento.numeroParcelas = this.form.installments.value.toString();

      try {
        const paymentTokenIugu = await IuguService.createPaymentToken(
          IuguService.createCreditCardObj(
            Utils.sanitizeDigitsString(this.form.cardNumber.value),
            this.form.cardExpirationDate.value,
            this.form.cardVerificationValue.value,
            this.form.cardName.value,
          ),
        );
        pagamento.tokenIugu = paymentTokenIugu;
      } catch (e) {
        console.error('Não foi possível realizar a tokenização no ambiente Iugu', JSON.stringify(e));
      }

      const card = {
            number: this.form.cardNumber.value.replace(/\s/g, ''),
            holder_name: this.form.cardName.value,
            exp_month: this.form.cardExpirationDate.value.substr(0, 2),
            exp_year: this.form.cardExpirationDate.value.substr(3, 2),
            cvv: this.form.cardVerificationValue.value
      };
      try {
        const paymentTokenPagarme = await PagarmeService.createPaymentToken({
          type: 'card',
          card,
        });
        pagamento.tokenPagarme = paymentTokenPagarme.data.id;
      } catch (e) {
        console.error('Não foi possível realizar a tokenização no ambiente PagarMe', JSON.stringify(e));
      }

      try {
        const customerInfo = {
          document: Utils.sanitizeDigitsString(this.form.cpf.value),
          email: this.userInfo.email,
        }
        const paymentTokenLayer = await AfyaLayerService.createPaymentToken(card, customerInfo);
        pagamento.tokenLayer = paymentTokenLayer.data.id;
      } catch (e) {
        console.error('Não foi possível realizar a tokenização no ambiente Afya Layer', JSON.stringify(e));
      }

      if (!pagamento.tokenPagarme && !pagamento.tokenIugu && !pagamento.tokenLayer) {
        alert('Não foi possível validar o cartão de crédito. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379');
        return;
      }

      return {
        idUsuario,
        identificadorPlanoLoja,
        pagamento,
        cliente
      };
    },
    getActivateSubscriptionBodyRequest(preAuthorizationInfo, preAuthorizationResult) {
      const { custom_variables } = preAuthorizationInfo;
      const { subscriptionId, idAssinatura, idUsuario } = preAuthorizationResult.data.data;
      return {
        idAssinatura,
        idUsuario,
        subscriptionId,
        custom_variables,
      };
    },
    trackerSnowplowAndBrazeByUserCardName(form) {
      this.trackSnowplow('sendSnowplowSubscribeEvent', {
        form_element: form.cardName,
        step_name: 'user_card_name',
        step_num: 4,
      });

      this.trackBraze('trackEvent', {
        name: 'subscribe_user_card_name',
        attribute: 'step_name',
        event_name: 'subscribe',
      });
    },
    sendSuccessfulSubscriptionEvent() {
      this.trackSnowplow('trackSubscribe', {
        step_name: 'subscribe_success',
        step_num: 8,
        step_value: 'credit_card',
        failure_reason: null,
      });

      this.trackBraze('trackEvent', {
        name: 'subscribe_success',
        attribute: 'step_name',
        event_name: 'subscribe',
      });
    },
    sendSubscriptionFailureEvent() {
      this.trackGTM('trackSubscribe', {
        event: 'naoCompletouAssinatura',
        category: 'click',
        label: 'naoCompletouAssinatura',
      });
    },
    goToCreditCardCongratulationsScreen() {
      this.navigateToRoute({
        name: 'congrats',
        query: { stepName: 'payment' },
      });
    },
    hideMessageGeneralFailure() {
      this.isGeneralFailure = false;
      this.failure = false;
    },
    trackBrazeWhenLoadPage() {
      this.trackBraze('trackEvent', {
        name: 'iniciou_checkout',
        attribute: 'step_name',
        event_name: 'checkout',
      });

      this.trackBraze('trackEvent', {
        name: 'checkout_credit_card',
        attribute: 'step_name',
        event_name: 'checkout',
      });
    },
    trackBrazeWhenSuccessPay() {
      this.trackBraze('trackEvent', {
        name: 'finalizou_checkout',
        attribute: 'step_name',
        event_name: 'checkout',
      });
    },
    trackBrazeWhenError() {
      this.trackBraze('trackEvent', {
        name: 'erro_pagamento_checkout_cartao_credito',
        attribute: 'step_name',
        event_name: 'checkout',
      });
    },
  },
};
</script>

<style scoped lang="scss" src="../checkout-card/checkout-card.scss" />
