<template>
  <header class="header">
    <div class="wrapper">
      <span class="back" v-if="showBack">
        <img
          v-on:click="back"
          src="../../assets/images/svg/arrow.svg"
          alt="voltar"
          title="voltar"
        />
      </span>

      <img
        class="logo"
        src="../../assets/images/svg/wb-logo.svg"
        alt="Whitebook"
      />
      <ul :data-active="step" v-bind:class="{ error: status }">
        <li class="login">Entrar</li>
        <li class="payment">Pagamento</li>
        <li class="start">Começar</li>
      </ul>
    </div>
  </header>
</template>

<script>
  export default {
    computed: {
      step() {
        return this.$route.meta.step;
      },
      showBack() {
        // back should not show when there is an error
        if (this.status) {
          return false;
        }

        if (
          this.$route.meta.step === 'login' ||
          this.$route.meta.step === 'payment'
        ) {
          return true;
        }
        return false;
      },
    },
    methods: {
      back: function () {
        window.history.back();
      },
    },
    props: ['status'],
  };
</script>

<style scoped lang="scss" src="./CustomHeader.scss" />
