<template>
  <div class="payment-methods">
    <div class="card-types">
      <svg-image
        class="card-flag"
        file-name="icon-master-card.svg"
        alt="Icone do cartão MasterCard"
      />
      <svg-image
        class="card-flag"
        file-name="icon-dinners-club.svg"
        alt="Icone do cartão DinnersClub"
      />
      <svg-image
        class="card-flag"
        file-name="icon-american-express.svg"
        alt="Icone do cartão AmericanExpress"
      />
      <svg-image
        class="card-flag"
        file-name="icon-visa.svg"
        alt="Icone do cartão Visa"
      />
      <svg-image
        class="card-flag"
        file-name="icon-elo.svg"
        alt="Icone do cartão Elo"
      />
    </div>
    <div class="payment-gateway">
      <span>Pagamentos por</span>
      <svg-image
        class="card-flag"
        file-name="icon-iugu.svg"
        alt="Icone do meio de pagamento IUGU"
      />
    </div>
  </div>
</template>

<script>
  import SVGImage from '@/components/SVGImage';

  export default {
    components: {
      'svg-image': SVGImage,
    },
  };
</script>

<style scoped lang="scss" src="./payment-methods.scss" />
