var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('custom-header',{attrs:{"status":_vm.isBankSlipFailure}}),(_vm.isBankSlipFailure)?_c('bank-slip-failure',{attrs:{"action-back-to-bank-slip-payment":_vm.displayBankSlipPaymentCheckout,"bank-slip-failure-message":_vm.bankSlipFailureMessage}}):_vm._e(),(!_vm.isBankSlipFailure)?_c('div',{staticClass:"checkout page",style:(_vm.plansHeight)},[_c('form',{staticClass:"checkout-form",attrs:{"name":"checkout-form","novalidate":"true"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"checkout-form-body"},[_c('div',{staticClass:"plan-info expanded",attrs:{"id":"planInfo"},on:{"click":_vm.calculatePlansHeight}},[_c('div',{staticClass:"section-title open"},[_c('p',{staticClass:"primary"},[_vm._v("Confira o seu plano:")]),_c('div',{staticClass:"secondary",on:{"click":function($event){_vm.planOffers.show = !_vm.planOffers.show}}},[(_vm.userInfo)?_c('p',{staticClass:"user-email",attrs:{"id":"boleto-userEmail"}},[_vm._v(" "+_vm._s(_vm.userInfo.email)+" ")]):_vm._e()])]),(_vm.planOffer)?_c('div',{staticClass:"plans-container open"},_vm._l((_vm.availablePlans),function(plan){return _c('div',{key:plan.id,staticClass:"plan",class:{ discount: _vm.discountApplied }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.plan.value),expression:"form.plan.value"}],attrs:{"id":plan.id,"type":"radio","name":"plan"},domProps:{"value":plan.id,"checked":_vm._q(_vm.form.plan.value,plan.id)},on:{"change":function($event){return _vm.$set(_vm.form.plan, "value", plan.id)}}}),_c('label',{staticClass:"plan-card",class:plan.class,attrs:{"for":plan.id,"data-discount-percentage":`-${
                  _vm.discountApplied ? plan.price.discountPercentage : plan.price.originalDiscountPercentage
                }%`}},[_c('p',{staticClass:"plan-title"},[_c('span',{staticClass:"period"},[_vm._v(_vm._s(plan.title.period))]),_c('span',{staticClass:"type"},[_vm._v(_vm._s(plan.title.type))])]),_c('p',{staticClass:"price",class:{ discount: _vm.discountApplied }},[_c('span',{staticClass:"full"},[_vm._v("De R$ "+_vm._s(_vm.numberToCurrency(plan.price.original)))]),_c('span',[_vm._v(" | ")]),_c('span',{class:{ 'with-coupon-discount-applied': _vm.couponDiscountApplied }},[_c('span',{staticClass:"withDiscount"},[_vm._v("Por R$ "+_vm._s(_vm.numberToCurrency(_vm.discountApplied ? plan.price.discount : plan.price.full)))])])])])])}),0):_vm._e(),_c('div',{staticClass:"submit-form mobile"},[_c('custom-button',{attrs:{"primary":true,"action":_vm.submitSubscription}},[_vm._v(" Finalizar pagamento ")])],1)]),_c('div',{staticClass:"container"},[_c('h2',{staticClass:"title-form"},[_vm._v("Falta pouco!")]),_c('h3',{staticClass:"subtitle-form"},[_vm._v("Insira abaixo seus dados pessoais para gerar o boleto:")]),_c('img',{staticClass:"boleto-icon",attrs:{"src":require("../../assets/images/svg/boleto.svg"),"alt":"Pagamento por boleto"}}),_vm._m(0),_c('div',{staticClass:"payment-info"},[_c('div',{staticClass:"info-container"},[_c('form-input',{attrs:{"name":'fullName',"value":_vm.form.fullName.value},on:{"input":function($event){_vm.form.fullName.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
                    form_element: _vm.form.fullName,
                    step_name: 'user_full_name',
                    step_num: 10,
                  })}}}),_c('form-input',{attrs:{"name":'cpf',"disabled":_vm.form.cpf.disabled,"value":_vm.form.cpf.value},on:{"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
                    form_element: _vm.form.cpf,
                    step_name: 'user_cpf',
                    step_num: 11,
                  })}}}),_c('form-input',{attrs:{"name":'zipCode',"value":_vm.form.zipCode.value},on:{"input":function($event){_vm.form.zipCode.value = $event},"blur":function($event){return _vm.fillAddress(_vm.form.zipCode)}}}),_c('form-input',{attrs:{"name":'street',"value":_vm.form.street.value},on:{"input":function($event){_vm.form.street.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
                    form_element: _vm.form.street,
                    step_name: 'address_street',
                    step_num: 14,
                  })}}}),_c('div',{staticClass:"row"},[_c('form-input',{attrs:{"name":'number',"value":_vm.form.number.value},on:{"input":function($event){_vm.form.number.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: _vm.form.number,
                      step_name: 'address_number',
                      step_num: 15,
                    })}}}),_c('form-input',{attrs:{"name":'complement',"value":_vm.form.complement.value},on:{"input":function($event){_vm.form.complement.value = $event},"blur":function($event){return _vm.trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: _vm.form.complement,
                      step_name: 'address_complement',
                      step_num: 16,
                    })}}})],1),_c('div',{staticClass:"discount-coupon"},[(_vm.planOffer && _vm.planOffer.acceptCoupon)?_c('form-input',{attrs:{"name":'discountCoupon',"value":_vm.form.discountCoupon.value,"disabled":_vm.form.discountCoupon.disabled,"readonly":_vm.form.discountCoupon.readonly,"change":_vm.handleCouponValue},on:{"input":function($event){_vm.form.discountCoupon.value = $event}}}):_vm._e(),(!_vm.discountApplied && !_vm.form.discountCoupon.readonly)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.applyDiscountCoupon(_vm.form.discountCoupon.value)}}},[_vm._v(" Aplicar ")]):_vm._e(),(_vm.discountApplied && !_vm.form.discountCoupon.readonly)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeDiscountApplied(_vm.form.discountCoupon)}}},[_vm._v(" X ")]):_vm._e()],1),_c('div',{staticClass:"discount-coupon-info"},[_vm._v("Desconto não acumulativo. Ao aplicar valerá como o principal.")])],1)]),_c('div',{staticClass:"purchase-summary"},[_c('div',{staticClass:"summary"},[_vm._m(1),_vm._l((_vm.applySummary),function(summaryPlan){return _c('div',{key:summaryPlan.id},[_c('p',{staticClass:"summary-price"},[_c('span',{staticClass:"full"},[_vm._v("R$"+_vm._s(_vm.numberToCurrency(summaryPlan.price.original)))]),_vm._v(" | "),(_vm.discountApplied)?_c('span',[_vm._v("R$"+_vm._s(_vm.numberToCurrency(summaryPlan.price.discount)))]):_c('span',[_vm._v("R$"+_vm._s(_vm.numberToCurrency(summaryPlan.price.full)))])])])})],2),_c('div',{staticClass:"checkout-form-button"},[_c('custom-button',{attrs:{"primary":true,"action":_vm.submitSubscription}},[_vm._v(" Finalizar pagamento ")])],1)]),_c('div',{staticClass:"checkout-form-button-desktop"},[_c('custom-button',{attrs:{"primary":true,"action":_vm.submitSubscription}},[_vm._v(" Finalizar pagamento ")])],1)])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"iugu"},[_vm._v(" Pagamentos por "),_c('img',{attrs:{"src":require("../../assets/images/svg/iugu.svg"),"alt":"Pagamentos por iugu"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Resumo da compra")])])
}]

export { render, staticRenderFns }