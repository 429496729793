import axios from 'axios';
import * as Sentry from '@sentry/browser';
import UserSessionService from '@/services/user-session';
import { subscriptionAdapter } from '@/filters/subscriptionAdapter'

class SubscriptionService {
  constructor() {
    this.api_url = process.env.VUE_APP_URL_API_ASSINATURA;
    this.api_pagamentos_url = process.env.VUE_APP_URL_API_PAGAMENTOS;
  }

  getSubscriptionStatus(userId) {
    return axios.get(`${this.api_url}/v2/subscriptions?product_id=1&id_usuario=${userId}`);
  }

  async validateDiscountCoupon(planStoreId, couponCode) {
    try {
      const endpoint = `${this.api_url}/coupon?planStoreId=${planStoreId}&couponCode=${couponCode}&couponType=desconto`;
      const response = await axios.get(endpoint);

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  submitPreAuthorizationSubscription(infoToPreAuthorization, recaptchaToken) {
    axios.defaults.headers.common['tokenReCaptcha'] = recaptchaToken;

    if (infoToPreAuthorization && infoToPreAuthorization.pagamento
      && infoToPreAuthorization.pagamento.tokenPagarme) {
        return axios.post(`${this.api_pagamentos_url}/assinatura/pre-autorizacao`, infoToActivateSubscription);
    }
    return axios.post(`${this.api_url}/iugu/pre-autorizacao`, infoToPreAuthorization);
  }

  submitPreAuthorizationSubscriptionNew(infoToPreAuthorization) {
    return axios.post(`${this.api_pagamentos_url}/assinatura/pre-autorizar`, infoToPreAuthorization);
  }

  submitActivateSubscription(infoToActivateSubscription) {
    if (infoToActivateSubscription && infoToActivateSubscription.idAssinatura
      && infoToActivateSubscription.idUsuario) {
      return axios.post(`${this.api_pagamentos_url}/assinatura/efetivar`, infoToActivateSubscription);
    }
    return axios.post(`${this.api_url}/iugu/efetivar`, infoToActivateSubscription);
  }

  getActiveSubscriptionByUser(clientId) {
    return axios.get(`${this.api_url}/portal/gerenciar/${clientId}`);
  }

  getPaymentMethodsByClient(clientId) {
    return axios.get(`${this.api_url}/iugu/forma_pgto/list/${clientId}`);
  }

  getPaymentMethodsByUserId(userId) {
    return axios.get(`${this.api_url}/payment_methods/list/${userId}`);
  }

  changePaymentMethodByUser(userId, newPaymentMethod) {
    return axios.post(`${this.api_url}/v2/${userId}/alterar_forma_pgto`, newPaymentMethod);
  }

  getOffersByGateway(offerId) {
    return axios.get(`${this.api_url}/gateways/iugu/offers${offerId ? `/${offerId}` : ''}`);
  }

  listOffersByGateway(gateway = 'iugu', offerId) {
    return axios.get(`${this.api_url}/gateways/${gateway}/offers${offerId ? `/${offerId}` : ''}`);
  }

  getSuspendSubscription(userId) {
    return axios.get(`${this.api_url}/iugu/${userId}/suspender`);
  }

  async changeUserStateToSubscriber() {
    try {
      const currentUserSession = UserSessionService.getData();
      const responseSubscription = await this.getSubscriptionStatus(currentUserSession.currentUser.id);
      currentUserSession.subscription = subscriptionAdapter(responseSubscription.data);
      currentUserSession.currentUser.tipo = 1;
      currentUserSession.subscription.ativa = true;
      currentUserSession.subscription.premium.active = true;

      UserSessionService.updateData(currentUserSession);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async retrieveInvoices(subscriptionId) {
    return axios.get(`${this.api_url}/iugu/${subscriptionId}/faturas`);
  }
}

export default new SubscriptionService();
