export const subscriptionAdapter = newSubscriptionModel => ({
  ativa: newSubscriptionModel.data.subscription.active,
  premium: {
    active: newSubscriptionModel.data.subscription.active,
    trial: newSubscriptionModel.data.subscription.trial,
    beginDate: newSubscriptionModel.data.subscription.beginDate,
    endDate: newSubscriptionModel.data.subscription.endDate,
    id: newSubscriptionModel.data.subscription.id,
    status: newSubscriptionModel.data.subscription.status,
    premiumAccessEndDate: newSubscriptionModel.data.subscription.gracePeriodEndDate,
    planId: newSubscriptionModel.data.subscription.plan.id,
    plan: {
      id: newSubscriptionModel.data.subscription.plan.id,
      storeId: newSubscriptionModel.data.subscription.plan.storeId,
      description: newSubscriptionModel.data.subscription.plan.description,
      splittable: newSubscriptionModel.data.subscription.plan.splittable,
      priceFull: newSubscriptionModel.data.subscription.plan.price.value,
      period: newSubscriptionModel.data.subscription.plan.period
    },
    price: newSubscriptionModel.data.subscription.payment.paid.value,
    installment: newSubscriptionModel.data.subscription.payment.paid.installments,
    installmentValue: newSubscriptionModel.data.subscription.payment.paid.installmentValue,
    paymentMethod: newSubscriptionModel.data.subscription.payment.method,
    so: "WEB",
    gateway: {
      id: newSubscriptionModel.data.subscription.payment.gateway.id,
      name: newSubscriptionModel.data.subscription.payment.gateway.name
    },
    customerId: newSubscriptionModel.data.subscription.payment.user.identifier,
    payment: {
      gateway: {
        id: newSubscriptionModel.data.subscription.payment.gateway.id,
        name: newSubscriptionModel.data.subscription.payment.gateway.name
      }
    }
  },
  freeTasting: {
    active: newSubscriptionModel.data.freeTasting.active
  },
  lastChecked: newSubscriptionModel.data.lastChecked
});
