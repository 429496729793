import { render, staticRenderFns } from "./payment-methods.vue?vue&type=template&id=6cb98cef&scoped=true"
import script from "./payment-methods.vue?vue&type=script&lang=js"
export * from "./payment-methods.vue?vue&type=script&lang=js"
import style0 from "./payment-methods.scss?vue&type=style&index=0&id=6cb98cef&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb98cef",
  null
  
)

export default component.exports