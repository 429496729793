<template>
  <img class="responsive-img" :alt="alt" :src="path" />
</template>

<script>
  export default {
    props: {
      fileName: String,
      alt: String,
    },
    computed: {
      path() {
        return require(`@/assets/images/svg/${this.fileName}`);
      },
    },
  };
</script>

<style lang="scss" scoped src="./SVGImage.scss"></style>
