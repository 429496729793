var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasSubscriptionData)?_c('div',{staticClass:"container-subscription-info"},[_c('div',{staticClass:"subscription-info"},[_c('h1',[_vm._v("Assinatura")]),_c('div',[_c('ul',[_c('li',{staticClass:"status"},[_c('strong',[_vm._v("Status da assinatura")]),(_vm.hasSubscriptionData && _vm.getStatus() !== 'PENDENTE' && (_vm.getSubscription().trial || _vm.getStatus() === 'TRIAL'))?_c('span',{staticClass:"text-status aproved",attrs:{"id":"subscription-info-status"}},[_vm._v(" Período de teste "),_c('em',{staticClass:"fa fa-check"}),_c('div',{staticClass:"container-message-alert"},[_c('div',{staticClass:"container-top-message"},[_vm._m(0),_c('p',{staticClass:"text-alert"},[_vm._v(" Seu período de testes encerra no dia "+_vm._s(_vm.formatDate(_vm.responseSubscription.data.subscription.endDate))+". "),(_vm.responseSubscription.data.subscription.payment.paid.method === 'credit_card')?_c('span',[_vm._v(" Após essa data, enviaremos a cobrança na fatura do seu cartão de crédito. ")]):_c('span',[_vm._v(" Após essa data, realize o pagamento do boleto para continuar utilizando o Whitebook. ")])])]),(
                  _vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip' && _vm.invoiceStatus === 'pending'
                )?_c('div',{staticClass:"container-buttons"},[_c('a',{staticClass:"button-bankslip",on:{"click":function($event){return _vm.copyBarcode()}}},[(_vm.bankSlipDigitableLineCopied)?_c('p',[_vm._v(" Código de barras copiado "),_c('img',{attrs:{"height":"10","width":"10","src":require("../../assets/images/svg/check-success.svg"),"alt":"info-icon"}})]):_c('p',[_vm._v("Copiar código de barras")])]),_c('a',{staticClass:"button-bankslip",on:{"click":function($event){return _vm.downloadBankSlip()}}},[_c('p',[_vm._v("Baixar boleto")])])]):_vm._e()])]):(
              _vm.hasSubscriptionData &&
              (_vm.getStatus() === 'APROVADA' ||
                _vm.getStatus() === 'SUSPENSA' ||
                _vm.getStatus() === 'REEMBOLSADA' ||
                _vm.getStatus() === 'CONTESTADA' ||
                _vm.getStatus() === 'PRE-AUTORIZADA' ||
                _vm.getStatus() === 'PENDENTE') &&
              !_vm.assinaturaCancelada
            )?_c('span',[(_vm.getStatus() === 'APROVADA')?_c('span',{staticClass:"text-status aproved"},[_vm._v(" "+_vm._s(_vm.status[_vm.responseSubscription.data.subscription.status])+" "),_c('em',{staticClass:"fa fa-check"})]):_c('span',{staticClass:"text-status pending"},[_vm._v(" "+_vm._s(_vm.status[_vm.responseSubscription.data.subscription.status])+" "),_c('em',{staticClass:"fa fa-times"})]),(
                _vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip' && _vm.invoiceStatus === 'pending'
              )?_c('div',{staticClass:"container-message-alert"},[_vm._m(1),(
                  _vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip' && _vm.invoiceStatus === 'pending'
                )?_c('div',{staticClass:"container-buttons"},[_c('a',{staticClass:"button-bankslip",on:{"click":function($event){return _vm.copyBarcode()}}},[(_vm.bankSlipDigitableLineCopied)?_c('p',[_vm._v(" Código de barras copiado "),_c('img',{attrs:{"height":"10","width":"10","src":require("../../assets/images/svg/check-success.svg"),"alt":"info-icon"}})]):_c('p',[_vm._v("Copiar código de barras")])]),_c('a',{staticClass:"button-bankslip",on:{"click":function($event){return _vm.downloadBankSlip()}}},[_c('p',[_vm._v("Baixar boleto")])])]):_vm._e()]):_vm._e(),(_vm.getStatus() === 'PRE-AUTORIZADA')?_c('div',{staticClass:"container-message-alert"},[_vm._m(2),(_vm.getStatus() === 'PRE-AUTORIZADA')?_c('div',{staticClass:"container-button"},[_vm._m(3)]):_vm._e()]):_vm._e()]):(
              _vm.hasSubscriptionData &&
              (_vm.getStatus() === 'APROVADA' ||
                _vm.getStatus() === 'SUSPENSA' ||
                _vm.getStatus() === 'REEMBOLSADA' ||
                _vm.getStatus() === 'CONTESTADA' ||
                _vm.getStatus() === 'PENDENTE') &&
              _vm.assinaturaCancelada
            )?_c('span',[_vm._v(" Ativa, mas sem renovação ")]):_vm._e()]),_c('li',{staticClass:"email"},[_c('strong',[_vm._v("Email")]),(_vm.hasSubscriptionData)?_c('span',{attrs:{"id":"subscription-info-email"}},[_vm._v(_vm._s(_vm.userSession.currentUser.email))]):_vm._e()]),_c('li',{staticClass:"plan"},[_c('strong',[_vm._v("Plano")]),(_vm.hasSubscriptionData)?_c('span',{attrs:{"id":"subscription-info-plan"}},[_vm._v(" "+_vm._s(_vm.responseSubscription.data.subscription.plan.description)+" ")]):_vm._e()]),(_vm.responseSubscription.data.subscription.plan.storeId !== 'pebmed')?_c('li',[_c('strong',[_vm._v("Valor")]),(_vm.isPagoComCupomDeDesconto)?_c('span',{attrs:{"id":"subscription-info-value"}},[_vm._v("R$ "+_vm._s(_vm.valorPagoDoPlano)+" (com cupom de desconto promocional)")]):_c('span',[(_vm.isPagamentoAVista && _vm.isGatewayWithDefaultDiscount)?_c('span',{attrs:{"id":"subscription-info-value"}},[_vm._v("R$ "+_vm._s(_vm.valorPagoDoPlano)+" (com desconto promocional à vista)")]):(_vm.isGatewayWithDefaultDiscount)?_c('span',{attrs:{"id":"subscription-info-value"}},[_vm._v("R$ "+_vm._s(_vm.valorPagoDoPlano)+" (com desconto promocional parcelado)")]):_c('span',{attrs:{"id":"subscription-info-value"}},[_vm._v("R$ "+_vm._s(_vm.valorPagoDoPlano))])])]):_vm._e(),(
            (_vm.responseSubscription.data.subscription.payment.paid.method === 'credit_card' ||
              _vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip' ||
              _vm.responseSubscription.data.subscription.payment.paid.method === 'pix') &&
            !((_vm.getSubscription().trial && _vm.getStatus() === 'APROVADA') || _vm.getStatus() === 'PRE-AUTORIZADA') &&
            _vm.responseSubscription.data.subscription.plan.storeId !== 'pebmed'
          )?_c('li',[_c('strong',[_vm._v("Forma de pagamento")]),(_vm.responseSubscription.data.subscription.payment.paid.method === 'credit_card')?_c('span',{attrs:{"id":"subscription-info-payment"}},[_vm._v("Cartão de crédito")]):(_vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip')?_c('span',{attrs:{"id":"subscription-info-payment"}},[_vm._v("Boleto")]):(_vm.responseSubscription.data.subscription.payment.paid.method === 'pix')?_c('span',{attrs:{"id":"subscription-info-payment"}},[_vm._v("Pix")]):_c('span',{attrs:{"id":"subscription-info-payment"}},[_vm._v(_vm._s(_vm.responseSubscription.data.subscription.payment.paid.method))])]):_vm._e(),(
            (_vm.responseSubscription.data.subscription.payment.paid.method === 'credit_card' ||
              _vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip' ||
              _vm.responseSubscription.data.subscription.payment.paid.method === 'pix') &&
            !((_vm.getSubscription().trial && _vm.getStatus() === 'APROVADA') || _vm.getStatus() === 'PRE-AUTORIZADA') &&
            _vm.responseSubscription.data.subscription.plan.storeId !== 'pebmed' &&
            _vm.responseSubscription.data.subscription.payment.paid.installments &&
            _vm.responseSubscription.data.subscription.payment.paid.installmentValue
          )?_c('li',[_c('strong',[_vm._v("Quantidade de parcelas")]),(_vm.responseSubscription.data.subscription.payment.paid.installments === 1)?_c('span',{attrs:{"id":"subscription-info-installments"}},[_vm._v(" À vista ")]):_c('span',{attrs:{"id":"subscription-info-installments"}},[_vm._v(" "+_vm._s(_vm.responseSubscription.data.subscription.payment.paid.installments)+"x de "+_vm._s(_vm.responseSubscription.data.subscription.payment.paid.installmentValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])]):_vm._e(),(
            _vm.hasSubscriptionData &&
            _vm.getStatus() !== 'PRE-AUTORIZADA' &&
            _vm.formatDate(_vm.getSubscription().beginDate) !== _vm.formatDate(_vm.getSubscription().endDate)
          )?_c('div',[_c('li',{staticClass:"endDate"},[(_vm.getSubscription().trial && _vm.getStatus() === 'APROVADA')?_c('span',[_c('strong',[_vm._v("Fim do período de teste ")])]):(
                _vm.getStatus() === 'SUSPENSA' ||
                _vm.getStatus() === 'REEMBOLSADA' ||
                _vm.getStatus() === 'CONTESTADA' ||
                (_vm.getSubscription().payment.paid.value === 0 && _vm.getStatus() === 'APROVADA') ||
                _vm.assinaturaCancelada
              )?_c('span',[_c('strong',[_vm._v("Ativa até")])]):(_vm.getStatus() === 'APROVADA' && !_vm.getSubscription().trial)?_c('span',[_c('strong',[_vm._v("Data da assinatura")])]):_vm._e(),(
                _vm.getStatus() === 'APROVADA' &&
                !_vm.getSubscription().trial &&
                _vm.responseSubscription.data.subscription.plan.storeId !== 'pebmed'
              )?_c('span',{staticClass:"text-date",attrs:{"id":"subscription-info-subscriptionDate"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.responseSubscription.data.subscription.beginDate))+" ")]):(_vm.hasSubscriptionData && _vm.getStatus() !== 'PENDENTE' && _vm.getStatus() !== 'TRIAL')?_c('span',{staticClass:"text-date",attrs:{"id":"subscription-info-subscriptionDate"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.responseSubscription.data.subscription.endDate))+" ")]):_vm._e()])]):_vm._e()])]),(_vm.getStatus() !== 'PRE-AUTORIZADA')?_c('p',{staticClass:"title about",class:{ open: _vm.aboutInfo.show },on:{"click":function($event){_vm.aboutInfo.show = !_vm.aboutInfo.show}}},[_vm._v(" Alterar assinatura ")]):_vm._e(),_c('div',{staticClass:"container-body-alterar-assinatura modify-subscription",class:{ open: _vm.aboutInfo.show }},[_vm._m(4),_c('div',{staticClass:"confirm-button"},[_c('p',{on:{"click":function($event){_vm.aboutInfo.show = !_vm.aboutInfo.show}}},[_vm._v("Ok, entendi")])]),_c('span',{staticClass:"container-arrow-down"})])]),(_vm.isShowRenewalInformation)?_c('div',{staticClass:"renewal-info"},[_c('div',{staticClass:"about-renewal"},[_c('h1',{staticClass:"title renewal",class:{ open: _vm.renewalInfo.show },on:{"click":function($event){_vm.renewalInfo.show = !_vm.renewalInfo.show}}},[_vm._v(" Renovação "),_c('img',{staticClass:"icon-info",attrs:{"src":require("../../assets/images/icon-info.png"),"alt":"info-icon"}})]),_c('div',{staticClass:"container-body renewal-subscription",class:{ open: _vm.renewalInfo.show }},[_vm._m(5),_c('div',{staticClass:"confirm-button"},[_c('p',{on:{"click":function($event){_vm.renewalInfo.show = !_vm.renewalInfo.show}}},[_vm._v("Ok, entendi")])]),_c('span',{staticClass:"container-arrow-down"})])]),_c('div',[_c('ul',[(_vm.getStatus() !== 'PENDENTE')?_c('li',{staticClass:"endDate"},[(!_vm.assinaturaCancelada)?_c('span',[_c('strong',[_vm._v("Data da renovação")])]):_vm._e(),(
              _vm.hasSubscriptionData &&
              _vm.getStatus() !== 'PENDENTE' &&
              _vm.getStatus() !== 'SUSPENSA' &&
              _vm.getStatus() !== 'REEMBOLSADA' &&
              _vm.getStatus() !== 'CONTESTADA' &&
              !_vm.getSubscription().trial
            )?_c('span',{staticClass:"text-date",attrs:{"id":"subscription-info-renewDate"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.responseSubscription.data.subscription.endDate))+" ")]):_c('span',{staticClass:"text-date",attrs:{"id":"subscription-info-renewDate"}},[_vm._v(" Sem renovação automática ")])]):_vm._e(),(
            _vm.hasSubscriptionData &&
            _vm.getStatus() !== 'PENDENTE' &&
            _vm.getStatus() !== 'SUSPENSA' &&
            _vm.getStatus() !== 'REEMBOLSADA' &&
            _vm.getStatus() !== 'CONTESTADA' &&
            !_vm.getSubscription().trial
          )?_c('li',[_c('strong',[_vm._v("Valor da renovação")]),(_vm.isRenovacaoComCupomDeDesconto)?_c('span',{attrs:{"id":"subscription-info-renewValue"}},[_vm._v("R$ "+_vm._s(_vm.valorDeRenovacaoDoPlano)+" (com cupom de desconto promocional)")]):_c('span',[(_vm.isPagamentoAVista && _vm.isGatewayWithDefaultDiscount)?_c('span',{attrs:{"id":"subscription-info-renewValue"}},[_vm._v("R$ "+_vm._s(_vm.valorDeRenovacaoDoPlano)+" (com desconto promocional à vista)")]):(_vm.isGatewayWithDefaultDiscount)?_c('span',{attrs:{"id":"subscription-info-renewValue"}},[_vm._v("R$ "+_vm._s(_vm.valorDeRenovacaoDoPlano)+" (com desconto promocional parcelado)")]):_c('span',{attrs:{"id":"subscription-info-renewValue"}},[_vm._v("R$ "+_vm._s(_vm.valorDeRenovacaoDoPlano))])])]):_vm._e(),_c('li',[_c('strong',[_vm._v("Valor Original do Plano")]),_c('span',{attrs:{"id":"subscription-info-renewValue"}},[_vm._v("R$ "+_vm._s(_vm.valorOriginalDoPlano))])]),(
            _vm.hasSubscriptionData &&
            _vm.getStatus() !== 'PENDENTE' &&
            _vm.getStatus() !== 'SUSPENSA' &&
            _vm.getStatus() !== 'REEMBOLSADA' &&
            _vm.getStatus() !== 'CONTESTADA' &&
            !_vm.getSubscription().trial &&
            (_vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'credit_card' ||
              _vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'bank_slip' ||
              _vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'pix')
          )?_c('li',[_c('strong',[_vm._v("Forma de pagamento")]),(_vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'credit_card')?_c('span',{attrs:{"id":"subscription-info-renewPayment"}},[_vm._v("Cartão de crédito")]):(_vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'bank_slip')?_c('span',{attrs:{"id":"subscription-info-renewPayment"}},[_vm._v("Boleto")]):(_vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'pix')?_c('span',{attrs:{"id":"subscription-info-renewPayment"}},[_vm._v("Pix")]):_c('span',{attrs:{"id":"subscription-info-renewPayment"}},[_vm._v(_vm._s(_vm.responseSubscription.data.subscription.payment.renewalPrice.method))])]):_vm._e(),(
            _vm.hasSubscriptionData &&
            _vm.getStatus() !== 'PENDENTE' &&
            _vm.getStatus() !== 'SUSPENSA' &&
            _vm.getStatus() !== 'REEMBOLSADA' &&
            _vm.getStatus() !== 'CONTESTADA' &&
            !_vm.getSubscription().trial &&
            (_vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'credit_card' ||
              _vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'bank_slip' ||
              _vm.responseSubscription.data.subscription.payment.renewalPrice.method === 'pix')
              &&
              !!_vm.responseSubscription.data.subscription.payment.renewalPrice.installments &&
              !!_vm.responseSubscription.data.subscription.payment.renewalPrice.installmentValue
          )?_c('li',[_c('strong',[_vm._v("Quantidade de parcelas")]),(_vm.responseSubscription.data.subscription.payment.renewalPrice.installments === 1)?_c('span',{attrs:{"id":"subscription-info-renewInstallments"}},[_vm._v("À vista")]):_c('span',{attrs:{"id":"subscription-info-renewInstallments"}},[_vm._v(" "+_vm._s(_vm.responseSubscription.data.subscription.payment.renewalPrice.installments)+"x de "+_vm._s(_vm.responseSubscription.data.subscription.payment.renewalPrice.installmentValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])]):_vm._e(),(_vm.hasSubscriptionData && _vm.getStatus() === 'PENDENTE')?_c('li',{staticClass:"description-subscription-pending"},[(_vm.responseSubscription.data.subscription.payment.paid.method === 'credit_card')?_c('span',{attrs:{"id":"subscription-info-pendingSubscription"}},[_vm._v(" Para continuar a usar o Whitebook, por favor, regularize seu Cartão de Crédito. ")]):(_vm.responseSubscription.data.subscription.payment.paid.method === 'bank_slip')?_c('span',{attrs:{"id":"subscription-info-pendingSubscription"}},[_vm._v(" Para continuar a usar o Whitebook, por favor, verifique as informações de pagamento por Boleto no seu e-mail, ou adicione um novo Cartão de Crédito para pagamento. ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"container-btn-renewal"},[(_vm.isPaymentMethodChangeAllowed)?_c('div',{staticClass:"btn-payment-method-change"},[_c('p',{staticClass:"title",on:{"click":function($event){return _vm.redirectToChangePaymentMethod()}}},[_vm._v(" Alterar método de pagamento ")])]):_vm._e(),(
        _vm.hasSubscriptionData &&
        _vm.getStatus() !== 'PENDENTE' &&
        _vm.getStatus() !== 'SUSPENSA' &&
        _vm.getStatus() !== 'REEMBOLSADA' &&
        _vm.getStatus() !== 'CONTESTADA' &&
        !_vm.getSubscription().trial &&
        _vm.iugu
      )?_c('div',{staticClass:"about"},[(_vm.cancelamentoDisponivel)?_c('p',{staticClass:"title",class:{ open: _vm.aboutInfo.show },on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" Cancelar renovação ")]):_vm._e(),(_vm.showModal)?_c('slideout-cancelamento',{on:{"fechar-modal-e-bloquear-cancelamento":function($event){return _vm.fecharModalEBloquearCancelamento()},"fechar-modal":function($event){return _vm.fecharModal()}}}):_vm._e()],1):(
        _vm.hasSubscriptionData &&
        _vm.getStatus() !== 'PENDENTE' &&
        _vm.getStatus() !== 'SUSPENSA' &&
        _vm.getStatus() !== 'REEMBOLSADA' &&
        _vm.getStatus() !== 'CONTESTADA' &&
        !_vm.getSubscription().trial
      )?_c('div',{staticClass:"about"},[_c('p',{staticClass:"title",class:{ open: _vm.cancelInfo.show },on:{"click":function($event){_vm.cancelInfo.show = !_vm.cancelInfo.show}}},[_vm._v(" Cancelar renovação ")]),_c('div',{staticClass:"container-body cancel-subscription",class:{ open: _vm.cancelInfo.show }},[_vm._m(6),_c('div',{staticClass:"confirm-button"},[_c('p',{on:{"click":function($event){_vm.cancelInfo.show = !_vm.cancelInfo.show}}},[_vm._v("Ok, entendi")])]),_c('span',{staticClass:"container-arrow-down"})])]):_vm._e()])]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-icon"},[_c('img',{attrs:{"src":require("../../assets/images/shape.png"),"alt":"info-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-top-message"},[_c('div',{staticClass:"container-icon"},[_c('img',{attrs:{"src":require("../../assets/images/shape.png"),"alt":"info-icon"}})]),_c('p',{staticClass:"text-alert"},[_vm._v(" Não identificamos o pagamento. Por favor, regularize agora e evite o cancelamento da assinatura. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-top-message"},[_c('div',{staticClass:"container-icon"},[_c('img',{attrs:{"src":require("../../assets/images/shape.png"),"alt":"info-icon"}})]),_c('p',{staticClass:"text-alert"},[_vm._v(" Sua assinatura não foi finalizada. Favor entrar em contato com nosso suporte através do Whatsapp. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"button-whatsapp",attrs:{"href":"https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0","target":"_blank"}},[_c('p',[_vm._v("Fale conosco pelo Whatsapp")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-text"},[_c('p',{staticClass:"body-title"},[_vm._v("Alterar assinatura")]),_c('p',{staticClass:"body"},[_vm._v(" Para alterar sua assinatura, acesse a nossa "),_c('a',{attrs:{"href":"https://help.pebmed.com.br/hc/pt-br","target":"_blank"}},[_vm._v("Central de Ajuda")]),_vm._v(" ou fale conosco "),_c('a',{attrs:{"href":"https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0","target":"_blank"}},[_vm._v(" aqui")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-text"},[_c('p',{staticClass:"body-title"},[_vm._v("Renovação")]),_c('p',{staticClass:"body"},[_vm._v(" A renovação de qualquer Plano Whitebook é feita com base no valor total, não contemplando nenhum desconto oferecido anteriormente. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-text"},[_c('p',{staticClass:"body-title"},[_vm._v("Cancelar assinatura")]),_c('p',{staticClass:"body"},[_vm._v(" Para cancelar sua assinatura, acesse a nossa "),_c('a',{attrs:{"href":"https://help.pebmed.com.br/hc/pt-br","target":"_blank"}},[_vm._v("Central de Ajuda")]),_vm._v(" ou fale conosco "),_c('a',{attrs:{"href":"https://api.whatsapp.com/send/?phone=5521973764379&text=Ol%C3%A1%2C+eu+gostaria+de+uma+ajuda.&app_absent=0","target":"_blank"}},[_vm._v(" aqui")])])])
}]

export { render, staticRenderFns }