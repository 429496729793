import { render, staticRenderFns } from "./BlackFridayCountdown.vue?vue&type=template&id=4b502e46&scoped=true"
import script from "./BlackFridayCountdown.vue?vue&type=script&lang=js"
export * from "./BlackFridayCountdown.vue?vue&type=script&lang=js"
import style0 from "./BlackFridayCountdown.scss?vue&type=style&index=0&id=4b502e46&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b502e46",
  null
  
)

export default component.exports