<template>
  <div class="subscription-info">
    <ul class="featured">
      <li>
        <span class="text plan">{{ planName }}</span>
      </li>
      <li>
        <span class="text price">
          R$ {{ totalValue }}*
          <span class="price" v-if="installmentQuantity > 1"
            >| {{ installmentQuantity }} x R$ {{ installmentValue }}</span
          >
        </span>
      </li>
    </ul>
    <ul>
      <li>
        <span class="text label">E-mail</span>
        <span class="text value">{{ userEmail }}</span>
      </li>
      <li>
        <span class="text label">CPF</span>
        <span class="text value">{{ userCpf }}</span>
      </li>
    </ul>
    <span class="renewal-info">
      Após o pagamento, a renovação automática ficará ativa.
      <p>
        *Valores promocionais não tem recorrência,<br>
        <strong>e não serão aplicados na renovação.</strong>
      </p>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'SubscriptionInfo',
    data() {
      return {
        planName: null,
        totalValue: null,
        installmentValue: null,
        installmentQuantity: null,
        userEmail: null,
        userCpf: null,
      };
    },
    created() {
      this.planName = window.sessionStorage.getItem('planName');
      this.totalValue = window.sessionStorage.getItem('totalValue');
      const value = (this.totalValue || '').replace(',','.');
      this.installmentValue = window.sessionStorage.getItem('installmentValue');
      this.installmentQuantity = window.sessionStorage.getItem(
        'installmentQuantity'
      );
      this.userEmail = window.sessionStorage.getItem('userEmail');
      this.userCpf = window.sessionStorage.getItem('userCpf');
      window.dataLayer.push({'value': Number(value)});
    },
    mounted() {
      if(!this.userCpf || this.userCpf === null ||
        !this.userEmail || this.userEmail === null
      ) {
        this.navigateToRoute({
          name: 'login'
        });
      }
    }
  };
</script>

<style scoped lang="scss" src="./SubscriptionInfo.scss"></style>
