import { render, staticRenderFns } from "./bank-slip-failure.vue?vue&type=template&id=45d106e3&scoped=true"
import script from "./bank-slip-failure.vue?vue&type=script&lang=js"
export * from "./bank-slip-failure.vue?vue&type=script&lang=js"
import style0 from "./bank-slip-failure.scss?vue&type=style&index=0&id=45d106e3&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d106e3",
  null
  
)

export default component.exports