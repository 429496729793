import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=72caf5ce&scoped=true"
var script = {}
import style0 from "./Loading.scss?vue&type=style&index=0&id=72caf5ce&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72caf5ce",
  null
  
)

export default component.exports