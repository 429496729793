<template>
  <div class="manage-devices">
    <table aria-describedby="Lista dos dispositivos que foram utilizados">
      <thead>
        <tr>
          <th scope="col">Seus Dispositivos</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tr v-if="userDevices.length === 0">
        <td>Nenhum dispositivo cadastrado.</td>
      </tr>
      <tr v-for="(device, index) in userDevices" v-else :key="index">
        <td>{{ device.dispositivo }}</td>
        <td>
          <em
            class="fa fa-trash"
            title="remover dispositivo"
            @click="deleteDevice(device.id, device.dispositivo, index)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import AuthService from '@/services/AuthService';
  import * as Sentry from '@sentry/browser';
  import UserSessionService from '@/services/user-session';

  export default {
    name: 'ManageDevices',
    data() {
      return {
        userDevices: [],
        userSession: null,
      };
    },
    async created() {
      try {
        this.userSession = UserSessionService.getData();

        const { devices } = (
          await AuthService.getUserDevices(this.userSession.currentUser.id)
        ).data;

        this.userDevices = devices;
      } catch (err) {
        if (err.response) {
          err = err.response.data;
        }

        const errorMessage = err.message || err.mensagem;

        if (errorMessage && (err.code === 'AUTH_CODE_EXPIRED'
          || err.code === 'AUTH_INVALID_AUTH_CODE'
          || err.code === 'AUTH_WRONG_TOKEN_TYPE')
        ) {
          UserSessionService.removeData();
          this.createToast(errorMessage, {
            redirectTo: 'login',
          });
        }
      }
    },
    methods: {
      deleteDevice: async function (deviceId, deviceModel, index) {
        if (confirm(`Remover o dispositivo ${deviceModel} da sua lista?`)) {
          try {
            this.initLoading();

            await AuthService.deleteUserDevice(
              this.userSession.currentUser.id,
              deviceId,
            );

            this.finishLoading();
            this.userDevices.splice(index, 1);
          } catch (err) {
            this.finishLoading();

            if (err.response) {
              err = err.response.data;
            }

            const errorMessage = err.message || err.mensagem;

            if (errorMessage) {
              if (err.code === 'AUTH_CODE_EXPIRED'
                || err.code === 'AUTH_INVALID_AUTH_CODE'
                || err.code === 'AUTH_WRONG_TOKEN_TYPE'
              ) {
                UserSessionService.removeData();
                this.createToast(errorMessage, {
                  redirectTo: 'login',
                });
              } else this.createToast(errorMessage);
            } else {
              this.createToast(
                'Algo deu errado ao tentar remover o seu dispositivo. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379',
              );
              Sentry.captureException(err);
            }
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .manage-devices {
    width: 90%;

    display: flex;
    flex-direction: column;

    table {
      max-width: 670px;
      width: 100%;
      font-size: 1em;

      th,
      td {
        padding: 0.8em;
      }

      th {
        text-transform: uppercase;
        font-size: 0.8em;

        &:nth-of-type(2) {
          color: #888888;
          text-align: center;
        }
      }

      td {
        &:nth-of-type(1) {
          background-color: #e3e3e3;
          color: #888888;
          font-weight: 400;
        }

        &:nth-of-type(2) {
          background-color: #f6f6f6;
          color: #bcbcbc;
          text-align: center;
        }

        em {
          font-size: 1.7em;
          cursor: pointer;

          &:hover {
            color: #ec6767;
          }
        }
      }
    }
  }

  @media screen and (min-height: 569px) {
    .manage-devices table {
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 769px) {
    .manage-devices {
      max-width: 27em;
    }
  }
</style>
