import Vue from 'vue';
import AuthService from '@/services/AuthService';
import SentryService from '@/services/sentry';
import BrazeService from '@/services/braze';
import SnowplowService from "@/services/SnowplowService";
import Utils from '@/assets/js/Utils';

class UserSessionService {
  constructor() {
    this.appAuthenticationToken = 'APP_AUTHENTICATION_TOKEN';
    this.appUserData = 'APP_USER_DATA';
    this.appNameAuthenticationCookie = 'globals';
  }

  isIUGUSubscription() {
    if (!this.isAuthenticated()) {
      return false;
    }
    const userData = this.getData();
    if (!userData) {
      return false;
    }
    const { subscription } = userData;
    if (subscription && subscription.premium && subscription.premium.gateway && subscription.premium.gateway.id) {
      return userData.subscription.premium.gateway.id === 1;
    }
    return false;
  }
  isNotMobileGateway() {
    if (!this.isAuthenticated()) {
      return false;
    }
    const userData = this.getData();
    if (!userData) {
      return false;
    }
    const { subscription } = userData;
    if (subscription && subscription.premium && subscription.premium.gateway && subscription.premium.gateway.id) {
      const gatewayId = userData.subscription.premium.gateway.id;
      return (gatewayId !== 2 && gatewayId !== 3 && gatewayId !== 4);
    }
    return false;
  }
  isAuthenticated() {
    if (this.hasNoCookiesNorLocalStorage()) {
      return false;
    }
    try {
      const userData = this.getData() || this.getAuthenticationCookieData();
      if (!userData || !userData.authData) {
        return false;
      }
      const DecodedToken = JSON.parse(atob(userData.authData.split('.')[1]));
      return new Date(DecodedToken.exp * 1000) > new Date();
    } catch (_) {
      return false;
    }
  }
  hasNoCookiesNorLocalStorage() {
    return !this.existAuthenticationCookie() && !this.existLocalData();
  }
  setData(userData) {
    if (!userData && !userData.usuario) {
      throw new Error('[UserSessionService] -> setData: It is mandatory to provide a valid object.');
    }
    const userDataParse = {
      origin: process.env.VUE_APP_NAME,
      authData: userData.codigo,
      currentUser: {
        id: userData.usuario.id,
        cpf: userData.usuario.cpf,
        email: userData.usuario.email,
        nome: userData.usuario.nome,
        tipo: userData.usuario.id_tipo_usuario,
        cep: userData.usuario.endereco ? userData.usuario.endereco.cep : null,
        id_formacao_medica: userData.usuario.id_formacao_medica,
        id_especialidade: userData.usuario.id_especialidade,
        grau_formacao: userData.usuario.grau_formacao,
        id_area_atuacao: userData.usuario.id_area_atuacao,
      },
      subscription: userData.subscription,
    };

    this.setUserData(userDataParse);
    this.buildAuthenticationCookie(userDataParse);
    this.setTokenAuthentication(userData.codigo);
    AuthService.setAuthorizationHeader(userData.codigo);
    SentryService.sendUserDataSentry(userDataParse.currentUser);
    SnowplowService.trackUser({ user: userData.usuario });
    BrazeService.trackUser({ userId: userDataParse.currentUser.id });
  }
  setUserData(userData) {
    localStorage.setItem(this.appUserData, JSON.stringify(userData));
  }
  getData() {
    const userData = localStorage.getItem(this.appUserData);
    if (!userData) {
      return null;
    }
    return JSON.parse(userData);
  }
  updateData(userDataLocal) {
    if (!userDataLocal) {
      throw new Error('[UserSessionService] -> updateData: It is mandatory to provide a valid object.');
    }
    this.buildAuthenticationCookie(userDataLocal);
    this.setUserData(userDataLocal);
  }
  removeData() {
    Vue.$cookies.remove(this.appNameAuthenticationCookie);
    localStorage.removeItem(this.appAuthenticationToken);
    localStorage.removeItem(this.appUserData);
  }
  getAuthenticationToken() {
    return localStorage.getItem(this.appAuthenticationToken);
  }
  existAuthenticationToken() {
    return !Utils.isEmpty(this.getAuthenticationToken());
  }
  existLocalData() {
    return this.existAuthenticationToken() && localStorage.getItem(this.appUserData) !== null;
  }
  isSubscriber() {
    const userData = this.getData();
    if (!userData) {
      return false;
    }

    return userData.currentUser.tipo === 1 && (userData.subscription.ativa || userData.subscription.premium.active);
  }
  setTokenAuthentication(authenticationToken) {
    if (!authenticationToken) {
      throw new Error('[UserSessionService] -> setTokenAuthentication: It is mandatory to provide a valid token.');
    }
    return localStorage.setItem(this.appAuthenticationToken, authenticationToken);
  }
  getNameCookieSourceApp() {
    return localStorage.getItem(this.appNameAuthenticationCookie);
  }
  setNameCookieSourceApp(nameCookieSourceApp) {
    if (!nameCookieSourceApp) {
      throw new Error(
        '[UserSessionService] -> setNameCookieSourceApp: It is mandatory to provide a valid name cookie.',
      );
    }
    return localStorage.setItem(this.appNameAuthenticationCookie, nameCookieSourceApp);
  }
  existAuthenticationCookie() {
    return Vue.$cookies.isKey(this.appNameAuthenticationCookie);
  }
  getAuthenticationCookieData() {
    const authenticationCookie = Vue.$cookies.get(this.appNameAuthenticationCookie);
    if (!authenticationCookie) {
      throw new Error(
        '[UserSessionService] -> getAuthenticationCookieData: It is mandatory to provide a valid object.',
      );
    }
    const fixedAuthenticationCookie = authenticationCookie.replace(/%3D/g, '=');
    return JSON.parse(atob(fixedAuthenticationCookie));
  }
  setDataByAuthenticationCookie(cookieData) {
    if (!cookieData) {
      throw new Error(
        '[UserSessionService] -> setDataByAuthenticationCookie: It is mandatory to provide a valid object.',
      );
    }
    localStorage.setItem(this.appUserData, JSON.stringify(cookieData));
  }
  runAuthenticationByCookie() {
    if (!this.existAuthenticationCookie()) {
      this.removeData();
    }
    const cookieData = this.getAuthenticationCookieData();
    this.setTokenAuthentication(cookieData.authData);
    this.setNameCookieSourceApp(cookieData.origin);
    this.setDataByAuthenticationCookie(cookieData);
    AuthService.setAuthorizationHeader(cookieData.authData);
    AuthService.setUserIdHeader(cookieData.currentUser.id);

    SnowplowService.trackUser({
      user: {
        id:cookieData.currentUser.id,
        email:cookieData.currentUser.email,
        id_tipo_usuario:cookieData.currentUser.tipo
      }
    });
  }
  buildAuthenticationCookie(userData) {
    if (!userData) {
      throw new Error(
        '[UserSessionService] -> buildAuthenticationCookie: It is mandatory to provide a valid object user data.',
      );
    }
    const encodeCookieData = btoa(JSON.stringify(userData));
    Vue.$cookies.set(this.appNameAuthenticationCookie, encodeCookieData);
  }
}

export default new UserSessionService();
