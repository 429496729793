<template>
  <div class="manage-subscription page">
    <top-bar :step-id="'management'" />
    <div class="main">
      <nav class="menu" @click="navigation.showList = !navigation.showList">
        <span class="selected-label">
          {{ currentMenuLabel }}
          <i class="fa fa-angle-down" aria-hidden="true" />
        </span>
        <ul :class="{ open: navigation.showList }">
          <router-link
              v-for="(item, index) in navigation.menuList"
              :key="index"
              :to="item.routerLink.name"
              active-class="active"
              tag="li"
            >
            <a>
              {{ item.label }}
            </a>
          </router-link>
        </ul>
      </nav>
      <div class="content">
        <router-view
          :registered-credit-card="registeredCreditCard"
          @loading="handleLoading"
          @updateRegisteredCard="mountRegisteredCard"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from '@/components/TopBar';
  import SubscriptionService from '@/services/SubscriptionService';
  import UserSessionService from '@/services/user-session';
  import { subscriptionAdapter } from '@/filters/subscriptionAdapter';

  import * as Sentry from '@sentry/browser';

  export default {
    components: {
      'top-bar': TopBar,
    },
    data() {
      return {
        navigation: {
          showList: false,
          menuList: [
            {
              label: 'Sobre a sua assinatura',
              routerLink: {
                name: '/gerenciar-assinatura.php/minha-assinatura',
              },
            },
            {
              label: 'Gerenciar dispositivos',
              routerLink: {
                name: '/gerenciar-assinatura.php/gerenciar-dispositivos',
              },
            },
          ],
        },
        selectedMenuLabel: {
          show: false,
        },
        registeredCreditCard: null,
        userSession: null,
      };
    },
    async created() {
      this.userSession = UserSessionService.getData();

      try {
        const subscription = await SubscriptionService.getSubscriptionStatus(
          this.userSession.currentUser.id
        );
        this.userSession.gateway = subscription.data.data.subscription.payment.gateway;

        const userData = {
          codigo: this.userSession.authData,
          usuario: {
            ...this.userSession.currentUser,
            id_tipo_usuario: this.userSession.currentUser.tipo
          },
          subscription: subscriptionAdapter(subscription.data)
        }

        UserSessionService.setData(userData);
      } catch (error) {
        this.createToast(
          'Ocorreu um erro ao buscar a sua assinatura. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379'
        );
        Sentry.captureException(error);
      }

      try {
        const gateWayIUGU = 1;
        const idGatewayPagarme = 5;
        if (
          (this.userSession.gateway.id === gateWayIUGU || this.userSession.gateway.id === idGatewayPagarme)
          && (this.userSession.subscription.premium.status === 'APROVADA' || this.userSession.subscription.premium.status === 'PENDENTE')
        ) {
          try {
            const addChargeCardMenuItem = {
              label: 'Adicionar cartão para cobrança',
              routerLink: {
                name: '/gerenciar-assinatura.php/gerenciar-cobranca',
              },
            };

            await this.mountRegisteredCard();
            addChargeCardMenuItem.label = 'Gerenciar cartão para cobrança';
            this.navigation.menuList.push(addChargeCardMenuItem);
          } catch (err) {
            if (err.response) {
              err = err.response.data;
            }

            const errorMessage = err.message || err.mensagem;

            if (err.code === 'AUTH_CODE_EXPIRED'
              || err.code === 'AUTH_INVALID_AUTH_CODE'
              || err.code === 'AUTH_WRONG_TOKEN_TYPE'
            ) {
              UserSessionService.removeData();
              this.createToast(errorMessage, {
                redirectTo: 'login',
              });
            }
          }
        }
      } catch (err) {
        if (err.response) {
          err = err.response.data;
        }

        const errorMessage = err.message || err.mensagem;

        if (errorMessage) {
          if (err.code === 'AUTH_CODE_EXPIRED'
            || err.code === 'AUTH_INVALID_AUTH_CODE'
            || err.code === 'AUTH_WRONG_TOKEN_TYPE'
          ) {
            UserSessionService.removeData();
            this.createToast(errorMessage, {
              redirectTo: 'login',
            });
          } else this.createToast(errorMessage);
        } else {
          this.createToast(
            'Ocorreu um erro ao carregar a página de gerenciamento de assinatura. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379',
          );
          Sentry.captureException(err);
        }
      } finally {
        this.finishLoading();
      }
    },
    computed: {
      currentMenuLabel() {
        if (this.$route.name === 'subscription-info') {
          return 'Sobre a sua assinatura';
        } else if (this.$route.name === 'manage-devices') {
          return 'Gerenciar dispositivos';
        } else if (this.$route.name === 'manage-billing') {
          return 'Gerenciar cartão para cobrança';
        }
      },
    },
    methods: {
      handleLoading(isLoading) {
        isLoading ? this.initLoading() : this.finishLoading();
      },
      async mountRegisteredCard() {
      const userId = this.userSession.currentUser.id;
        let resp = (
          await SubscriptionService.getPaymentMethodsByUserId(
            userId,
          )
        ).data;

        if (resp.id) {
          this.registeredCreditCard = {
            number: (resp.data.display_number || resp.data.masked_number).split(
              '-',
            ),
            expirationDate: `${resp.data.month}/${`${resp.data.year}`.substr(
              2,
            )}`,
            holderName: (
              resp.data.holder_name || resp.data.holder
            ).toUpperCase(),
            brand: resp.data.brand.toLowerCase(),
            token: resp.id,
          };
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .manage-subscription {
    .main {
      height: calc(100% - 56px);
      width: 100%;
      position: relative;

      .menu {
        height: 55px;
        position: relative;
        color: #566e7a;

        &:hover {
          cursor: pointer;
        }

        .selected-label {
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 2em;
          height: 46px;
          display: flex;
          background-color: #fff;
          border-bottom: 2px solid #cecece;
        }

        ul {
          display: none;
          list-style-type: none;
          padding: 0;
          background-color: #0000005e;
          width: 100vw;
          height: 100vh;

          &.open {
            display: initial;
            position: absolute;
            top: -14px;
            z-index: 9;
            margin-bottom: 0;
          }

          li {
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 2em;
            height: 46px;
            display: flex;
            background-color: #fff;
            border-bottom: 1px solid #cecece78;

            a {
              text-decoration: none;
              font-weight: normal;
            }

            &.active {
              a {
                font-weight: bold;
              }
            }
          }
        }
      }

      .content {
        height: calc(100% - 78px);
        padding-left: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        // overflow: hidden;

        > * {
          height: calc(100% - 30px);
          display: flex;
        }
      }
    }
  }

  @media screen and (min-width: 960px) {
    .manage-subscription {
      .main {
        display: flex;
        padding-top: 3em;
        position: initial;

        .menu {
          width: 230px;
          height: 100%;
          border-right: 1px solid #efefef;
          margin-left: 6em;

          .selected-label {
            display: none;
          }

          ul,
          ul.open {
            display: initial;
            position: initial;
            background-color: transparent;

            li {
              border: none;

              &.active {
                font-weight: initial;
                background-color: #efefef;

                a {
                  text-decoration: none;
                  font-weight: normal;
                }
              }
            }
          }
        }

        .content {
          width: calc(100% - 230px - 20%);
          min-width: 404px;
          height: 100%;
          margin-left: 3em;
          align-items: flex-start;

          > * {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 2560px) {
    .manage-subscription {
      .main {
        margin: 0 27vw;
      }
    }
  }
</style>
